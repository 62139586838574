import { useEffect } from 'react';
import AppLogo from '../src/assets/logo.png';

const ChangeIcons = ({ appIcon }) => {
    useEffect(() => {
        const updateIcon = (rel, sizes, href) => {
            let link = document.querySelector(`link[rel='${rel}'][sizes='${sizes}']`);
            if (link) {
                link.href = href;
            } else {
                link = document.createElement('link');
                link.rel = rel;
                link.sizes = sizes;
                link.href = href;
                document.head.appendChild(link);
            }
        };

        // Update each icon link
        updateIcon('apple-touch-icon', '180x180', appIcon);
        updateIcon('icon', '32x32', appIcon);
        updateIcon('icon', '16x16', appIcon);
    }, [appIcon]);

    return null;
};

export default ChangeIcons;
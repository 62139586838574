import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Avatar, Button, Divider, Collapse, Typography } from '@mui/material';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import CallIcon from '@mui/icons-material/Call';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const CollapseButtonStyle = styled(Button)(({ theme }) => ({
  ...theme.typography.overline,
  height: 40,
  borderRadius: 0,
  padding: theme.spacing(1, 2),
  justifyContent: 'space-between',
  color: theme.palette.text.disabled,
}));

const RowStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(1.5, 0),
}));

const RowIconStyle = styled("span")(({ theme }) => ({
  width: 16,
  height: 16,
  marginTop: 4,
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const RowTextStyle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: 160,
  wordWrap: 'break-word',
  ...theme.typography.body2,
}));

ChatRoomOneParticipant.propTypes = {
  participants: PropTypes.array.isRequired,
  isCollapse: PropTypes.bool,
  onCollapse: PropTypes.func,
};

export default function ChatRoomOneParticipant({ participants, isCollapse, onCollapse }) {
  const participant = [...participants][0];

  if (participant === undefined) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          pt: 4,
          pb: 3,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Avatar alt={participant.name} src={participant.avatar} sx={{ width: 96, height: 96 }} />
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="subtitle1">{participant.name}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {participant.position}
          </Typography>
        </Box>
      </Box>

      <Divider />

      <CollapseButtonStyle
        fullWidth
        color="inherit"
        onClick={onCollapse}
        endIcon={
          isCollapse?<ExpandMoreIcon sx={{ height:"16px", width:"16px"}}/> :<KeyboardArrowRightIcon sx={{ height:"16px", width:"16px"}}/>
        }
      >
        information
      </CollapseButtonStyle>

      <Collapse in={isCollapse}>
        <Box sx={{ px: 2.5, pb: 1 }}>
          <RowStyle>
            <RowIconStyle ><PlaceRoundedIcon sx={{height:"16px",width:"16px"}}/></RowIconStyle>
            <RowTextStyle>{participant.address}</RowTextStyle>
          </RowStyle>
          <RowStyle>
          <RowIconStyle ><CallIcon sx={{height:"16px",width:"16px"}}/></RowIconStyle>
            <RowTextStyle>{participant.phone}</RowTextStyle>
          </RowStyle>
          <RowStyle>
            <RowIconStyle ><MarkunreadIcon sx={{height:"16px",width:"16px"}}/></RowIconStyle>
            <RowTextStyle>{participant.email}</RowTextStyle>
          </RowStyle>
        </Box>
      </Collapse>
    </>
  );
}

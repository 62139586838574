const GoogleMyBusinessRedirect = () => {
    const popupWindowURL = new URL(window.location.href);
    const urlParams = new URLSearchParams(popupWindowURL.search);
    var token = urlParams.get("code");
    var error = urlParams.get("error");
    var error_description = urlParams.get("error_description");
    if (token) {
        localStorage.setItem("googleMyBusiness_token", `${token}`);
        window.close();
    }
    else if (error) {
        localStorage.setItem("googleMyBusiness_error", `${error_description}`);
        window.close();
    }
}
export default GoogleMyBusinessRedirect;
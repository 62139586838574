import {
    Box,
    Card,
    CardMedia,
    Grid,
    IconButton,
    Table, TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import Comments from '../../../assets/comment.svg';
import Likes from '../../../assets/fblike.svg';
import Share from '../../../assets/fbshare.svg';
import { CommonHelper } from 'src/utils/commonHelper';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import NoDocument from '../../../assets/no-document.svg';
import TextIconLabel from 'src/components/TextIconLabel';


const LinkedInMobileViewPosts = ({ publishedPosts, mobileLoader, getMobileViewLinkedinNextPublishedPosts }) => {
    const theme = useTheme();

    const postcontentL = 220;
    const postcontentS = 110;

    const getMediaType = (media_url) => {
        if (!media_url) return NoDocument;
        let mediaFiles = CommonHelper.ProcessMediaUrl(media_url)?.media_data;
        if (!mediaFiles || mediaFiles.length === 0) return NoDocument;
        let firstMediaFile = mediaFiles[0];
        if (firstMediaFile.type === "document") return NoDocument;
        return firstMediaFile.url;
    };

    return (
        <Box sx={{
            maxHeight: 'calc(100vh - 330px)',
            minHeight: 'calc(100vh - 330px)',
        }} >{publishedPosts.map((row, index) => {
            const { created_time, image_urls, thumbnail_url, like_count, message, share_count, comment_count } = row;
            return (
                <Card key={index} sx={{ maxHeight: message.length > 50 ? postcontentL : postcontentS, display: 'flex', flexDirection: 'row', mb: 1, p: 1, gap: 1 }}>
                    {PostDetailsHelper.getPostMedias(image_urls.length > 0 ? getMediaType(image_urls[0]) : null || thumbnail_url)}
                    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 1, maxWidth: 200 }}>
                        <Typography variant="subtitle2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} >{message}</Typography>
                        <Stack
                            direction="row"
                            justifyContent="space-between" sx={{ gap: 1 }}>
                            <TextIconLabel
                                value={like_count}
                                aria-label="chatbubbleoutline"
                                sx={{ typography: 'subtitle2' }}
                                icon={<img src={Likes} style={{ width: 20, height: 20 }} />}
                            />
                            <TextIconLabel
                                value={comment_count}
                                aria-label="chatbubbleoutline"
                                sx={{ typography: 'subtitle2' }}
                                icon={<img src={Comments} style={{ width: 20, height: 20 }} />}
                            />
                            <TextIconLabel
                                value={share_count}
                                aria-label="chatbubbleoutline"
                                sx={{ typography: 'subtitle2' }}
                                icon={<img src={Share} style={{ width: 20, height: 20 }} />}
                            />
                        </Stack>
                        <Typography variant="body2" sx={{ fontSize: '10px' }} color="text.secondary">
                            {CommonHelper.GetLinkedinPreviewDateFormat(created_time)}
                        </Typography>
                    </Box>
                </Card>
            );
        })}
            <Box sx={{ textAlign: 'center' }}>
                {mobileLoader ? (
                    <ThreeDotLoader />
                ) : (
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 700,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: theme.palette.grey[500],
                        }}
                        onClick={getMobileViewLinkedinNextPublishedPosts}
                    >
                        Load More Posts
                    </Typography>
                )}
            </Box></Box>
    )

}
export default LinkedInMobileViewPosts;
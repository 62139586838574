import { LoadingButton } from '@mui/lab';
import { Box, Card, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { CommonHelper } from 'src/utils/commonHelper';
import UserDisableIcon from '../../assets/icons/user-disable.svg';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Header from 'src/layouts/dashboard/header/header.component';

const DisableUserComponent = () => {

  const { showToast } = useToaster();
  const navigate = useNavigate();

  useEffect(() => {
    const isUserEnabled = CommonHelper.GetBrandInfoFromLocalStorage("is_user_enabled");
    if (isUserEnabled) {
      navigate(CommonHelper.DefaultPermissionRoute);
    }
  }, [])

  const successCallback = (data, message) => {
    message && showToast(message, 's');
    navigate('/no-organization');
  }

  const failureCallback = (message) => {
    message && showToast(message, 'e');
  }

  const handleLeaveOrganization = () => {
    CommonHelper.LeaveOrganization(successCallback, failureCallback);
  }

  return (
    <>
      <Helmet>
        <title> User Disabled </title>
      </Helmet>
      <Header headerName={''} />
      <Container>
        <div style={{
          display: 'flex',
          minHeight: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 10
        }}
        >
          <Container>
            <Box sx={{ maxWidth: 680, mx: 'auto' }}>
              <Card sx={{ py: 3, px: 3, textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={UserDisableIcon} style={{ height: 300 }} />
                </Box>
                <Typography variant="h3" paragraph>
                  User has been disabled.
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 3 }}>Please contact Admin or super Admin.</Typography>
                <LoadingButton
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleLeaveOrganization}
                >
                  Leave Organization
                </LoadingButton>
              </Card>
            </Box>
          </Container>
        </div>
      </Container>
    </>
  );
};
export default DisableUserComponent;
import {
    Box,
    Card,
    CardMedia,
    Grid,
    IconButton,
    Table, TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { CommonHelper } from 'src/utils/commonHelper';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';

const  PinterestMobileViewPosts = ({ publishedPosts, mobileLoader, getMobileViewPinterestsNextPublishedPosts ,pagination}) => {
    const theme = useTheme();

    const postcontentL = 220;
    const postcontentS = 110;
    return (
        <Box sx={{
            maxHeight: 'calc(100vh - 330px)',
            minHeight: 'calc(100vh - 330px)',
        }} >{publishedPosts.map((row, index) => {
            const { created_time, image_url, description, title } = row;
            return (
                <Card key={index} sx={{ maxHeight: description.length > 50 ? postcontentL : postcontentS, display: 'flex', flexDirection: 'row', mb: 1, p: 1 ,gap: 1 }}>
                    {image_url && <CardMedia
                        component="img"
                        sx={{ width: 140, maxHeight: 140 }}
                        image={image_url}
                        alt="Post image"
                    />}
                    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, minWidth: 200 }}>
                    <Typography variant="body2" sx={{ fontSize: '10px' }} color="text.secondary">
                            {title}
                        </Typography>
                        <Typography variant="subtitle2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} >{description}</Typography>
                        <Typography variant="body2" sx={{ fontSize: '10px' }} color="text.secondary">
                            {CommonHelper.GetDateFormat(created_time)}
                        </Typography>
                    </Box>
                </Card>
            );
        })}
            <Box sx={{ textAlign: 'center' }}>
                {mobileLoader ? (
                    <ThreeDotLoader />
                ) : (
                    <>
                   {pagination && <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 700,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: theme.palette.grey[500],
                        }}
                        onClick={getMobileViewPinterestsNextPublishedPosts}
                    >
                        Load More Posts
                    </Typography>}
                    </>
                )}
            </Box></Box>
    )

}
export default PinterestMobileViewPosts;
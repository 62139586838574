import { capitalCase } from 'change-case';
import { useState } from 'react';
import { Container, Tab, Box, Tabs, Stack, Typography, Grid, AppBar, Toolbar, Paper } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import Account from './integratedAccounts';
import NewIntegrationComponent from './newIntegration.component';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { NavConfig } from 'src/navConfig';
import { createTheme } from '@mui/material/styles';

const NAV_WIDTH = 280;
const HEADER_MOBILE = 42;
const HEADER_DESKTOP = 40;
const HEADERMOBILE = 42;
const HEADERDESKTOP = 40;
const TOP_MOBILE = 62;
const TOP_DESKTOP = 90;
const TOPMOBILE = 112;
const TOPDESKTOP = 130;

const customTheme = createTheme({
    breakpoints: {
        values: {
            mdCustom: 1100,
            mdCustom0: 1250
        },
    },
});

const StyledRoot = styled(AppBar)(({ theme, isCollapse }) => ({
    // top: isMobile ? TOP_MOBILE : TOP_DESKTOP,
    zIndex: '1',
    color: 'black',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    [customTheme.breakpoints.up('mdCustom')]: {
        width: `calc(100% - ${NavConfig.NAVBAR.DASHBOARD_WIDTH + 40}px)`,
        ...(isCollapse && {
            width: `calc(100% - ${NavConfig.NAVBAR.DASHBOARD_COLLAPSE_WIDTH - 60}px)`,
        }),
    },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

const IntegrationComponent = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [currentTab, setCurrentTab] = useState('New Integration');
    const { isCollapse } = useCollapseDrawer();
    const ACCOUNT_TABS = [
        {
            value: 'New Integration',
            icon: <PersonAddAltSharpIcon sx={{ marginRight: '10px' }} />,
            component: <NewIntegrationComponent />,
        },
        {
            value: 'Integrated Accounts',
            icon: <HowToRegIcon sx={{ marginRight: '10px' }} />,
            component: <Account />,
        },
    ];
    return (
        <>
            <Helmet>
                <title>Integration</title>
            </Helmet>
            <Box>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ gap: '0.5rem', minHeight: '37px',mt:'0.5rem' }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => {
                        return <Tab sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }} disableRipple key={tab.value} label={!isMobile && capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                    })}
                </Tabs>

            </Box>
            {/* <Box sx={{ mb: 2 }} /> */}
            {ACCOUNT_TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </>
    );
}
export default IntegrationComponent;
import React, { useEffect, useState } from 'react';
import { Card, Grid } from '@mui/material';
import SummaryCommonCard from 'src/components/reports/summary-cards/summaryCommonCard';
import DonutChart from 'src/components/reports/summary-cards/summaryDonutCard';
import HttpServices from 'src/services/httpService';
import { reportModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import NoReportCard from 'src/components/reports/summary-cards/noReportCard';

const AudienceSummaryComponent = ({ facebookPageId, startDate, endDate, viewBy,audienceSummary }) => {
    const [isLoading, setLoading] = useState(true);
    const [summaryData, setSummaryData] = useState({
        totalPageLikes: null,
        newLikes: null,
        likesLost: null,
        organicPaidLikes: null
    });

    const { showToast } = useToaster();

    useEffect(() => {
        if (facebookPageId) {
            getFacebookAudienceSummary();
        }
    }, [facebookPageId, startDate, endDate]);

    const getFacebookAudienceSummary = () => {
        setLoading(true);
        let body = {
            connected_profile_id: facebookPageId,
            report_id: audienceSummary.id,
            date_range: {
                from: startDate,
                to: endDate,
            },
            view_by: "day"
        };
        let getProps = {
            url: reportModuleURL.facebookAudienceSummary,
            body: body,
            successCallback: facebookPublishedPostSuccessCallback,
            failureCallback: facebookPublishedPostFailureCallback
        };
        HttpServices.Post(getProps);
    };

    const facebookPublishedPostFailureCallback = (message) => {
        setLoading(false);
        showToast(message, "e");
        setSummaryData({
            totalPageLikes: null,
            newLikes: null,
            likesLost: null,
            organicPaidLikes: null
        });
    };

    const facebookPublishedPostSuccessCallback = (data) => {
        const responseData = data[0].data;

            setSummaryData({
                totalPageLikes: responseData.total_page_likes,
                newLikes: responseData.new_likes,
                likesLost: responseData.likes_lost,
                organicPaidLikes: responseData.organic_paid_likes,
            });
        setLoading(false);
    };

    return (
        <Card>
            {isLoading ?
            <Grid container spacing={2}>
            {Array.from({ length: 4 }).map((_, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <NoReportCard />
                </Grid>
            ))}
        </Grid>
            :
            summaryData && (
                <Grid container spacing={2}>
                    {summaryData.totalPageLikes && summaryData.totalPageLikes.card_type != 'no-report'  ? (
                        <Grid item xs={12} sm={6} md={2.5}>
                            <SummaryCommonCard
                                title={summaryData.totalPageLikes.title}
                                data={summaryData.totalPageLikes.data}
                                percentage={summaryData.totalPageLikes.percentage}
                                percentageState={summaryData.totalPageLikes.percentage_state}
                                averageData={summaryData.totalPageLikes.average_data}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} md={2.5}>
                           <NoReportCard />
                        </Grid>
                    )}

                    {summaryData.newLikes && summaryData.newLikes.card_type != 'no-report' ? (
                        <Grid item xs={12} sm={6} md={2.5}>
                            <SummaryCommonCard
                                title={summaryData.newLikes.title}
                                data={summaryData.newLikes.data}
                                percentage={summaryData.newLikes.percentage}
                                percentageState={summaryData.newLikes.percentage_state}
                                averageData={summaryData.newLikes.average_data}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} md={2.5}>
                           <NoReportCard />
                        </Grid>
                    )}

                    {summaryData.likesLost && summaryData.likesLost.card_type != 'no-report' ? (
                        <Grid item xs={12} sm={6} md={2.5}>
                            <SummaryCommonCard
                                title={summaryData.likesLost.title}
                                data={summaryData.likesLost.data}
                                percentage={summaryData.likesLost.percentage}
                                percentageState={summaryData.likesLost.percentage_state}
                                averageData={summaryData.likesLost.average_data}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} md={2.5}>
                            <NoReportCard />
                        </Grid>
                    )}

                    {summaryData.organicPaidLikes && summaryData.organicPaidLikes.card_type != 'no-report' ? (
                        <Grid item xs={12} sm={6} md={4.5} >
                            <DonutChart
                                title={summaryData.organicPaidLikes.title}
                                value={summaryData.organicPaidLikes.value}
                                labels={summaryData.organicPaidLikes.key}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} md={3}>
                           <NoReportCard />
                        </Grid>
                    )}
                </Grid>
            )}
        </Card>
    );
};

export default AudienceSummaryComponent;

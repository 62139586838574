import { useState } from "react";
import { useEffect } from "react";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import { Config } from "src/config";
import { useToaster } from "src/utils/toaster/toasterContext";
import LinkedInProfileAuthorizationModal from "./linkedinProfileAuthorizationModal";
// var openedWindow;
const LinkedInProfileIntegration = (props) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const { showToast } = useToaster();
    let intervalId = null;

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthCodeReceivedFromLinkedIn();
        }, Config.Common.socialAccountsIntegrationInterval);
        return (() => {
            clearInterval(intervalId);
        })
    }, [])

    const getLinkedProfileOauthToken = () => {
        window.open(
            `${Config.LinkedIn.authorizeUrl}?response_type=code&client_id=${Config.LinkedIn.clientId}&scope=${Config.LinkedIn.scope}&state=${Config.LinkedIn.state}&redirect_uri=${Config.LinkedIn.redirectUri}`, "_blank"
        );
    };

    const checkAuthCodeReceivedFromLinkedIn = () => {
        var code = localStorage.getItem("linkedin_code");
        var error = localStorage.getItem("linkedin_error");
        if (code) {
            localStorage.removeItem("linkedin_code");
            clearInterval(intervalId);
            sendAccessTokenToServer(code);
        }
        else if (error) {
            localStorage.removeItem("linkedin_error");
            clearInterval(intervalId);
            showToast(error, 'e');
            handleClose();
        }
    };
    const linkedInAccessTokenSuccessCallback = (data, message) => {
        showToast(message, 's');
        handleClose();
    }
    const linkedInAccessTokenFailureCallback = (message) => {
        showToast(message, 's');
        handleClose();
    }
    const sendAccessTokenToServer = (code) => {
        let linkedInForm = {
            code
        };
        let postProps = {
            url: integrateModuleURL.sendLinkedInAccessToken,
            body: linkedInForm,
            successCallback: linkedInAccessTokenSuccessCallback,
            failureCallback: linkedInAccessTokenFailureCallback
        }
        HttpServices.Post(postProps);
    };

    const handleClose = () => {
        props.handleClose();
    }

    return (
        isAuthorizationModalOpen &&
        <LinkedInProfileAuthorizationModal
            handleConnectLinkedInProfile={getLinkedProfileOauthToken}
            handleClose={handleClose}
        />
    )
};
export default LinkedInProfileIntegration;

import React, { useState, useEffect } from 'react';
import {
    Card,
    Grid,
    Stack,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useToaster } from 'src/utils/toaster/toasterContext';
import HttpServices from 'src/services/httpService';
import { reportModuleURL } from 'src/services/urlService';
import DonutChart from 'src/components/reports/summary-cards/summaryDonutCard';
import BestPostCardDetails from 'src/components/reports/summary-cards/bestPostCard';
import TableDonutChart from 'src/components/reports/summary-cards/summaryTableDonutCard';
import SummaryCommonCard from 'src/components/reports/summary-cards/summaryCommonCard';
import Typography from 'src/theme/overrides/Typography';
import NoReportCard from 'src/components/reports/summary-cards/noReportCard';

const PostEngagementSummaryComponent = ({ facebookPageId, startDate, endDate, viewBy,engagementSummary }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [isLoading, setloading] = useState(true);
    const [facebookPostEngagementSummary, setFacebookPostEngagementSummary] = useState({
        totalPosts: null,
        totalEngagement: null,
        bestPost: null,
        engagementPostType: null
    }
    );
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));
    const { showToast } = useToaster();

    useEffect(() => {
        if (facebookPageId) getFacebookPostEngagementSummary();
    }, [facebookPageId, startDate, endDate]);

    const getFacebookPostEngagementSummary = () => {
        setloading(true);
        let body = {
            connected_profile_id: facebookPageId,
            report_id: engagementSummary.id,
            date_range: {
                from: startDate,
                to: endDate,
            },
            view_by: "day"
        };
        let getProps = {
            url: reportModuleURL.facebookAudienceSummary,
            body: body,
            successCallback: facebookPostEngagementSuccessCallback,
            failureCallback: facebookPostEngagementFailureCallback
        };
        HttpServices.Post(getProps);
    };

    const facebookPostEngagementFailureCallback = (message) => {
        setFacebookPostEngagementSummary({
            totalPosts: null,
            totalEngagement: null,
            bestPost: null,
            engagementPostType: null
        });
        message && showToast(message, "e");
        setloading(false);
    };

    const facebookPostEngagementSuccessCallback = (data, message) => {
        const responseData = data[0].data;
        setFacebookPostEngagementSummary({
            totalPosts: responseData.total_posts,
            totalEngagement: responseData.total_engagement,
            bestPost: responseData.best_post,
            engagementPostType: responseData.engagement_post_type
        });
        setloading(false);
    };

    return (
        <Card>
            {isLoading ? (
                <Grid container spacing={2}>
                    {Array.from({ length: 4 }).map((_, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <NoReportCard />
                        </Grid>
                    ))}
                </Grid>
            ) : (

                <Grid container spacing={2}>
                    <Grid item xs={12}  lg={6} xl={2.5} >
                        <Stack direction={isMobile ? 'row' : 'column'} >
                            {facebookPostEngagementSummary.totalPosts && facebookPostEngagementSummary.totalPosts.card_type != 'no-report' ? (
                                <SummaryCommonCard
                                    title={facebookPostEngagementSummary.totalPosts.title}
                                    data={facebookPostEngagementSummary.totalPosts.data}
                                    percentage={facebookPostEngagementSummary.totalPosts.percentage}
                                    percentageState={facebookPostEngagementSummary.totalPosts.percentage_state}
                                    averageData={facebookPostEngagementSummary.totalPosts.average_data}
                                />
                            ) : (
                                <NoReportCard />
                            )}
                            {facebookPostEngagementSummary.totalEngagement && facebookPostEngagementSummary.totalEngagement.card_type != 'no-report' ? (
                                <SummaryCommonCard
                                    title={facebookPostEngagementSummary.totalEngagement.title}
                                    data={facebookPostEngagementSummary.totalEngagement.data}
                                    percentage={facebookPostEngagementSummary.totalEngagement.percentage}
                                    percentageState={facebookPostEngagementSummary.totalEngagement.percentage_state}
                                    averageData={facebookPostEngagementSummary.totalEngagement.average_data}
                                />
                            ) : (
                                <NoReportCard />
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}  lg={6} xl={2.5}>
                        {facebookPostEngagementSummary.bestPost && facebookPostEngagementSummary.bestPost.card_type != 'no-report' ? (
                            <BestPostCardDetails
                                title={facebookPostEngagementSummary.bestPost.title}
                                comments={facebookPostEngagementSummary.bestPost.comment_count}
                                createdTime={facebookPostEngagementSummary.bestPost.created_time}
                                description={facebookPostEngagementSummary.bestPost.description}
                                engagement={facebookPostEngagementSummary.bestPost.engagement}
                                engagementRate={facebookPostEngagementSummary.bestPost.engagement_rate}
                                mediaUrl={facebookPostEngagementSummary.bestPost.media_url}
                                link={facebookPostEngagementSummary.bestPost.link}
                                reactions={facebookPostEngagementSummary.bestPost.reaction}
                                shares={facebookPostEngagementSummary.bestPost.share_count}
                            />
                        ) : (
                            <NoReportCard />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={isMobile ? 12 : 6} xl={7}>
                        {facebookPostEngagementSummary.engagementPostType && facebookPostEngagementSummary.engagementPostType.card_type != 'no-report' ? (
                            <TableDonutChart
                                title={facebookPostEngagementSummary.engagementPostType.title}
                                engagementPostType={facebookPostEngagementSummary.engagementPostType.engagement_by_type}
                                value={facebookPostEngagementSummary.engagementPostType.value}
                                labels={facebookPostEngagementSummary.engagementPostType.key}
                            />
                        ) : (
                            <NoReportCard />
                        )}
                    </Grid>
                </Grid>

            )}
        </Card>
    );

};

export default PostEngagementSummaryComponent;

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, Box, Typography, Button, Avatar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import LoadFacebookSdk from '../loadFacebookSdk';
import { useToaster } from 'src/utils/toaster/toasterContext';
import FacebookGroupAuthorizationModal from './facebookGroupAuthorizationModal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Facebook from '../../../assets/facebook.svg'
import { Config } from 'src/config';

const FacebookGroupIntegration = (props) => {
    const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
    const [isFbAccountModalOpen, setFbAccountModal] = useState(false);
    const [isNoAccountForIntegrate, setNoAccountIntegrated] = useState(false);
    const [groups, setGroups] = useState([]);
    const [profileId, setProfileId] = useState("");
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isLoadSdk, setLoadSdk] = useState(false);
    const { showToast } = useToaster();
    let intervalId = null;

    useEffect(() => {
        intervalId = setInterval(() => {
            checkAuthoCodeReceivedFromFacebook();
        }, Config.Common.socialAccountsIntegrationInterval);
        return () => {
            clearInterval(intervalId);
        };
    }, [])

    const checkAuthoCodeReceivedFromFacebook = () => {
        var code = localStorage.getItem("fb_autho_code");
        var error = localStorage.getItem("fb_error");
        if (code) {
            localStorage.removeItem("fb_autho_code");
            clearInterval(intervalId);
            getFacebookGroups(code);
        }
        else if (error) {
            localStorage.removeItem("fb_error");
            clearInterval(intervalId);
            showToast(error, 'e');
        }
    }
    const fbGroupsSuccessCallback = (data, message) => {
        if (data.groups &&
            data.groups.data &&
            data.groups.data.length > 0) {
            let isNoAccountForIntegrate = !data.groups.data.some(a => !a.is_integrated);
            setNoAccountIntegrated(isNoAccountForIntegrate);
            setAuthorizationModal(false);
            setFbAccountModal(true);
            setGroups(data.groups.data);
            setProfileId(data.id)
        }
    }
    const fbGroupsFailureCallback = (message) => {
        showToast(message, 'e');
    }
    const getFacebookGroups = (code) => {
        let facebookForm = {
            code,
        };
        let postProps = {
            url: integrateModuleURL.getFacebookPagesAndGroups,
            body: facebookForm,
            successCallback: fbGroupsSuccessCallback,
            failureCallback: fbGroupsFailureCallback
        }
        HttpServices.Post(postProps);
    };
    const facebookSuccessCallback = (data, message) => {
        showToast(message, 's');
        handleClose();
    }
    const facebookFailureCallback = (message) => {
        handleClose();
        showToast(message, 's');
    }
    const handleSubmitGroups = () => {
        let facebookForm = {
            groups: selectedGroups,
            id: profileId
        };
        let postProps = {
            url: integrateModuleURL.integrateFacebookPagesAndGroups,
            body: facebookForm,
            successCallback: facebookSuccessCallback,
            failureCallback: facebookFailureCallback
        }
        HttpServices.Post(postProps);
    };

    const handleGroups = (groupId) => {
        let selectedGroupList = [...selectedGroups];
        let index = selectedGroupList.findIndex((a) => a.id === groupId);
        if (index >= 0) selectedGroupList.splice(index, 1);
        else {
            let selectedPage = groups.find((a) => a.id === groupId);
            selectedGroupList.push(selectedPage);
        }
        setSelectedGroups(selectedGroupList);
    };

    const handleConnectFacebook = () => {
        setLoadSdk(true);
    }

    const handleClose = () => {
        props.handleClose();
    };
    const checkAccountIsIntegrated = (account) => {
        let isChecked = false;
        if (account.is_integrated)
            isChecked = true;
        else {
            let selectedGroupList = [...selectedGroups];
            if (selectedGroupList && selectedGroupList.length > 0) {
                let index = selectedGroupList.findIndex(a => a.id === account.id);
                if (index >= 0)
                    isChecked = true;
            }
        }
        return isChecked;
    }
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 20,
        height: 20,
        backgroundColor: '#fff',
    }));
    return (
        <>
            {isAuthorizationModalOpen &&
                <FacebookGroupAuthorizationModal
                    handleConnectFacebook={handleConnectFacebook}
                    handleClose={handleClose}
                />}
            {isFbAccountModalOpen ? (
                <Dialog onClose={handleClose} open={isFbAccountModalOpen} fullWidth>
                    <DialogTitle>Facebook Groups</DialogTitle>
                    <DialogContent dividers>
                        <div className="l2s-space-between">
                            <Box>
                                {groups && groups.length > 0 ?
                                    <>
                                          <List>
                                        {groups.map((a) => {
                                            return (
                                                <ListItem
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        value={a.id}
                                                        checked={checkAccountIsIntegrated(a)}
                                                        onClick={isNoAccountForIntegrate ? null : () => { handleGroups(a.id) }}
                                                        disabled={a.is_integrated}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton onClick={isNoAccountForIntegrate ? null : () => { handleGroups(a.id) }}>
                                                    <ListItemAvatar>
                                                        <Badge
                                                            overlap="circular"
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            badgeContent={
                                                                <SmallAvatar alt="" src={Facebook} />
                                                            }
                                                        >
                                                            <Avatar
                                                                 src={a.profile_image ? a.profile_image : a.profile_name || a.id}
                                                                 alt={a.profile_name ? a.profile_name : a.id}
                                                                style={{ color: '#ffffff' }}
                                                            />
                                                        </Badge>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={a.name} />
                                                </ListItemButton>
                                            </ListItem>
                                            )
                                        })}
                                        </List>
                                        {isNoAccountForIntegrate && <Typography
                                            sx={{
                                                color: 'red',
                                                fontSize: '12px',
                                                marginTop: '10px'
                                            }}
                                        >
                                            All of your groups are integrated
                                        </Typography>}
                                    </>
                                    :
                                    <Typography sx={{
                                        color: 'red',
                                        margin: '10px'
                                    }}>
                                        There is no Facebook groups to integrate
                                    </Typography>
                                }
                            </Box>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color='error' variant="outlined" onClick={handleClose}>
                            Cancel
                        </Button>
                        {groups && groups.length > 0 && !isNoAccountForIntegrate &&
                            <LoadingButton variant="contained" disabled={selectedGroups.length === 0} autoFocus onClick={handleSubmitGroups}>
                                Submit
                            </LoadingButton>}
                    </DialogActions>
                </Dialog>
            ) : null}
            {isLoadSdk && <LoadFacebookSdk />}
        </>
    );
};
export default FacebookGroupIntegration;

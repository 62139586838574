import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { UserListHead } from "src/sections/@dashboard/user";


const DraftPostGridHead = ({ draftList, order, orderBy, handleRequestSort }) => {
    const getTableDesk = [
        { id: 'created_time', label: 'Created At', alignRight: false, hasSort: true },
        { id: 'description', label: 'Draft Content', alignRight: false, hasSort: true },
        { id: 'media', label: 'Media', alignRight: false },
        { id: 'created_by_name', label: 'Created By', alignRight: false, hasSort: true },
        { id: 'scheduled-for', label: 'Draft For', alignRight: false },
        { id: 'action', label: 'Action', alignRight: false },
    ];
    const getTableMobile = [
        { id: 'scheduled-on', label: 'Scheduled On', alignRight: false, hasSort: true },
        { id: 'post-content', label: 'post-content', alignRight: false, hasSort: true },
        { id: 'action', label: 'Action', alignRight: false },
    ];
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;


    return (
        <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={draftList.length}
            onRequestSort={handleRequestSort}
            style={{ whiteSpace: 'nowrap' }}
            isShowCheckbox={false}
        />
    )
}

export default DraftPostGridHead;
import { List } from '@mui/material';
import ChatConversationItem from './ChatConversationItem';
import { SkeletonConversationItem } from 'src/components/skeleton';

export default function ChatConversationList({ chatConversation, isOpenSidebar, selectedConversation, handleConversation ,userId,userPic,icon }) {

  const handleSelectConversation = (conversation) => {
    handleConversation(conversation);
  };

  // const loading = !conversations.allIds.length;

  return (
    <List disablePadding>
      {/* {(loading ? [...Array(12)] : conversations.allIds).map((conversationId, index) =>
        conversationId ? (
        <ChatConversationItem
          key={conversationId}
          isOpenSidebar={isOpenSidebar}
          conversation={conversations.byId[0]}
          isSelected={true}
          onSelectConversation={() => handleSelectConversation(conversationId)}
        />
        ) : (
          <SkeletonConversationItem key={index} />
        )
      )} */}
      {chatConversation
        && chatConversation.conversion
        && chatConversation.conversion.length > 0
        && chatConversation.conversion.map(conv => {
          return (<ChatConversationItem
            key={conv.id}
            isOpenSidebar={isOpenSidebar}
            conversation={conv}
            icon={icon}
            userPic={userPic}
            userId={userId}
            isSelected={conv.id === selectedConversation.id}
            onSelectConversation={() => handleSelectConversation(conv)}
          />);
        })}

    </List>
  );
}

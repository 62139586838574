import AddIcon from '@mui/icons-material/Add';
import { Button, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import WorkPlaceBox from '../../components/navbar/WorkPlaceBox.jsx';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1, 0, 3),
}));


export default function CreateBrandToolbar({ handleRefreshGrid }) {
  const [openWorkPlaceModal, setWorkPlaceModal] = useState(false);
  const handleWorkPlaceClick = () => {
    setWorkPlaceModal(true);
  };
  const handleClose = () => {
    setWorkPlaceModal(false);
  };
  const handleSuccess = () => {
    setWorkPlaceModal(false);
    handleRefreshGrid(true);
  }

  return (
    <>
      <StyledRoot>
        <Button variant='contained' startIcon={<AddIcon />} onClick={handleWorkPlaceClick}>CREATE BRAND</Button>
      </StyledRoot>
      {openWorkPlaceModal && <WorkPlaceBox handleClose={handleClose} handleSuccess={handleSuccess} />}
    </>
  );
}

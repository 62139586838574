import {
  Box, Button, Card, Divider, Grid, Stack, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import ReactCountryFlag from "react-country-flag";
import { useLocation } from 'react-router-dom';
import Page from 'src/components/Page';
import PaymentResponse from 'src/components/stripe/paymentResponse';
import PlanPaymentComponent from 'src/components/stripe/planPaymentComponent';
import PricingPlanCard from 'src/pages/payment/pricing.component';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import PlanUpgrade from '../../../assets/app-image/plan-upgrade.svg';

const PlanInfoComponent = () => {
  const [planType, setPlanType] = useState(30);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [billingAddress, setBillingAddress] = useState("");
  const [billingName, setBillingName] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isPaymentDialogOpen, setPaymentDialog] = useState(false);
  const [pricingPlan, setPricingPlan] = useState([]);
  const [planAddonDetials, setPlanAddonDetials] = useState([]);
  const [isPaymentResponseDialogOpen, setPaymentDialogOpen] = useState(false);
  const [upgradeItems, setUpgradeItems] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [expiresOn, setPlanExpiresOn] = useState("");
  const [newExpiresOn, setNewExpiresOn] = useState("");
  const [hasActiveSubscription, setActiveSubscription] = useState(false);
  const [isPaymentOn, setPaymentOn] = useState(false);
  const [isAddon, setAddon] = useState(false);
  const { showToast } = useToaster();
  const location = useLocation();
  const [height, setHeight] = useState(window.innerHeight - 150);
  const [inrAmount, setINRAmount] = useState(0);
  const [currency, setCurrency] = useState('USD');

  useEffect(() => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let currency = CommonHelper.DetermineCurrency(localTimeZone);
    setCurrency(currency);
    getPlanList(currency);
    if (location && location.state !== null) {
      setPaymentDialogOpen(true);
      setTimeout(() => {
        clearLocationState();
      }, 2000)
    }
  }, []);

  const handleCurrencyChange = (event, newCurrency) => {
    if (newCurrency !== null) {
      setCurrency(newCurrency);
      getPlanList(newCurrency);
      if (selectedPlan!=null){
        getCurrentPlanPrice(selectedPlan.id,upgradeItems,newCurrency);
      }
      
    }
  };
  const clearLocationState = () => {
    window.history.replaceState({}, '')
  };

  const planSuccessCallback = (data, message) => {
    setPricingPlan(data.paid_plan_detials);
    getCurrentPlan(data.paid_plan_detials);
    setPlanAddonDetials(data.plan_addon_detials);
  }
  const failureCallback = (message) => {
    showToast(message, "e");
  }
  const getPlanList = (currency) => {
    let getProps = {
      url: userModuleURL.getAllPlan + currency,
      successCallback: planSuccessCallback,
      failureCallback
    };
    HttpServices.Get(getProps);
  }

  const getCurrentPlan = (data) => {
    const planDetails = CommonHelper.GetBrandInfoFromLocalStorage("current_plan_info");
    setCurrentPlan(planDetails);
    let plan = data.find((a) => a.id === planDetails.id);
    if (selectedPlan?.id) {
      plan = data.find((a) => a.id === selectedPlan.id);
    }
    setSelectedPlan(plan);
  };

  const handlePlan = (id) => {
    const plan = pricingPlan.find((a) => a.id === id);
    setSelectedPlan(plan);
    getCurrentPlanPrice(id,upgradeItems,currency); 
  };

  const successCallback = (data) => {
    setTotalAmount(data.amount);
    setPlanExpiresOn(data.expire_date);
    setNewExpiresOn(data.new_expire_date);
    setActiveSubscription(data.has_active_subscription);
  };

  const handleUpdate = (data) => {
    if (selectedPlan) {
      getCurrentPlanPrice(selectedPlan.id, data,currency); 
      setUpgradeItems(data);
    }
  };

  const getCurrentPlanPrice = (id, upgradeItems,newCurrency) => {
    const body = {
      new_plan_id: id,
      add_ons: upgradeItems,
      currency:newCurrency,
    };
    HttpServices.Post({
      url: userModuleURL.changePlanPrice,
      body,
      successCallback,
      failureCallback,
    });
  };

  const handlePaymentDialog = (show) => {
    setPaymentDialog(show);
  }

  const handleAddOnDialog = (show) => {
    setAddon(show);
  };

  const handleAddonClose = () => {
    setAddon(false);
  };

  const handleInputChange = (event, newValue) => {
    if (event?.target?.name === "planType") {
      setPlanType(newValue);
    } else {
      const { name, value } = event.target;
      switch (name) {
        case "billingName":
          setBillingName(value);
          break;
        case "billingAddress":
          setBillingAddress(value);
          break;
        default:
          break;
      }
    }
  };

  const ACCOUNT_TABS = [
    {
      value: 30,
      label: 'MONTHLY',
    },
    {
      value: 365,
      label: 'YEARLY',
    },
  ];

  const getScheduledDate = (date) => {
    return CommonHelper.formateDateWithYear(date);
  };

  const handleClosePaymentResponse = () => {
    setPaymentDialogOpen(false);
    getPlanList();
  };

  const stripeProps = { planId: selectedPlan?.id, amount: totalAmount, inrAmount: inrAmount, currency: currency, upgradeItems: upgradeItems, handlePaymentDialog: handlePaymentDialog };

  const paymentResponseProps = {
    paymentIntent: location?.state?.paymentIntent,
    paymentIntentClientSecret: location?.state?.paymentIntentClientSecret,
    handleClosePaymentResponse,
  };

  return (
    <Page title="Pricing Plan">
      <Card sx={{ pt: 3, height: height, overflow: 'auto' }}>

        <Box sx={{ textAlign: 'center' }}>
          <Stack alignItems="center" justifyContent="center">
            <Tabs
              name="planType"
              value={planType}
              onChange={handleInputChange}
            >
              {ACCOUNT_TABS.map((tab) => (
                <Tab
                  name="planType"
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  sx={{ flexDirection: 'row', minHeight: '10px', mr: '0.50rem', fontWeight: 600 }}
                />
              ))}
            </Tabs>
          </Stack>
        </Box>
        <Grid container sx={{ mt: 2 }} >
          {pricingPlan.length > 0 && pricingPlan.filter((a) => a.plan_validity === planType).map((plan) => (
            <Grid item xs={12} md={3} key={plan.id} sx={{ display: 'flex' }}>
              <PricingPlanCard {...plan} selectedPlan={selectedPlan} handlePlan={handlePlan} handleUpdate={handleUpdate} planAddonDetials={planAddonDetials} currency={currency}/>
            </Grid>
          ))}
          {selectedPlan?.price > 0 ? (
            <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
              <Card sx={{ p: 3, maxWidth: 400, width: '100%', mt: 1, flex: 1 }}>
                <Box>
                  <Stack sx={{ width: '100%', alignItems: 'center' }}>
                    <ToggleButtonGroup
                      value={currency}
                      exclusive
                      onChange={handleCurrencyChange}
                      style={{ marginBottom: 10 }}
                    >
                      <ToggleButton value="INR" aria-label="India">
                        <ReactCountryFlag
                          countryCode="IN"
                          svg
                          style={{
                            width: '24px',
                            height: '18px',
                            marginRight: '8px',
                          }}
                        />
                        INR
                      </ToggleButton>
                      <ToggleButton value="USD" aria-label="USA">
                        <ReactCountryFlag
                          countryCode="US"
                          svg
                          style={{
                            width: '24px',
                            height: '18px',
                            marginRight: '8px',
                          }}
                        />
                        USD
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <TextField fullWidth name="billingName" label="Billing Name" onChange={handleInputChange} sx={{ mb: 1.5 }} />
                    <TextField fullWidth name="billingAddress" label="Billing Address" onChange={handleInputChange} sx={{ mb: 1.5 }} />
                    <Typography color="text.secondary">
                      Current Plan Expires on
                    </Typography>
                    <Typography variant="overline" display="block" sx={{ mb: 1.5 }}>{getScheduledDate(expiresOn)}</Typography>
                    <Typography color="text.secondary">
                      New Plan Begins from
                    </Typography>
                    <Typography variant="overline" display="block">{getScheduledDate(expiresOn)}</Typography>
                    <Typography color="text.secondary">
                      To
                    </Typography>
                    <Typography variant="overline" display="block" sx={{ mb: 1.5 }}>
                      {getScheduledDate(newExpiresOn)}
                    </Typography>
                    <Divider />
                    <Stack alignItems="center" sx={{ mb: 1.5 }}>
                      <Typography variant="h5">{`Total = ${currency === "INR" ? `₹${totalAmount}` : `$${totalAmount}`}`}</Typography>
                    </Stack>
                    <Divider />
                    <Button fullWidth size="large" variant="contained" sx={{ margin: '15px' }} onClick={() => handlePaymentDialog(true)}>
                      Proceed
                    </Button>
                  </Stack>
                </Box>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
              <Card sx={{ p: 3, maxWidth: 400, width: '100%', mt: 1, mb: 1, flex: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Stack sx={{ width: '100%', alignItems: 'center' }}>
                    <img src={PlanUpgrade} alt="Plan Upgrade" />
                    <Typography color="text.secondary" variant="overline" display="block" sx={{ mt: 3 }}>
                      Currently you are in
                    </Typography>
                    <Typography color="text.secondary" variant="overline" display="block" sx={{ mt: 1, fontSize: 22 }}>
                      {/* Uncomment and add plan name */}
                      {/* {currentPlan ? `${currentPlan.plan_name.split("_")[0]} Plan` : "Free Plan"} */}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </Card>
      {isPaymentDialogOpen && <PlanPaymentComponent {...stripeProps} />}
      {isPaymentResponseDialogOpen && <PaymentResponse {...paymentResponseProps} />}
    </Page>
  );
};

export default PlanInfoComponent;

import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { UserListHead } from "src/sections/@dashboard/user";


const FailedPostGridHead = ({ failedPostList, order, orderBy, handleRequestSort }) => {
    const getTableDesk = [
        { id: 'drop_down', label: '', alignRight: false },
        { id: 'scheduled_time', label: 'Scheduled', alignRight: false, hasSort: true },
        { id: 'created_time', label: 'Created', alignRight: false, hasSort: true },
        { id: 'updated_at', label: 'Updated', alignRight: false, hasSort: true },
        { id: 'description', label: 'Post  Content', alignRight: false, hasSort: true },
        { id: 'media', label: 'Media', alignRight: false },
        { id: 'created_by_name', label: 'Created By', alignRight: false, hasSort: true },
        { id: 'approved_by_name', label: 'Approved By', alignRight: false, hasSort: true },
        // { id: 'is_enabled', label: 'Status', alignRight: false, hasSort: true },
        { id: 'scheduled-for', label: 'Scheduled For', alignRight: false },
        { id: 'action', label: 'Action', alignCenter: true },
    ];
    const getTableMobile = [
        { id: 'scheduled_time', label: 'Scheduled On', alignRight: false, hasSort: true },
        { id: 'description', label: 'post-content', alignRight: false, hasSort: true },
        { id: 'action', label: 'Action', alignRight: false },
    ];
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;

    return (
        <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={failedPostList.length}
            onRequestSort={handleRequestSort}
            style={{ whiteSpace: 'nowrap' }}
            isShowCheckbox={false}
        />
    )
}

export default FailedPostGridHead;
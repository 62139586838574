import AddIcon from '@mui/icons-material/Add';
import { Button, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import AddNewRole from './addNewRole.component';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1, 0, 3),
}));

export default function RolesToolbar({ handleSuccess }) {
    const theme = useTheme();
    const [openNewRoleModel, setNewRoleModel] = useState(false);
    const handleNewRoleModal = () => {
        setNewRoleModel(true);
    };
    const handleClose = () => {
        setNewRoleModel(false);
    };
    return (
        <>
            <StyledRoot>
                <Button variant='contained' startIcon={<AddIcon />} onClick={handleNewRoleModal}>Add New Role</Button>
            </StyledRoot>
            {openNewRoleModel && <AddNewRole handleClose={handleClose} handleSuccess={handleSuccess} />}
        </>
    );
}

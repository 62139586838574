import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Card,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import UserListHead from '../../sections/@dashboard/user/UserListHead';
import NoPostFound from '../publishedPosts/noPost.component';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import OrgManageUserAction from './orgManageUserAction';

const OrgManageUsers = () => {
  const [teamMembers, setTeamMembers] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [height, setHeight] = useState(window.innerHeight - 150);
  const [loader, setLoader] = useState(false);
  const [selectedPopOverPost, setPopOverPost] = useState(null);
  const [popoverElement, setPopoverElement] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const { showToast } = useToaster();

  useEffect(() => {
    getAllManageUsers();
  }, []);

  const getAllManageUsers = () => {
    setLoader(true);
    let postProps = {
      url: userModuleURL.getOrgUsers,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(postProps);
  };
  const successCallback = (data, message) => {
    setTeamMembers(data);
    showToast(message, 's');
    setLoader(false);
  };
  const failureCallback = (message) => {
    setLoader(false);
    showToast(message, 'e');
  };
  const statusSuccessCallback = (data, message) => {
    getAllManageUsers();
    showToast(message, 's');
    handleCloseMenu();
  };
  const statusFailureCallback = (message) => {
    showToast(message, 'e');
  };
  const handleOpenConfirm = (action) => {
    setCurrentAction(action);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setCurrentAction(null);
  };

  const handleConfirmAction = () => {
    handleRemoveFromOrg();
    handleCloseConfirm();
  };
  const handleRemoveFromOrg = () => {
    let bodyProps = {
      user_id: selectedPopOverPost.user_id,
    };
    let postProps = {
      url: userModuleURL.removeUserFromOrg,
      body: bodyProps,
      successCallback: statusSuccessCallback,
      failureCallback: statusFailureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleAccountStatus = (member) => {
    let bodyProps = {
      user_id: member.user_id,
    };
    let postProps = {
      url: member.is_enabled ? userModuleURL.disableUser : userModuleURL.enableUser,
      body: bodyProps,
      successCallback: statusSuccessCallback,
      failureCallback: statusFailureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleOpenMenu = (event, row) => {
    setPopOverPost(row);
    setPopoverElement(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setPopOverPost(null);
    setPopoverElement(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const postActionProps = {
    popoverElement: popoverElement,
    handleCloseMenu,
    handleRemoveFromOrg,
    handleOpenConfirm,
    selectedPopOverPost: selectedPopOverPost,
  };
  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <>
          {teamMembers && teamMembers.length > 0 ? (
            <Card sx={{ m: 1 ,minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)'}}>
              <DynamicHeightComponent>
                <Scrollbar>
                  <TableContainer sx={{
                    maxHeight: height, height: height - 60
                  }}>
                    <Table stickyHeader>
                      <UserListHead
                        headLabel={[
                          { id: 'profile', label: 'Profile', alignRight: false },
                          { id: 'name', label: 'Team Member', alignRight: false },
                          { id: 'email', label: 'Email', alignRight: false },
                          { id: 'status', label: 'Status', alignCenter: true },
                          { id: 'action', label: 'Action', alignCenter: true },
                        ]}
                        rowCount={teamMembers.length}
                        isShowCheckbox={false}
                      />
                      <TableBody>
                        {teamMembers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((member) => (
                          <TableRow hover key={member.id} tabIndex={-1}>
                            <TableCell component="th" scope="row" sx={{ py: 3 }}>
                              <Avatar
                                src={member.image_url ? member.image_url : member.first_name.charAt(0).toUpperCase()}
                                alt={member.first_name.charAt(0).toUpperCase()}
                                sx={{ height: 40, width: 40, bgcolor: '#00AB55', color: '#ffffff' }}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <Typography>{member.first_name}</Typography>
                            </TableCell>
                            <TableCell align="left">{member.email}</TableCell>
                            <TableCell align="center">
                              <StyledComponent.SwitchIcon
                                sx={{ m: 1 }}
                                checked={member.is_enabled}
                                onChange={() => {
                                  handleAccountStatus(member);
                                }}
                              ></StyledComponent.SwitchIcon>
                            </TableCell>
                            <TableCell align="center">
                              {!member?.is_current_user && (
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(e) => {
                                    handleOpenMenu(e, member);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 15, 25]}
                    count={teamMembers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Scrollbar>
              </DynamicHeightComponent>
              <ConfirmationDialog
                open={confirmOpen}
                onClose={handleCloseConfirm}
                onConfirm={handleConfirmAction}
                title="Confirm Remove User From Organization"
                message="Are you sure you want to remove this user from the organization"
              />
            </Card>
          ) : (
            <Card>
              <NoPostFound height={height} />
            </Card>
          )}
          <OrgManageUserAction {...postActionProps} />
        </>
      )}
    </>
  );
};

export default OrgManageUsers;

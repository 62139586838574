import { Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import { LoginForm } from '../sections/auth/login';
import { CommonHelper } from 'src/utils/commonHelper';
import landingImage from "../assets/app-image/landing.png";

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const LoginPage = () => {

  const isBrand2SocialDomain = window.location.origin.includes(".brand2social.com");
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const appLogo = CommonHelper.GetAppLogo(true);
  const navigateToRegister = () => {
    navigate('/register');
  };

  const logoStyle = {
    height: '40px',
    position: 'fixed',
    top: '34px',
    left: '35px',
  };

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <>
            <img src={appLogo} style={logoStyle} />
            <StyledSection>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                Hi, Welcome Back
              </Typography>
              <img src={landingImage} alt="login" />
            </StyledSection>
          </>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to B2S
            </Typography>

            {isBrand2SocialDomain && <Typography variant="body2" sx={{ mb: 3 }}>
              Don’t have an account? {''}
              <Link style={{ cursor: 'pointer' }} onClick={navigateToRegister}>
                <strong>Get started</strong>
              </Link>
            </Typography>}
            <LoginForm isBrand2SocialDomain={isBrand2SocialDomain} />
          </StyledContent>
        </Container>
      </StyledRoot >
    </>
  );
};
export default LoginPage;


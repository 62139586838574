import { Crypto } from "../../services/crypto.jsx";

const getItemFromLocalStorage = (name) => {
    let value = localStorage.getItem(name);
    return value ? Crypto.decryption(value) : "";
}
const setItemToLocalStorage = (name, content) => {
    localStorage.setItem(name, Crypto.encryption(content));
}

export const LocalStorage = {
    setItem: setItemToLocalStorage,
    getItem: getItemFromLocalStorage,
};
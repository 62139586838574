import {
    Avatar, Button, Card, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import UserListHead from '../../sections/@dashboard/user/UserListHead';
import NoPostFound from '../publishedPosts/noPost.component';
import { CommonHelper } from 'src/utils/commonHelper';

const ProfileDetails = () => {

    const isOrgUser = CommonHelper.GetBrandInfoFromLocalStorage("is_org_user");

    const { showToast } = useToaster();

    const [brandList, setBrandList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState(false);
    const [leaveBrandId, setLeaveBrandId] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [height, setHeight] = useState(window.innerHeight - 150);

    useEffect(() => {
        fetchUsersBrand();
    }, [])

    const fetchUsersBrand = () => {
        setLoader(true);
        let postProps = {
            url: userModuleURL.fetchUsersBrand,
            successCallback,
            failureCallback
        };
        HttpServices.Get(postProps);
    }
    const successCallback = (data, message) => {
        setBrandList(data);
        message && showToast(message, "s")
        setLoader(false)
    }
    const failureCallback = (message) => {
        message && showToast(message, "e")
        setLoader(false)
    }
    const handleBrandStatus = (brand) => {
        let bodyProps = {
            brand_id: brand.brand_id,
        };
        let postProps = {
            url: brand.is_enabled ? userModuleURL.disableBrand : userModuleURL.enableBrand,
            body: bodyProps,
            successCallback,
            failureCallback
        }
        HttpServices.Post(postProps);
    }
    const handleLeaveBrand = (id) => {
        setLeaveBrandId(id);
        setConfirmOpen(true);
    }

    const confirmLeaveBrand = () => {
        setConfirmOpen(false);
        let brandprops = { brand_id: leaveBrandId };
        let postProps = {
            url: userModuleURL.leaveBrand,
            body: brandprops,
            successCallback: updateSuccessCallback,
            failureCallback
        };
        HttpServices.Post(postProps);
    };
    const updateSuccessCallback = (data, message) => {
        showToast(message, "s");
        fetchUsersBrand();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
            <Helmet>
                <title> Profile Details </title>
            </Helmet>
            {loader ?
                <Spinner />
                :
                <>
                    {brandList && brandList.length > 0 ?
                        <Card sx={{ m: 1,minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                            <DynamicHeightComponent>
                                <Scrollbar>
                                    <TableContainer sx={{
                                        maxHeight: height, height: height - 60
                                    }}>
                                        <Table stickyHeader>
                                            <UserListHead
                                                headLabel={[
                                                    { id: 'profile', label: 'Profile', alignCenter: false },
                                                    { id: 'brand name', label: 'Brand Name', alignRight: false },
                                                    { id: 'role', label: 'Role', alignRight: false },
                                                    { id: 'Permission', label: 'Permissions', alignRight: false },
                                                    ...(!isOrgUser ? [{ id: 'delete', label: 'Delete', alignCenter: true }] : []),
                                                ]}

                                                rowCount={brandList.length}
                                                isShowCheckbox={false}
                                            />
                                            <TableBody>
                                                {brandList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((brand) => (
                                                    <TableRow hover key={brand.brand_id} tabIndex={-1}>
                                                        <TableCell component="th" scope="row" sx={{ width: "10%" }}>
                                                            <Avatar
                                                                src={brand.brand_image ? brand.brand_image : brand.brand_name.charAt(0).toUpperCase()}
                                                                alt={brand.brand_name ? brand.brand_name.charAt(0).toUpperCase() : brand.brand_id}
                                                                sx={{ height: 40, width: 40, bgcolor: '#00AB55', color: '#ffffff' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ width: "10%" }}>{brand.brand_name}</TableCell>
                                                        <TableCell align="left" sx={{ width: "20%" }}>
                                                            <Label color='error'>
                                                                {brand.role_name}
                                                            </Label>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ width: "50%" }}>
                                                            {brand.role_permissions.map((permission) => (
                                                                <>
                                                                    {permission.has_permission && <Label key={permission.id} color='success'>
                                                                        {permission.display_name}
                                                                    </Label>}
                                                                </>
                                                            ))}

                                                        </TableCell>
                                                        {!isOrgUser && <TableCell align="center" sx={{ width: "10%" }}>
                                                            <Button variant='contained' color='error' onClick={() => { handleLeaveBrand(brand.brand_id) }} >Leave Brand</Button>
                                                        </TableCell>}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[ 10,15, 25]}
                                        count={brandList.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                    <ConfirmationDialog
                                        open={confirmOpen}
                                        onClose={() => setConfirmOpen(false)}
                                        onConfirm={confirmLeaveBrand}
                                        title="Confirm Leave Brand"
                                        message="Are you sure you want to leave this brand?"
                                    />
                                </Scrollbar>
                            </DynamicHeightComponent>
                        </Card>
                        :
                        <Card>
                            <NoPostFound height={height} />
                        </Card>
                    }
                </>
            }
        </>
    );
}
export default ProfileDetails

import { useEffect, useState } from "react";
import HttpServices from "src/services/httpService";
import { userModuleURL } from "src/services/urlService";
import Spinner from "src/utils/Loader/spinner";
import { useToaster } from "src/utils/toaster/toasterContext";
import PendingApprovalGrid from "./pending-approval-grid.component";
import { useParams } from 'react-router-dom';

const PendingApproval = ({ publicUserName }) => {
    const [loader, setLoader] = useState(true);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();
    const [pendingApprovalData, setPendingApprovalData] = useState([]);
    const { showToast } = useToaster();
    const { urlKey } = useParams();

    useEffect(() => {
        getPendingApprovals("scheduled_time", "asc");
    }, [])
    const getPendingApprovals = (sortBy = "", sortOrder = "") => {
        setOrder(sortOrder)
        setOrderBy(sortBy)
        let getProps = {
            url: userModuleURL.getUnapprovedPostPublic + `?sort_by=${sortBy}&sort_order=${sortOrder}&url_key=${urlKey}`,
            successCallback: getPendingApprovalSuccessCallback,
            failureCallback
        }
        HttpServices.Get(getProps);
    }
    const getPendingApprovalSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        setPendingApprovalData(data);
        setLoader(false);
    }
    const failureCallback = (message) => {
        message && showToast(message, "e");
        setLoader(false);
    }
    const handleReCallPendingApprovalPost = (sortBy = "", sortOrder = "") => {
        setLoader(true);
        getPendingApprovals(sortBy, sortOrder);
    }
    return (
        <>
            {loader ?
                <Spinner />
                :
                <PendingApprovalGrid
                    pendingApprovalList={pendingApprovalData}
                    orderBy={orderBy}
                    order={order}
                    publicUserName={publicUserName}
                    handleReCallPendingApprovalPost={handleReCallPendingApprovalPost}
                />
            }
        </>
    );
}
export default PendingApproval;
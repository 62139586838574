import { useTheme } from '@mui/material/styles';

const NoPost = () => {
    const theme = useTheme();
    const keyframesFadeIn = `
        @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
        }
    `;

    const keyframesBounce = `
        @keyframes bounce {
            0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
            40% { transform: translateY(-20px); }
            60% { transform: translateY(-10px); }
        }
    `;

    return (
        <div style={{ position: 'relative', width: '100%', height: '100px', backgroundColor: '#fff' }}>
            <style>
                {keyframesFadeIn}
                {keyframesBounce}
            </style>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100" viewBox="0 0 250 30">
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    style={{
                        fontFamily: 'sans-serif',
                        fontSize: '24px',
                        fill: theme.palette.primary.main,
                        fontWeight: '800',
                        opacity: 0,
                        animation: 'fadeIn 1.5s ease-out forwards',
                    }}
                >
                    No Post Available
                </text>
            </svg>
        </div>
    );
};

export default NoPost;
import React, { useEffect, useState } from 'react';
const DynamicHeightComponent = ({ children, heightRatio }) => {
    const [height, setHeight] = useState(window.innerHeight - heightRatio);
    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight - heightRatio);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const containerStyle = {
        height: `${height}px`,
    };
    return (
        <div style={containerStyle}>
            {children}
        </div>
    );
};

export default DynamicHeightComponent;

import { useState } from "react";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import { useToaster } from "src/utils/toaster/toasterContext";
import TwitterAppSumoAuthorizationModal from "./twitterAppSumoAuthorizationModal";

const TwitterAppSumoIntegration = ({ handleClose }) => {
  const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
  const [appKey, setAppKey] = useState('');
  const [appSecretKey, setAppSecretKey] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [accessSecretToken, setAccessSecretToken] = useState('')
  const [isLoadingOn, setLoadingOn] = useState(false);
  const { showToast } = useToaster();

  const handeleInputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    switch (name) {
      case 'appKey':
        setAppKey(value);
        break;
      case 'appSecretKey':
        setAppSecretKey(value);
        break;
      case 'accessToken':
        setAccessToken(value);
        break;
      case 'accessSecretToken':
        setAccessSecretToken(value);
        break;
      default:
        break;
    }
  }
  const twitterIntegrationSuccessCallback = (data, message) => {
    setLoadingOn(false);
    showToast(message, 's');
    handleCloseTwitter();
  }
  const twitterIntegrationFailureCallback = (message) => {
    setLoadingOn(false);
    showToast(message, 'e');
    handleCloseTwitter();
  }
  const sendAccessTokenToServer = () => {
    if (appKey && appSecretKey && accessToken && accessSecretToken) {
      setLoadingOn(true);
      let twitterForm = {
        access_token: accessToken,
        consumer_key: appKey,
        consumer_key_secret: appSecretKey,
        type: "DEVELOPER",
        access_token_secret: accessSecretToken,

      };
      let postProps = {
        url: integrateModuleURL.sendDeveloperTwitterAccessToken,
        body: twitterForm,
        successCallback: twitterIntegrationSuccessCallback,
        failureCallback: twitterIntegrationFailureCallback
      }
      HttpServices.Post(postProps);
    }
    else {
      setLoadingOn(false);
      showToast("Please enter all key fields", 'e');
    }
  };
  const handleCloseTwitter = () => {
    handleClose();
  }
  return (
    isAuthorizationModalOpen &&
    <TwitterAppSumoAuthorizationModal
      handlTwitterOauthToken={sendAccessTokenToServer}
      handleClose={handleCloseTwitter}
      handleInputChange={handeleInputChange}
      appKey={appKey}
      appSecretKey={appSecretKey}
      isLoadingOn={isLoadingOn}
    />
  )
};
export default TwitterAppSumoIntegration;

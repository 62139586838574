import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'src/routes';
import MainPage from './MainPage';
import { CommonHelper } from './utils/commonHelper';
import { LocalStorage } from './utils/storage/local-storage';

const OutComponent = ({ isDesktop }) => {

    const navigate = useNavigate();

    const routes = Routes.PublicRoutes();

    useEffect(() => {
        checkAndNavigateToRoute();
    }, [])

    const checkAndNavigateToRoute = () => {
        let route = "login";
        let initialLoad = LocalStorage.getItem('initial-load');
        if (initialLoad) {
            let data = JSON.parse(initialLoad);
            if (data) {
                if (data.org_id) {
                    if (data.is_user_enabled) {
                        let currentBrandId = CommonHelper.GetCurrentBrandId();
                        if (!currentBrandId) {
                            sessionStorage.clear();
                            route = "no-brand";
                        }
                        else {
                            let brandDetails = data.brand_details?.filter(a => a.is_enabled && a.brand_id === currentBrandId);
                            if (!(brandDetails?.length > 0)) {
                                sessionStorage.clear();
                                route = "no-brand";
                            }
                        }
                    }
                    else {
                        route = "user-disable";
                    }
                }
                else {
                    route = "no-organization";
                }
            }
        }
        else {
            localStorage.clear();
            sessionStorage.clear();
        }
        navigate(route);
    }

    return (
        <MainPage isDesktop={isDesktop} routes={routes} />
    );
}
export default OutComponent;
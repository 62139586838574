import { Crypto } from "../../services/crypto.jsx";

const getItemFromSessionStorage = (name) => {
    let value = sessionStorage.getItem(name);
    return value ? Crypto.decryption(value) : "";
}
const setItemToSessionStorage = (name, content) => {
    sessionStorage.setItem(name, Crypto.encryption(content));
}
export const SessionStorage = {
    setItem: setItemToSessionStorage,
    getItem: getItemFromSessionStorage
};
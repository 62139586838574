import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    TextField,
    Grid,
    Chip,
    Typography,
    useMediaQuery,
    Stack,
    Container,
    TableContainer,
} from '@mui/material';
import AudienceSummaryComponent from './audience-summary.component';
import PostEngagementSummaryComponent from './post-engagement-summary.component';

const SummaryComponent = ({ facebookPageId, startDate, endDate, viewBy, summary }) => {
    const audienceSummary = summary?.report_card?.find(item => item.metric_name === "audience_summary");
    const engagementSummary = summary?.report_card?.find(item => item.metric_name === "post_and_engagement_summary");

    return (
        <TableContainer sx={{ mt:1,minHeight: 'calc(100vh - 260px)', maxHeight: 'calc(100vh - 260px)' }}>
            <Stack spacing={2} direction='column'>
                <Typography variant="h5" gutterBottom>
                    Audience Summary
                </Typography>
                <AudienceSummaryComponent
                    facebookPageId={facebookPageId}
                    startDate={startDate}
                    endDate={endDate}
                    viewBy={viewBy}
                    audienceSummary={audienceSummary} 
                />
            </Stack>
            <Stack spacing={2} direction='column' sx={{ mt: 1 }}>
                <Typography variant="h5" gutterBottom>
                    Post & Engagement Summary
                </Typography>
                <PostEngagementSummaryComponent
                    facebookPageId={facebookPageId}
                    startDate={startDate}
                    endDate={endDate}
                    viewBy={viewBy}
                    engagementSummary={engagementSummary}
                />
            </Stack>
        </TableContainer>
    );
};

export default SummaryComponent;

import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select, Button, Box, Typography, useTheme } from '@mui/material';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import PinterestCreateBoard from './pinterest-create-board';

const BoardView = ({ profileId, profile, handleChangeBoard }) => {
  const theme = useTheme();
  const [boards, setBoards] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState('');
  const [pinterestCreateBoardOpen, setPinterestCreateBoardOpen] = useState(false);

  // Fetch Boards and set the first one as selected
  const successCallback = (data, message) => {
    setBoards(data);
    if (data.length > 0) {
      const firstBoardId = data[0].board_id;
      setSelectedBoardId(firstBoardId);
      handleChangeBoard(profileId, profile, firstBoardId);
    }
  };

  const failureCallback = (error) => {
    console.error('Error fetching boards:', error);
  };

  useEffect(() => {
    const getProps = {
      url: `${integrateModuleURL.FetchAllBoards}${profileId}`,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  }, [profileId]);

  // Handle board change
  const handleActionChange = (newBoardId) => {
    // if (newBoardId === 'Create Board') {
    //   setPinterestCreateBoardOpen(true);
    // } else {
    setSelectedBoardId(newBoardId);
    handleChangeBoard(profileId, profile, newBoardId);
  };

  const handleCreateBoard = () => {
    setPinterestCreateBoardOpen(true);
  }

  // Handle the new board creation
  const NewBoardCreationCallback = (newBoard) => {
    setBoards((prevBoards) => [...prevBoards, newBoard]);
    setSelectedBoardId(newBoard.board_id);
    setPinterestCreateBoardOpen(false);
    handleChangeBoard(profileId, profile, newBoard.board_id);
  };

  const handleClose = () => {
    setPinterestCreateBoardOpen(false);
  };

  return (
    <>
      <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2, marginBottom: '8px' }}>Boards</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom:'8px' }}>
        <FormControl sx={{ flexGrow: 1 }}>
          <Select
            value={selectedBoardId}
            onChange={(event) => handleActionChange(event.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            fullWidth
            size="small"
          >
            {!boards.length && (
              <MenuItem value="">
                No Boards Available
              </MenuItem>
            )}
            {boards.map((board) => (
              <MenuItem key={board.board_id} value={board.board_id}>
                {board.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleCreateBoard}
          sx={{ whiteSpace: 'nowrap' }} // Prevents the button text from wrapping
        >
          Create Board
        </Button>

        {pinterestCreateBoardOpen && (
          <PinterestCreateBoard
            handleClose={handleClose}
            profileId={profileId}
            NewBoardCreationCallback={NewBoardCreationCallback}
          />
        )}
      </Box>
    </>
  );
};

export default BoardView;

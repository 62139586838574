import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { MenuItem, Popover } from '@mui/material';

const OrgManageUserAction = ({
  popoverElement,
  handleCloseMenu,
  handleOpenConfirm,
  
}) => {
  return (
    <Popover
      open={Boolean(popoverElement)}
      anchorEl={popoverElement}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: { p: 1, width: 200, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
      }}
    >
      <MenuItem sx={{ color: 'warning.main' }} onClick={() =>handleOpenConfirm('remove')}>
        <RemoveCircleIcon sx={{ mr: 1 }} /> Remove from Org
      </MenuItem>
    </Popover>
  );
};
export default OrgManageUserAction;

import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function GlobalStyles() {
  const theme = useTheme();
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '::-webkit-scrollbar': {
          width: '0.2em'
        },
        '::-webkit-scrollbar-track': {
          background: theme.palette.primary.lighter
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: 50,
          backgroundColor: theme.palette.primary.main,
        },
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        '& .fc-popover-body': {
          overflow: 'auto',
          height: '70vh',
          minWidth: '250px !important',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  );

  return inputGlobalStyles;
}

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./checkoutForm";
import { Config } from 'src/config';

const StripePaymentComponent = ({ clientSecret, amount }) => {
    const stripePromise = loadStripe(Config.Stripe.clientId);
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    return (
        <div className="stripe-payment-c">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm amount={amount} />
                </Elements>
            )}
        </div>
    );
}
export default StripePaymentComponent;
import { LoadingButton } from '@mui/lab';
import { Card, Stack, Grid, Box, Typography, InputLabel, IconButton, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import HttpServices from 'src/services/httpService';
import ValidationTool from 'src/utils/validationHelper';
import axios from 'axios';
import { imageModeuleUrl, userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ColorPickerComponent from 'src/components/color-picker/color-picker.component';
import BrandingColorPopover from './branding-color-popover';
import ColorizeRoundedIcon from '@mui/icons-material/ColorizeRounded';
import { Helmet } from 'react-helmet-async';
import InfoIcon from '@mui/icons-material/Info';

const BrandingInfo = () => {
  const [orgWebsite, setOrgWebsite] = useState('');
  const [orgPrimaryColor, setOrgPrimaryColor] = useState('');
  const [orgSecondaryColor, setOrgSecondaryColor] = useState('');
  const [orgSmallIcon, setOrgSmallIcon] = useState('');
  const [orgLargeIcon, setOrgLargeIcon] = useState('');
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [formSchema, setFormSchema] = useState({});
  const [isSubmitting, setSumbitLoading] = useState(false);
  const maxFileSizeInBytes = 10485760;
  const [fileUploadprogress, setFileUploadprogress] = useState(0);
  const [popoverElement, setPopoverElement] = useState(null);
  const [selectedPopOverData, setSelectedPopOverData] = useState('');
  const supportedFileFormat = ['image/jpeg', 'image/png'];
  const { showToast } = useToaster();

  useEffect(() => {
    generateFormModelSchema();
    getBrandingDetails();
  }, []);

  const getBrandingDetails = () => {
    let postProps = {
      url: userModuleURL.getBrandingDetails,
      successCallback,
      failureCallback,
    };
    HttpServices.Get(postProps);
  };
  const successCallback = (data, message) => {
    message && showToast(message, 's');
    setOrgWebsite(data.organization_url);
    setOrgSmallIcon(data.domain_small_icon);
    setOrgLargeIcon(data.domain_large_icon);
    setOrgPrimaryColor(data.org_primary_color);
    setOrgSecondaryColor(data.org_secondary_color);
  };
  const failureCallback = (message) => {
    message && showToast(message, 'e');
    setSumbitLoading(false);
  };
  const generateFormModelSchema = () => {
    let fields = [
      ValidationTool.GenerateSchema('orgWebsite', 'Organization Website URL', 'TEXT', false),
      ValidationTool.GenerateSchema('orgPrimaryColor', 'Organization Primary Color', 'TEXT', false),
      ValidationTool.GenerateSchema('orgSecondaryColor', 'Organization Secondary Color', 'TEXT', false),
      ValidationTool.GenerateSchema('orgSmallIcon', 'Organization Small Icon', 'TEXT', false),
      ValidationTool.GenerateSchema('orgLargeIcon', 'Organization Large Icon', 'TEXT', false),
    ];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };

  const defaultValues = {
    orgWebsite: orgWebsite || '',
    orgPrimaryColor: orgPrimaryColor || '',
    orgSecondaryColor: orgSecondaryColor || '',
    orgSmallIcon: orgSmallIcon || '',
    orgLargeIcon: orgLargeIcon || '',
  };

  const methods = useForm({
    defaultValues,
  });

  const onSubmit = () => {
    setSumbitLoading(true);
    let domainProps = {
      organization_url: orgWebsite,
      domain_small_icon: orgSmallIcon,
      domain_large_icon: orgLargeIcon,
      org_primary_color: orgPrimaryColor,
      org_secondary_color: orgSecondaryColor,
    };
    let postProps = {
      url: userModuleURL.updateBrandingDetails,
      body: domainProps,
      successCallback: successFromSubmitCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const successFromSubmitCallback = (data, message) => {
    message && showToast(message, 's');
    setSumbitLoading(false);
    getBrandingDetails();
  };

  const { handleSubmit } = methods;
  const handleSubmitForm = () => {
    let userForm = {
      orgWebsite: orgWebsite,
      orgPrimaryColor: orgPrimaryColor,
      orgSecondaryColor: orgSecondaryColor,
      orgSmallIcon: orgSmallIcon,
      orgLargeIcon: orgLargeIcon,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, userForm);
    if (isValidForm) {
      setFormValidationErrors({});
      onSubmit();
    } else setFormValidationErrors(formValidationErrors);
  };
  const handleDropSmallIcon = (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      handleFileUpload(file, 'smallicon');
    } else {
      showToast('We only support JPEG, and PNG file formats. The selected file are not in the supported format', 'e');
    }
  };
  const handleDropLargeIcon = (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      handleFileUpload(file, 'largeicon');
    } else {
      showToast('We only support JPEG, and PNG file formats. The selected file are not in the supported format', 'e');
    }
  };
  const handleFileUpload = (file, iconType) => {
    let isFileInCorrectFormat = supportedFileFormat.some((a) => a.includes(file.type));
    if (isFileInCorrectFormat) {
      if (file.size < maxFileSizeInBytes) {
        setFileUploadprogress(1);
        let formData = new FormData();
        formData.append('fileupload', file);
        axios
          .post(imageModeuleUrl.uploadMedia, formData, {
            headers: HttpServices.GetRequestHeader('form', true),
            onUploadProgress: (data) => {
              let loadedProgress = Math.round((100 * data.loaded) / data.total);
              setFileUploadprogress(loadedProgress > 90 ? 90 : loadedProgress);
            },
          })
          .then((result) => result)
          .then((response) => {
            setFileUploadprogress(100);
            if (response.status === 200 && response.data.http_code === 200)
              fileUploadSuccessCallback(response.data.data, iconType, 'File Uploaded Successfully');
            else showToast('Something went wrong. Please contact our technical team', 'e');
            setFileUploadprogress(0);
          })
          .catch((error) => {
            setFileUploadprogress(0);
            showToast(error.toString(), 'e');
          });
      } else showToast('Maximum file size for image is 10MB. Please select image below 10 MB', 'e');
    } else {
      showToast('We only support JPEG, and PNG file formats. The selected file are not in the supported format', 'e');
    }
  };
  const fileUploadSuccessCallback = (data, iconType, message) => {
    if (iconType === 'smallicon') setOrgSmallIcon(data.media_data[0].media_url);
    else setOrgLargeIcon(data.media_data[0].media_url);
  };
  const handleInputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    switch (name) {
      case 'orgWebsite':
        setOrgWebsite(value);
        break;
      case 'orgPrimaryColor':
        setOrgPrimaryColor(value);
        break;
      case 'orgSecondaryColor':
        setOrgSecondaryColor(value);
        break;
      default:
        break;
    }
  };
  const handleColor = (color, type) => {
    if (type === 'primary') setOrgPrimaryColor(color.hex);
    else setOrgSecondaryColor(color.hex);
  };
  const handleCloseMenu = () => {
    setPopoverElement(null);
  };
  const brandActionProps = {
    popoverElement: popoverElement,
    selectedPopOverData: selectedPopOverData,
    handleCloseMenu,
    handleColor,
    color: orgPrimaryColor,
  };
  const handleOpenMenu = (event, data) => {
    setSelectedPopOverData(data);
    setPopoverElement(event.currentTarget);
  };
  const handleDeleteOrgLargeImage = () => {
    setOrgLargeIcon('');
  };
  const handleDeleteOrgSmallImage = () => {
    setOrgSmallIcon('');
  };

  return (
    <>
      <Helmet>
        <title>Branding</title>
      </Helmet>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" height="100%">
              <Card sx={{ py: 2, px: 1, textAlign: 'center', flexGrow: 1 }}>
                <InputLabel style={{ paddingBottom: '0.5rem' }}>Favicon Image</InputLabel>
                <Box position="relative" display="inline-block">
                  <RHFUploadAvatar
                    name="orgSmallIcon"
                    accept="image/*"
                    maxSize={5242880}
                    onDrop={handleDropSmallIcon}
                    url={orgSmallIcon}
                    handleDeleteProfiles={handleDeleteOrgSmallImage}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of 5Mb
                      </Typography>
                    }
                  />
                </Box>
                <InputLabel style={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}>Header Logo Image</InputLabel>
                <Box position="relative" display="inline-block">
                  <RHFUploadAvatar
                    name="orgLargeIcon"
                    accept="image/*"
                    maxSize={5242880}
                    onDrop={handleDropLargeIcon}
                    handleDeleteProfiles={handleDeleteOrgLargeImage}
                    url={orgLargeIcon}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of 5Mb
                      </Typography>
                    }
                  />
                </Box>
              </Card>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box display="flex" flexDirection="column" height="100%">
              <Card sx={{ p: 3, flexGrow: 1 }}>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name="orgWebsite"
                    value={orgWebsite}
                    label="Organization Website URL"
                    onChange={handleInputChange}
                    error={formValidationErrors?.orgWebsite}
                    helperText={formValidationErrors?.orgWebsite}
                  />
                  <RHFTextField
                    name="orgPrimaryColor"
                    value={orgPrimaryColor}
                    label="Organization Primary Color"
                    onChange={handleInputChange}
                    error={formValidationErrors?.orgPrimaryColor}
                    helperText={formValidationErrors?.orgPrimaryColor}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, 'primary');
                            }}
                          >
                            <ColorizeRoundedIcon />
                          </IconButton>
                          {orgPrimaryColor && (
                            <Box
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                boxShadow: 1,
                                backgroundColor: orgPrimaryColor,
                                marginLeft: 1,
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <RHFTextField
                    name="orgSecondaryColor"
                    value={orgSecondaryColor}
                    label="Organization Secondary Color"
                    onChange={handleInputChange}
                    error={formValidationErrors?.orgSecondaryColor}
                    helperText={formValidationErrors?.orgSecondaryColor}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="large"
                            color="green"
                            onClick={(e) => {
                              handleOpenMenu(e, 'secondary');
                            }}
                          >
                            <ColorizeRoundedIcon />
                          </IconButton>
                          {orgSecondaryColor && (
                            <Box
                              style={{
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                boxShadow: 1,
                                backgroundColor: orgSecondaryColor,
                                marginLeft: 1,
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Stack spacing={3} alignItems="flex-end" sx={{ mt: 5 }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      Submit
                    </LoadingButton>
                  </Stack>
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
      <BrandingColorPopover {...brandActionProps} />
    </>
  );
};
export default BrandingInfo;

import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ProfileDetails from '../profile-details/profile-details';
import AccountChangePassword from './accountChangePassword';
import Account from './accountGeneral';

export default function UserAccount() {

    const [currentTab, setCurrentTab] = useState('general');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let ACCOUNT_TABS = [
        {
            value: 'general',
            icon: <AccountBoxSharpIcon sx={{ width: "20px", height: "20px" }} />,
            component: <Account />,
            mobile: true
        },
        {
            value: 'change_password',
            icon: <VpnKeyIcon sx={{ width: "20px", height: "20px" }} />,
            component: <AccountChangePassword />,
            mobile: true
        },
        {
            value: 'profile details',
            icon: <AccountCircleIcon sx={{ width: "20px", height: "20px" }} />,
            component: <ProfileDetails />,
            mobile: false
        }
    ];

    if (isMobile) {
        ACCOUNT_TABS = ACCOUNT_TABS.filter(a => a.mobile);
    }

    return (
        <>
            <Helmet>
                <title>Profile</title>
            </Helmet>
            <Box>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ gap: '0.5rem', minHeight: '37px',mt:'0.6rem' }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => {
                        return <Tab
                            disableRipple
                            key={tab.value}
                            label={isMobile ? "" : capitalCase(tab.value)}
                            icon={tab.icon}
                            value={tab.value}
                            sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }}
                        />
                    })}
                </Tabs>

            </Box>
            <Box sx={{ mb: 2 }} />
            {ACCOUNT_TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </>
    );
}

import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { UserListHead } from 'src/sections/@dashboard/user';

const EllapsedPostGridHead = ({ ellapsedPostList, order, orderBy, handleRequestSort }) => {
  const getTableDesk = [
    { id: 'scheduled_time',width:'10%', label: 'Scheduled', alignRight: false, hasSort: true },
    { id: 'created_time',width:'10%', label: 'Created', alignRight: false, hasSort: true },
    { id: 'updated_at',width:'10%', label: 'Updated', alignRight: false, hasSort: true },
    { id: 'description',width:'15%', label: 'Post  Content', alignRight: false, hasSort: true },
    { id: 'media',width:'15%', label: 'Media', alignRight: false },
    { id: 'created_by_name',width:'10%', label: 'Created By', alignRight: false, hasSort: true },
    // { id: 'is_enabled', label: 'Status', alignRight: false, hasSort: true },
    { id: 'scheduled-for',width:'20%', label: 'Scheduled For', alignRight: false },
    { id: 'action',width:'10%', label: 'Action', alignCenter: true },
  ];
  const getTableMobile = [
    { id: 'scheduled-on', label: 'Scheduled On', alignRight: false, hasSort: true },
    { id: 'post-content', label: 'post-content', alignRight: false, hasSort: true },
    { id: 'action', label: 'Action', alignRight: false },
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;

  return (
    <UserListHead
      order={order}
      orderBy={orderBy}
      headLabel={TABLE_HEAD}
      rowCount={ellapsedPostList.length}
      onRequestSort={handleRequestSort}
      style={{ whiteSpace: 'nowrap' }}
      isShowCheckbox={false}
    />
  );
};

export default EllapsedPostGridHead;

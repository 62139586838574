import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '../../assets/deletecomment.svg'
import { MenuItem, Popover, Typography } from '@mui/material';

const ManageUserAction = ({
  popoverElement,
  handleCloseMenu,
  handleRemoveUserFromOrg,
  handleRemoveFromBrand,
  handleOpenConfirm,
  handleUpdate,
  selectedPopOverPost
}) => {
  return (
    <Popover
      open={Boolean(popoverElement)}
      anchorEl={popoverElement}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 'auto',
          boxShadow: 3,
          borderRadius: 2,
          backdropFilter: 'blur(8px)',
          backgroundColor: 'action.disabledOpacity',
          boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
          '& .MuiMenuItem-root': {
            px: 2.5,
            py: 1.5,
            typography: 'body2',
            borderRadius: 2,
            transition: 'background-color 0.2s, transform 0.2s',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'action.disabledOpacity',
              transform: 'scale(1.02)',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
              marginRight: 2,
            },
            '& .MuiTypography-root': {
              fontWeight: 500,
            },
          },
        },
      }}
    >
      {!selectedPopOverPost?.is_org_user && <MenuItem onClick={() => handleOpenConfirm("brand")}>
        <RemoveCircleIcon sx={{ mr: 1, color: 'primary.main' }} />
        <Typography sx={{ fontWeight: 500 }}>Remove from Brand</Typography>
      </MenuItem>}
      <MenuItem sx={{ color: 'common.black' }} onClick={handleUpdate}>
        <UploadIcon sx={{ mr: 1, color: 'primary.main' }} />
        <Typography sx={{ fontWeight: 500 }}>Update User</Typography>
      </MenuItem>
      <MenuItem sx={{ color: 'error.main' }} onClick={() => {
        handleOpenConfirm("org")
      }}>
        <img src={DeleteIcon} style={{marginRight: "0.5rem", width: 23, height: 23 }} />
        <Typography sx={{ fontWeight: 500 }}>Remove User From Org</Typography>
      </MenuItem>
    </Popover>
  );
};
export default ManageUserAction;

const PinterestRedirect = () => {
    const popupWindowURL = new URL(window.location.href);
    const code = popupWindowURL.searchParams.get("code");
    const error = popupWindowURL.searchParams.get("error");
    const errorDescription = popupWindowURL.searchParams.get("error_description");
    if (code) {
        localStorage.setItem("pinterest_code", `${code}`);
        window.close();
    }
    else if (error) {
        localStorage.setItem("pinterest_code_error", `${errorDescription}`);
        window.close();
    }
    return null;
}
export default PinterestRedirect;
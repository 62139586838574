let originUrl = window.location.origin;
let baseURL = originUrl.includes("localhost") ? process.env.REACT_APP_API_URL : `${originUrl}/api`;

export const userModuleURL = {
  facebookLoginUrl: baseURL + 'facebookLogin',
  login: baseURL + '/profile-master-services/public/login',
  register: baseURL + '/profile-master-services/public/create-account',
  resetPassword: baseURL + '/profile-master-services/public/forget-password',
  changePasswordRequest: baseURL + '/profile-master-services/public/change-password-request',
  sendAuthCodeToServer: baseURL + '/profile-master-services/public/google-signup',
  logout: baseURL + '/profile-master-services/private/logout',
  fetchUserDetails: baseURL + '/profile-master-services/private/fetch-user-details',
  updateUserDetails: baseURL + '/profile-master-services/private/update-user-details',
  updatePasswordRequest: baseURL + '/profile-master-services/private/update-password',
  getAllUserList: baseURL + '/profile-master-services/private/get-all-users',
  deleteUser: baseURL + '/profile-master-services/private/delete-user?id=',
  verifyUser: baseURL + '/profile-master-services/public/verify-user',
  fetchNotification: baseURL + '/notification-services/fetch-notification',
  updateNotification: baseURL + '/notification-services/update-read-receipts',
  getPageDetails: baseURL + '/profile-master-services/private/get-page-details',
  resendVerificationMail: baseURL + '/profile-master-services/private/resend-verify-email',
  getAllPlan: baseURL + '/payment-services/get-all-plans?currency=',
  appSumoRegisteration: baseURL + '/profile-master-services/public/create-appsumo-account',
  couponVerify: baseURL + '/profile-master-services/public/coupon-verify',
  getAllBrands: baseURL + '/profile-master-services/private/get-all-brands',
  fetchUsersBrand: baseURL + '/profile-master-services/private/fetch-users-brand',
  intialLoad: baseURL + '/profile-master-services/private/initial-load',
  createNewBrand: baseURL + '/profile-master-services/private/create-new-brand?brand_id=',
  updateBrand: baseURL + '/profile-master-services/private/update-brand-details',
  leaveBrand: baseURL + '/profile-master-services/private/leave-from-brand',
  leaveOrganization: baseURL + '/profile-master-services/private/leave-from-org',
  deleteBrand: baseURL + '/profile-master-services/private/delete-brand',
  getAllRoles: baseURL + '/profile-master-services/private/fetch-all-roles',
  enableScheduledPost: baseURL + '/integration-services/enable-scheduled-event',
  disableScheduledPost: baseURL + '/integration-services/disable-scheduled-event',
  disableUser: baseURL + '/profile-master-services/private/disable-user',
  enableUser: baseURL + '/profile-master-services/private/enable-user',
  disableBrand: baseURL + '/profile-master-services/private/disable-brand',
  enableBrand: baseURL + '/profile-master-services/private/enable-brand',
  getAllUsers: baseURL + '/profile-master-services/private/fetch-brand-users',
  deleteUser: baseURL + '/profile-master-services/private/delete-user',
  removeUserFromOrg: baseURL + '/profile-master-services/private/remove-user-from-org',
  removeFromBrand: baseURL + '/profile-master-services/private/remove-user-from-brand',
  createNewRole: baseURL + '/profile-master-services/private/create-new-role',
  updateRole: baseURL + '/profile-master-services/private/update-role',
  createNewUser: baseURL + '/profile-master-services/private/send-invite-create-account',
  updateUser: baseURL + '/profile-master-services/private/update-user-role',
  deleteRole: baseURL + '/profile-master-services/private/delete-role',
  getOrgUsers: baseURL + '/profile-master-services/private/fetch-org-users',
  updateOrg: baseURL + '/profile-master-services/private/update-org-details',
  deleteOrg: baseURL + '/profile-master-services/private/delete-org',
  createOrg: baseURL + '/profile-master-services/private/create-org',
  createBrand: baseURL + '/profile-master-services/private/create-brand',
  newRoleInit: baseURL + '/profile-master-services/private/new-role-init',
  newUserInit: baseURL + '/profile-master-services/private/new-user-init',
  createUserFromToken: baseURL + '/profile-master-services/public/create-user-invite-account',
  getAllPendingInvites: baseURL + '/profile-master-services/private/get-all-pending-invites',
  deleteInvite: baseURL + '/profile-master-services/private/delete-invite',
  resendInvite: baseURL + '/profile-master-services/private/resend-invite',
  fetchBrandInfo: baseURL + '/profile-master-services/private/fetch-brand-info',
  fetchOrgInfo: baseURL + '/profile-master-services/private/fetch-org-info',
  deleteMe: baseURL + '/profile-master-services/private/delete-me',
  getDomainDetails: baseURL + '/profile-master-services/public/get-domain-details',
  getBrandingDetails: baseURL + '/profile-master-services/private/get-branding-details',
  saveDomainDetails: baseURL + '/profile-master-services/private/save-domain-details',
  updateBrandingDetails: baseURL + '/profile-master-services/private/update-branding',
  getAllMedia: baseURL + '/image-server/private/fetch-user-media',
  deleteMedia: baseURL + '/profile-master-services/private/delete-media',
  getNotificationSetting: baseURL + '/notification-services/get-notification-settings',
  saveNotificationSetting: baseURL + '/notification-services/save-notification-settings',
  getPendingApprovals: baseURL + '/integration-services/get-unapproved-post',
  getRejectedApprovals: baseURL + '/integration-services/get-rejected-post',
  getEllapsedApprovals: baseURL + '/integration-services/get-elapsed-post',
  approvePost: baseURL + '/integration-services/approve-post',
  unApprovePost: baseURL + '/integration-services/unapproved-post',
  retryPost: baseURL + '/integration-services/retry-post',
  retryPostRequest: baseURL + '/integration-services/retry-post-request',
  rejectPost: baseURL + '/integration-services/reject-post',
  getDashboardData: baseURL + '/integration-services/get-dashboard-details',
  addCName: baseURL + '/profile-master-services/private/add-cname',
  verifyCName: baseURL + '/profile-master-services/private/verify-cname',
  deleteCName: baseURL + '/profile-master-services/private/delete-cname',
  initApplication: baseURL + '/profile-master-services/public/init-application',
  changeTimeZone: baseURL + '/profile-master-services/public/change-timezone',
  getCurrentSubscription: baseURL + "/payment-services/get-current-plan-details",
  getAddonUpgradePrice: baseURL + "/payment-services/add-on-upgrade?currency=",
  addStartTrail: baseURL + "/payment-services/add-start-trail",
  getAddonDowngradePrice: baseURL + "/payment-services/add-on-downgrade",
  changePlanPrice: baseURL + "/payment-services/change-plan",
  updateAppSumoAccount: baseURL + "/profile-master-services/public/upgrade-appsumo-account",
  getUnapprovedPostPublic: baseURL + "/integration-services-public/get-unapproved-post",
  getRejectedPostPublic: baseURL + "/integration-services-public/get-rejected-post",
  getElapsedPostPublic: baseURL + "/integration-services-public/get-elapsed-post",
  getCalendarEventsPublic: baseURL + "/integration-services-public/calendar-events",
  getApprovedCalendarEventsPublic: baseURL + "/integration-services-public/approved-calendar-events?from=",
  getFailedCalendarEventsPublic: baseURL + "/integration-services-public/failed-calendar-events?from=",
  approvePostPublic: baseURL + "/integration-services-public/approve-post",
  unApprovePostPublic: baseURL + "/integration-services-public/unapproved-post",
  rejectPostPublic: baseURL + "/integration-services-public/reject-post",
  shareApprovalPosts: baseURL + "/integration-services/share-approval-posts",
  removeApprovalPosts: baseURL + "/integration-services/remove-share-approval-posts",
  getShareApprovalPosts: baseURL + "/integration-services/get-share-approval-posts",
};

export const postsModuleURL = {
  facebookPosts: baseURL + '/facebook-services/get-published-posts?id=',
  facebookNextPosts: baseURL + '/facebook-services/get-next-published-posts?id=',
  instagramPosts: baseURL + '/instagram-services/get-published-posts?id=',
  instagramNextPosts: baseURL + '/instagram-services/get-next-published-posts?id=',
  uploadPosts: baseURL + '/integration-services/publish-new-post',
  twitterPosts: baseURL + '/twitter-services/get-published-posts?id=',
  youtubePosts: baseURL + '/youtube-services/get-published-posts?id=',
  pinterestPosts: baseURL + '/pinterest-services/get-published-posts?id=',
  linkedInPosts: baseURL + '/linkedin-services/page/get-published-posts?id=',
  tiktokPosts: baseURL + '/tiktok-services/get-published-posts?id=',
  googlemybusinessPosts: baseURL + '/gbusiness-services/get-published-posts?id=',
  saveDraft: baseURL + '/integration-services/save-draft',
  savePostAsDraft: baseURL + '/integration-services/save-post-as-draft',
  loadDraft: baseURL + '/integration-services/get-saved-draft',
  getCalendarEvents: baseURL + '/integration-services/calendar-events?from=',
  getApprovedCalendarEvents: baseURL + '/integration-services/approved-calendar-events?from=',
  scheduledEventInfo: baseURL + '/integration-services/scheduled-event-info?schedule_post_id=',
  getPublicScheduledEventInfo: baseURL + '/integration-services-public/scheduled-event-info?schedule_post_id=',
  addPublicCommentToScheduledEventInfo: baseURL + '/integration-services-public/add-comment-on-scheduled-event?url_key=',
  addCommentToScheduledEventInfo: baseURL + '/integration-services/add-comment-on-scheduled-event',
  addPublicReplyCommentToScheduledEventInfo: baseURL + '/integration-services-public/reply-to-comment-on-scheduled-event?url_key=',
  addReplyCommentToScheduledEventInfo: baseURL + '/integration-services/reply-to-comment-on-scheduled-event',
  deleteDraft: baseURL + '/integration-services/delete-draft?id=',
  deleteSchedule: baseURL + '/integration-services/delete-scheduled-event?schedule_post_id=',
  reScheduleNewPost: baseURL + '/integration-services/reschedule-new-post',
  updateScheduledPost: baseURL + '/integration-services/update-new-post',
  getFacebookPostDetails: baseURL + '/facebook-services/get-post-details?connected_profile_id=',
  getIntagramPostDetails: baseURL + '/instagram-services/get-post-details?connected_profile_id=',
  getTiktokPostDetails: baseURL + '/tiktok-services/get-post-details?connected_profile_id=',
  getYoutubePostDetails: baseURL + '/youtube-services/get-post-details?connected_profile_id=',
  getPinterestPostDetails: baseURL + '/pinterest-services/get-post-details?connected_profile_id=',
  getYoutubeNextReply: baseURL + '/youtube-services/get-more-replies?connected_profile_id=',
  getLinkedinPostDetails: baseURL + '/linkedin-services/page/get-post-details?connected_profile_id=',
  getNextLinkedinPostDetails: baseURL + '/linkedin-services/page/get-more-replies?connected_profile_id=',
  getFacebookNextComments: baseURL + '/facebook-services/get-more-comments?connected_profile_id=',
  getFacebookNextReply: baseURL + '/facebook-services/get-more-replies?connected_profile_id=',
  getInstagramNextComments: baseURL + '/instagram-services/get-more-comments?connected_profile_id=',
  getInstagramNextReply: baseURL + '/instagram-services/get-more-replies?connected_profile_id=',
  likeFacebookPost: baseURL + '/facebook-services/add-like',
  unlikeFacebookPost: baseURL + '/facebook-services/remove-like',
  addFacebookComment: baseURL + '/facebook-services/add-comment',
  addYoutubeComment: baseURL + '/youtube-services/add-comment',
  addInstagramComment: baseURL + '/instagram-services/add-comment',
  addInstagramRepliesComment: baseURL + '/instagram-services/add-reply-comment',
  addYoutubeRepliesComment: baseURL + '/youtube-services/add-reply-comment',
  addLinkedinComments: baseURL + '/linkedin-services/page/add-comment',
  addLinkedinRepliesComments: baseURL + '/linkedin-services/page/add-reply-comment',
  deleteFacebookCommentAndMessage: baseURL + '/facebook-services/delete',
  deleteInstagramCommentAndMessage: baseURL + '/instagram-services/delete',
  deleteYoutubeCommentAndMessage: baseURL + '/youtube-services/delete',
  deleteLinkedinCommentAndMessage: baseURL + '/linkedin-services/page/delete',
  addRemoveLikeYoutube: baseURL + '/youtube-services/add-remove-like',
  fetchAllReviews: baseURL + '/gbusiness-services/get-all-review?id=',
  addReviews: baseURL + '/gbusiness-services/add-review',
  addAnswer: baseURL + '/gbusiness-services/add-answer',
  deleteReviews: baseURL + '/gbusiness-services/delete-review',
  deleteAnswers: baseURL + '/gbusiness-services/delete-answer',
  fetchAllQuestions: baseURL + '/gbusiness-services/get-all-questions?id=',
  addLinkedinCommentLike: baseURL + '/linkedin-services/page/add-comment-like',
  addLinkedinPostLike: baseURL + '/linkedin-services/page/add-post-like',
  deleteLinkedinCommentLike: baseURL + '/linkedin-services/page/delete-comment-like',
  deleteLinkedinPostLike: baseURL + '/linkedin-services/page/delete-post-like',
};
export const reportModuleURL = {
  facebookAudienceSummary: baseURL + '/facebook-services/get-predefined-report',
  instagramAudienceSummary: baseURL + '/instagram-services/get-predefined-report',
};
export const chatModuleURL = {
  //FacebookConversation
  getAllFacebookMessageConversations: baseURL + '/facebook-services/get-all-messages?page_id=',
  getFacebookMessageConversation: baseURL + '/facebook-services/get-conversation-message?page_id=',
  sendMessage: baseURL + '/facebook-services/add-message',
  getAllInstagramMessageConversations: baseURL + '/instagram-services/get-all-messages?page_id=',
  getInstagramMessageConversation: baseURL + '/instagram-services/get-conversation-message?page_id=',
  sendInstagramMessage: baseURL + '/instagram-services/add-message'
}

export const integrateModuleURL = {
  //Facebook integration
  facebookIntegration: baseURL + '/facebook-services/integrate',
  getFacebookPagesAndGroups: baseURL + '/facebook-services/get-groups-and-pages',
  integrateFacebookPagesAndGroups: baseURL + '/facebook-services/integrate-pages-and-groups',
  getConnectedNetworks: baseURL + '/integration-services/connected-profiles',
  getReportsDetails: baseURL + '/integration-services/get-all-reports?service_name=',
  updateFacebookAccounts: baseURL + '/facebook-services/update-account',
  reAuthenticateFacebookPagesAndGroups: baseURL + '/facebook-services/re-authenticate-account',

  //Twitter Integration
  twitterIntegration: baseURL + '/twitter-services/integrate',
  twitterDeveloperIntegration: baseURL + '/twitter-services/developer-new-integrate',
  sendTwitterAccessToken: baseURL + '/twitter-services/save-access-token',
  sendDeveloperTwitterAccessToken: baseURL + '/twitter-services/developer-save-access-token',
  sendDevelopeReAuthenticateTwitter: baseURL + '/twitter-services/developer-re-authenticate-account',
  updateTwitterAccounts: baseURL + '/twitter-services/update-account',
  reAuthenticateTwitter: baseURL + '/twitter-services/re-authenticate-account',

  //Youtube Integration
  sendYoutubeAccessToken: baseURL + '/youtube-services/save-access-token',
  reAuthenticateYoutube: baseURL + '/youtube-services/re-authenticate-account',
  updateYoutubeAccounts: baseURL + '/youtube-services/update-account',

  //Pinterest Integration
  sendPinterestAccessToken: baseURL + '/pinterest-services/save-access-token',
  reAuthenticatePinterest: baseURL + '/pinterest-services/re-authenticate-account',
  updatePinterestAccounts: baseURL + '/pinterest-services/update-account',
  FetchAllBoards: baseURL + '/pinterest-services/fetch-all-boards?id=',
  CreateBoard: baseURL + '/pinterest-services/create-boards',

  //Instagram Integration
  sendInstagramAuthorizationCode: baseURL + '/instagram-services/save-access-token',
  getInstagramAccountsFromToken: baseURL + '/instagram-services/get-instagram-account-from-token',
  integrateInstagram: baseURL + '/instagram-services/integrate-instagram',
  updateInstagramAccounts: baseURL + '/instagram-services/update-account',
  reAuthenticateInstagramAccounts: baseURL + '/instagram-services/re-authenticate-account',

  //LinkedIn Integration
  sendLinkedInAccessToken: baseURL + '/linkedin-services/profile/save-access-token',
  sendLinkedInPageAccessToken: baseURL + '/linkedin-services/page/save-access-token',
  getLinkedInPages: baseURL + '/linkedin-services/page/get-linked-pages',
  reAuthenticateLinkedInPages: baseURL + '/linkedin-services/page/re-authenticate-account',
  reAuthenticateLinkedInProfile: baseURL + '/linkedin-services/profile/re-authenticate-account',
  updateLinkedinAccounts: baseURL + '/linkedin-services/update-account',

  //Tiktok Integration
  sendTiktokAccessToken: baseURL + '/tiktok-services/save-access-token',
  reAuthenticateTiktok: baseURL + '/tiktok-services/re-authenticate-account',
  updateTiktokAccounts: baseURL + '/tiktok-services/update-account',

  //GoogleMyBusiness Integration
  sendGoogleMyBusinessAccessToken: baseURL + '/gbusiness-services/save-access-token',
  fetchGoogleMyBusinessNextPages: baseURL + '/gbusiness-services/fetch-next-pages',
  integrateGoogleMyBusinessAccessToken: baseURL + '/gbusiness-services/integrate-pages',
  updateGbusinessAccounts: baseURL + '/gbusiness-services/update-account',
  reAuthenticateGoogleMyBusiness: baseURL + '/gbusiness-services/re-authenticate-account',
  getPhoneNumber: baseURL + '/gbusiness-services/get-phone-number?id=',

  //Threads Integration
  sendThreadsAccessToken: baseURL + '/threads-services/save-access-token',

  //Accounts
  disintegrateAccounts: baseURL + '/integration-services/disintegrate-accounts',
  enableSocialAccounts: baseURL + '/integration-services/enable-accounts',
  disableSocialAccounts: baseURL + '/integration-services/disable-accounts',

  //Post
  getScheduledPost: baseURL + '/integration-services/get-scheduled-post',
  getFailedPost: baseURL + '/integration-services/failed-calendar-events?from=',

  //For Time being
  handleContactUs: baseURL + '/integration-services/contact-us'
};

export const paymentModuleUrl = {
  createPayment: baseURL + '/payment-services/change-plan-payment',
  checkPayment: baseURL + '/payment-services/check-payment?payment_intent=',
  addonPayment: baseURL + '/payment-services/add-on-upgrade-payment',
  getForNextBill: baseURL + '/payment-services/pay-for-next-bill?currency=',
  payForNextBill: baseURL + '/payment-services/pay-for-next-bill-payment',
  downGradePayment: baseURL + '/payment-services/add-on-downgrade-payment',
};

export const imageModeuleUrl = {
  uploadMedia: baseURL + '/image-server/upload-media',
  uploadPrivateMedia: baseURL + '/image-server/private/upload-media',
  fetchMediaMetadata: baseURL + '/image-server/fetch-media-metadata',
  fetchPrivateMediaMetadata: baseURL + '/image-server/fetch-private-media-metadata',
  deleteMedia: baseURL + '/image-server/private/delete-media'
}
import { TablePagination } from "@mui/material";

const ScheduledPostGridPagination = ({
    scheduledPostList,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage
}) => {
    return (
        <>
            <TablePagination
                component="div"
                rowsPerPageOptions={[10, 20, 30, 50]}
                count={scheduledPostList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

export default ScheduledPostGridPagination;
import { HelmetProvider } from 'react-helmet-async';
import InitApplication from './init-application';
import MaintenanceComponent from './pages/maintainane/maintainance.component';
import { Config } from './config';
import { CommonHelper } from './utils/commonHelper';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';

const App = () => {
  const startTime = Config.Maintainance.StartTime;
  const endTime = Config.Maintainance.EndTime;
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    if (startTime && endTime) {
      const startUTC = moment.tz(startTime, "DD/MM/YYYY hh:mm A", "Asia/Kolkata").utc();
      const endUTC = moment.tz(endTime, "DD/MM/YYYY hh:mm A", "Asia/Kolkata").utc();

      const nowUTC = moment.utc();

      setIsUnderMaintenance(nowUTC.isBetween(startUTC, endUTC, null, '[]'));
    } else {
      setIsUnderMaintenance(false);
    }
  }, [startTime, endTime]);

  return (
    <HelmetProvider>
      {isUnderMaintenance ?
        <MaintenanceComponent toDate={endTime}/>
        : <InitApplication />}
    </HelmetProvider>
  );
};
export default App;

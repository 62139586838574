import { useEffect } from "react";
import { Config } from "src/config";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import TiktokAuthorizationModal from "../tiktokIntegration/tiktokAuthorization.Modal";
import { useState } from "react";
import { useToaster } from "src/utils/toaster/toasterContext";

const TiktokReAuthenticate = ({ AccountId, handleClose,getConnectedNetworks }) => {
  const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
  const { showToast } = useToaster();
  let intervalId = null;

  useEffect(() => {
    intervalId = setInterval(() => {
      checkAccessTokenReceivedFromTiktok();
    }, Config.Common.socialAccountsIntegrationInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [])

  const getTiktokOauthToken = () => {
    let url = `${Config.Tiktok.authorizeUrl}?client_key=${Config.Tiktok.clientId}&scope=${Config.Tiktok.scope}&response_type=${Config.Tiktok.responseType}&redirect_uri=${Config.Tiktok.redirectUri}&state=123456`;
    window.open(url, "_blank");
  }

  const checkAccessTokenReceivedFromTiktok = () => {
    var token = localStorage.getItem("tiktok_auth_code");
    var error = localStorage.getItem("tiktok_signin_error");
    if (token) {
      localStorage.removeItem("tiktok_auth_code");
      clearInterval(intervalId);
      sendAccessTokenToServer(token);
    }
    else if (error) {
      localStorage.removeItem("tiktok_signin_error");
      clearInterval(intervalId);
      showToast(error, 'e');
    }
  };
  const tiktokSuccessCallback = (data, message) => {
    showToast(message, 's');
    getConnectedNetworks();
    handleClose();
  }
  const tiktokFailureCallback = (message) => {
    showToast(message, 'e');
    handleClose();
  }
  const sendAccessTokenToServer = (code) => {
    let TiktokForm = {
      code,
      account_id:AccountId
    };
    let postProps = {
      url: integrateModuleURL.reAuthenticateTiktok,
      body: TiktokForm,
      successCallback: tiktokSuccessCallback,
      failureCallback: tiktokFailureCallback
    }
    HttpServices.Post(postProps);
  };

  return (
    isAuthorizationModalOpen &&
    <TiktokAuthorizationModal
      handleConnectTiktok={getTiktokOauthToken}
      handleClose={handleClose}
    />
  );
};
export default TiktokReAuthenticate;
import { Box, IconButton } from "@mui/material";
import { StyledComponent } from "src/components/styled-components/styledComponent";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import FailedPostNestedGridActionPopup from "./FailedPostNestedGridActionPopup.componenet";
import { userModuleURL } from "src/services/urlService";
import HttpServices from "src/services/httpService";
import { useToaster } from "src/utils/toaster/toasterContext";
import { CommonHelper } from "src/utils/commonHelper";

const FailedPostNestedGridAction = ({failedPostId, scheduledEventDetails }) => {
    const { showToast } = useToaster();
    const [selectedPopOverPost, setPopOverPost] = useState(null);
    const [popoverElement, setPopoverElement] = useState(null);


    const handleOpenMenu = (event, failedPost) => {
        setPopOverPost(failedPost);
        setPopoverElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setPopOverPost(null);
        setPopoverElement(null);
    };

    const retryNow = () => {
        let props = {
            service_id: CommonHelper.GetSeviceId(scheduledEventDetails?.connected_profile_details[0].social_media),
            schedule_id: failedPostId,
            request_id: scheduledEventDetails.id,
        };
        let postProps = {
            url: userModuleURL.retryPostRequest,
            body: props,
            successCallback: retrySuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const retrySuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
    };

    const failureCallback = (message) => {
        message && showToast(message, 'e');
        handleCloseMenu();
    };

    const postActionProps = {
        popoverElement: popoverElement,
        handleCloseMenu,
        retryNow,
        selectedPopOverPost: selectedPopOverPost,
    };

    return (
        <>
            <StyledComponent.StyledTableCell sx={{ width: '10%' }} align="right">
                <Box display="flex" justifyContent="flex-end">
                    <IconButton
                        size="large"
                        color="inherit"
                        onClick={(e) => {
                            handleOpenMenu(e, scheduledEventDetails);
                        }}
                        sx={{ padding: '6px', marginRight: '2px' }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Box>
            </StyledComponent.StyledTableCell>
            <FailedPostNestedGridActionPopup {...postActionProps}></FailedPostNestedGridActionPopup>
        </>
    )
}

export default FailedPostNestedGridAction;
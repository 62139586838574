import { MenuItem, Popover,Box ,CircularProgress} from '@mui/material';
import { Cancel, Refresh, Replay, ScheduleSend } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import UploadIcon from '@mui/icons-material/Upload';

const FailedPostActionPopup = ({
  popoverElement,
  handleCloseMenu,
  handleSaveAsDraft,
  handleEditAsNewPost,
  handleDeletePost,
  hasEditAccess,
  failedPost,
  loading,
  hasApproveAccess,
  retryNow,
}) => {
  return (
    <Popover
      open={Boolean(popoverElement)}
      anchorEl={popoverElement}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: { p: 1, width: 170, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
      }}
    >
      {' '}
      {loading ? (
                   <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                   <CircularProgress size={24} />
               </Box>
                ) : 
                <>
      <MenuItem sx={{ color: 'info.main' }} onClick={handleSaveAsDraft}>
        <UploadIcon sx={{ mr: 1 }} /> Save As Draft{' '}
      </MenuItem>
      <MenuItem
        sx={{ color: 'warning.main' }}
        onClick={() => {
          handleEditAsNewPost();
        }}
      >
        <ModeEditOutlineIcon sx={{ mr: 1 }} /> Edit as New Post{' '}
      </MenuItem>
      {failedPost.has_delete_access && <MenuItem sx={{ color: 'error.main' }} onClick={handleDeletePost}>
        <DeleteForeverIcon sx={{ mr: 1 }} /> Delete Post{' '}
      </MenuItem>}
      {failedPost.has_edit_access && <MenuItem sx={{ color: 'error.main' }} onClick={retryNow}>
        <Replay sx={{ mr: 1 }} /> Retry Now
      </MenuItem>}
      </>}
    </Popover>
  );
};
export default FailedPostActionPopup;

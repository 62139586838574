import {
    Avatar,
    Box,
    Card,
    Stack,
    Typography,
    Paper,
    IconButton,
    TextField,
    Grid
} from '@mui/material';
import {MessageOutlined as MessageOutlinedIcon, Send as SendIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import EditIcon from '@mui/icons-material/Edit';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import DeleteCommentIcon from '../../assets/deletecomment.svg';
import { LoadingButton } from '@mui/lab';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
import NoMessage from '../../assets/app-image/no-message.svg';


const QuestionsGrid = ({ gBusinessId }) => {
    const theme = useTheme();
    const { showToast } = useToaster();
    const fileInputRef = useRef(null);
    const [height, setHeight] = useState(window.innerHeight - 260);
    const [isLoading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [childQuestion, setChildQuestion] = useState('');
    const [childQuestionId, setChildQuestionId] = useState(null);
    const [isSendLoading, setSendLoading] = useState(false);
    const [pagination, setPagination] = useState('');
    const [loaderQuestions,setLoderQuestions] = useState(false)


    useEffect(() => {
        gBusinessId && getGBusinessQuestion();
    }, [gBusinessId]);

    const getGBusinessQuestionsSuccessCallback = (data, message) => {
        setQuestions(data.questions);
        setPagination(data.pagination);
        setLoading(false);
    }
    const getNextGBusinessQuestionSuccessCallback = (data, message) => {
        setLoderQuestions(false)
        let updatedReviewss = [...questions, ...data.questions];
        setQuestions(updatedReviewss)
        setPagination(data.pagination)
    }
    const addQuestionsSuccessCallback = (data, message) => {
        const updatedQuestions = questions.map(question => {
            if (question.name === childQuestionId) {
                return {
                    ...question,
                    topAnswers: [{
                        text: childQuestion,
                        updateTime: new Date()
                    }]
                };
            }
            return question;
        });
        setQuestions(updatedQuestions);
        setSendLoading(false);
        setChildQuestion('');
        setChildQuestionId(null);
    }
    const deleteQuestionsSuccessCallback = (data, message) => {
        setChildQuestion('');
        setChildQuestionId(null);
    }
    const addQuestionsFailureCallback = (message) => {
        message && showToast(message, "e");
        setLoading(false);
    }

    const getGBusinessQuestionsFailureCallback = (message) => {
        setQuestions([]);
        message && showToast(message, "e");
        setLoading(false);
        setSendLoading(false);
        setLoderQuestions(false)
    }

    const getGBusinessQuestion = () => {
        setLoading(true);
        let getProps = {
            url: postsModuleURL.fetchAllQuestions + gBusinessId,
            successCallback: getGBusinessQuestionsSuccessCallback,
            failureCallback: getGBusinessQuestionsFailureCallback
        }
        HttpServices.Get(getProps);
    }
    const getNextGBusinessQuestion = () => {
        setLoderQuestions(true)
        let getProps = {
            url: `${postsModuleURL.fetchAllQuestions}${gBusinessId}&pagination=${pagination}`,
            successCallback: getNextGBusinessQuestionSuccessCallback,
            failureCallback: getGBusinessQuestionsFailureCallback
        }
        HttpServices.Get(getProps);
    }

    const handleClickComment = (question) => {
        if(question.topAnswers && question.topAnswers.length > 0)
            return
        setChildQuestion("")
        setChildQuestionId(question.name);
    }

    const handleClickEditComment = (answer, id) => {
        setChildQuestion(answer.text)
        setChildQuestionId(id);
    }

    const handlePostComment = (comment, isParent) => {
        setSendLoading(true);
        setChildQuestion(comment);
        let postCommentBody = {
            connected_profile_id: gBusinessId,
            question_id: childQuestionId,
            message: comment
        };
        let postProps = {
            url: postsModuleURL.addAnswer,
            body: postCommentBody,
            successCallback: addQuestionsSuccessCallback,
            failureCallback: addQuestionsFailureCallback

        }
        HttpServices.Post(postProps);
    }

    const handleDeleteQuestions = (id) => {
        let postCommentBody = {
            connected_profile_id: gBusinessId,
            question_id: id,
        };
        let postProps = {
            url: postsModuleURL.deleteAnswers,
            body: postCommentBody,
            successCallback: deleteQuestionsSuccessCallback,
            failureCallback: addQuestionsFailureCallback
        }
        const updatedQuestions = questions.map(question => {
            if (question.name === id) {
                return {
                    ...question,
                    topAnswers: []
                };
            }
            return question;
        });
        setQuestions(updatedQuestions);
        HttpServices.Post(postProps);
    }

    const handleChangeComment = (e) => {
        setChildQuestion(e.target.value);
    }


    const commentView = (question) => {
        
        return (
            <Stack key={question.name} direction="row" spacing={2} style={{ marginLeft: 15, marginRight: 15 }}>
                <Avatar alt={question.author.displayName || "Author"} src={`https://`+ question.author.profilePhotoUri} />
                <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', margin: '10px', boxShadow: '3' }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems={{ sm: 'center' }}
                        justifyContent="space-between"
                        sx={{ mb: 0.5 }}
                    >
                        <Typography variant="subtitle2">{question.author.displayName|| "Author"}</Typography>
                        <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                            {CommonHelper.DateTimeFromCurrentTime(question.createTime)}
                        </Typography>
                    </Stack>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {question.text}
                    </Typography>
                    <Stack
                        flexWrap="wrap"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                        sx={{
                            mt: 3,
                            color: 'text.disabled',
                        }}
                    >
                        <IconButton onClick={() => handleClickComment(question)}>
                            <MessageOutlinedIcon sx={{ width: 20, height: 20, mr: 0.5, color: theme.palette.preview.comments.facebook }} />
                        </IconButton>
                    </Stack>
                </Paper>
            </Stack>
        );
    }


    const commentReplyView = (answers) => {
        return (
            <>
                {answers.topAnswers && answers.topAnswers.length > 0 && answers.topAnswers.map((answer) => (
                    <Stack key={answers.name} direction="row" spacing={2} style={{ marginLeft: 15, marginRight: 15 }}>
                        <Avatar />
                        <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', margin: '10px', boxShadow: '3' }}>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                alignItems={{ sm: 'center' }}
                                justifyContent="space-between"
                                sx={{ mb: 0.5 }}
                            >
                                <Typography variant="subtitle2">Author</Typography>
                                <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                                    {CommonHelper.DateTimeFromCurrentTime(answer.updateTime)}
                                </Typography>
                            </Stack>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {answer.text}
                            </Typography>
                            <Stack
                                flexWrap="wrap"
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                sx={{
                                    mt: 3,
                                    color: 'text.disabled',
                                }}
                            >
                                <IconButton onClick={() => handleClickEditComment(answer, answers.name)}>
                                    <EditIcon sx={{ width: 20, height: 20, mr: 0.5, color: theme.palette.primary.main}} />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteQuestions(answers.name)}>
                                 <img src={DeleteCommentIcon} style={{ width: 20, height: 20 }} />
                                </IconButton>
                            </Stack>
                        </Paper>
                    </Stack>))}
            </>
        );
    }

    const writeContentView = (id, comment, isParent = false) => {
        return (
            <Card sx={{ boxShadow: 'none', marginLeft: isParent ? '0px' : '80px !important' }}>
                <Stack direction="row" alignItems="center">
                    <TextField
                        fullWidth
                        size="small"
                        value={comment}
                        placeholder="Write a comment…"
                        onChange={(e) => { handleChangeComment(e, isParent) }}

                        sx={{
                            ml: 2,
                            mr: 1,
                            '& fieldset': {
                                borderWidth: `1px !important`,
                                borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
                            },
                        }}
                    />
                    <LoadingButton
                        variant="outlined"
                        loading={isSendLoading}
                        endIcon={<SendIcon />}
                        size=""
                        onClick={() => { handlePostComment(comment, isParent) }}
                        disabled={!comment}
                        sx={{
                            border: 'none', '&.Mui-disabled': {
                                border: 'none',
                                opacity: 0.6
                            },
                            '&.MuiLoadingButton-root:hover': {
                                border: 'none',
                                background: 'none'
                            }
                        }}
                    >
                    </LoadingButton>
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
                </Stack>
            </Card>
        );
    }

    const getGridView = () => {
        return (
              <Grid  sx={{maxHeight: 'calc(100vh - 360px)', overflow: 'auto'}}>
                    <Stack >
                        {questions && questions.length > 0 && questions.map((question) => (
                            <>
                                <Card sx={{ m: '10px !important', boxShadow: 'none' }}>
                                    {commentView(question)}
                                </Card>
                                {question.topAnswers && <Card sx={{ marginLeft: '80px !important', boxShadow: 'none' }}>
                                    {commentReplyView(question)}
                                </Card>}
                                {childQuestionId && question.name === childQuestionId && writeContentView(childQuestionId, childQuestion)}
                            </>
                        ))}
                        {pagination!== '' && (
                        <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
                          {loaderQuestions ? (
                            <ThreeDotLoader />
                          ) : (
                            <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 700,
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              color: theme.palette.grey[500],
                            }}
                            onClick={getNextGBusinessQuestion}
                          >
                            Load More Questions
                          </Typography>
                          )}
                        </Box>
                      )}
                    </Stack>
                    </Grid>
               
        );
    }

    return (
        <Box >
            {isLoading ?
                <Spinner height={height}/>
                :
                questions?.length > 0 ? (
                    getGridView()
                  ) : (
                    <Box
                      sx={{
                        mb: 2,
                        p: 2,
                        maxHeight: 'calc(100vh - 360px)',
                        minHeight: 'calc(100vh - 360px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={NoMessage}
                        style={{ width: '25%', height: '25%', maxWidth: '25%', maxHeight: '25%' }}
                        alt="No Message Available"
                      />
                      <Typography variant="h4" sx={{ fontWeight: 800 }}>
                        No Questions Available
                      </Typography>
                    </Box>
                  )}

        </Box>
    )
}
export default QuestionsGrid;

import { useEffect } from "react";
import { Config } from "src/config";
import HttpServices from "src/services/httpService";
import { integrateModuleURL } from "src/services/urlService";
import { useState } from "react";
import { useToaster } from "src/utils/toaster/toasterContext";
import ThreadsAuthorizationModal from "./threads-authorization-modal";

const ThreadsIntegration = (props) => {
  const [isAuthorizationModalOpen, setAuthorizationModal] = useState(true);
  const { showToast } = useToaster();
  let intervalId = null;

  useEffect(() => {
    intervalId = setInterval(() => {
      checkAccessTokenReceivedFromThreads();
    }, Config.Common.socialAccountsIntegrationInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [])

  const getThreadsOauthToken = () => {
    let redirectUri = encodeURIComponent(Config.Threads.redirectUri);
    window.open(
      `${Config.Threads.authorizeUrl}?client_id=${Config.Threads.clientId}&redirect_uri=${redirectUri}&scope=${Config.Threads.scope}&response_type=code`, "_blank"
    );
  }

  const checkAccessTokenReceivedFromThreads = () => {
    var token = localStorage.getItem("threads_token");
    var error = localStorage.getItem("threads_error");
    if (token) {
      localStorage.removeItem("threads_token");
      clearInterval(intervalId);
      sendAccessTokenToServer(token);
    }
    else if (error) {
      localStorage.removeItem("threads_error");
      clearInterval(intervalId);
      showToast(error, 'e');
    }
  };
  const threadsSuccessCallback = (data, message) => {
    showToast(message, 's');
    handleClose();
  }
  const threadsFailureCallback = (message) => {
    showToast(message, 'e');
    handleClose();
  }
  const sendAccessTokenToServer = (code) => {
    let threadsForm = {
      code
    };
    let postProps = {
      url: integrateModuleURL.sendThreadsAccessToken,
      body: threadsForm,
      successCallback: threadsSuccessCallback,
      failureCallback: threadsFailureCallback
    }
    HttpServices.Post(postProps);
  };

  const handleClose = () => {
    props.handleClose();
  }

  return (
    isAuthorizationModalOpen &&
    <ThreadsAuthorizationModal
      handleConnectThreads={getThreadsOauthToken}
      handleClose={handleClose}
    />
  );
};
export default ThreadsIntegration;
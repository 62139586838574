import { MenuItem, Popover, Box, Typography, TextField, Button, Select, InputAdornment, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { CommonHelper } from 'src/utils/commonHelper';

const SharePublicApprovalPopup = ({
    popoverElement,
    handleCloseMenu,
    url,
    urlKey,
    shareUntil,
    getShareApprovalPosts
}) => {
    const [selectShareTime, setSelectShareTime] = useState('8 HOUR');
    const { showToast } = useToaster();
    const theme = useTheme();

    const handleActionChange = (shareTime) => {
        setSelectShareTime(shareTime);
    };

    const shareApprovalPosts = () => {
        const formBody = {
            share_until: selectShareTime
        };
        const postProps = {
            url: userModuleURL.shareApprovalPosts,
            body: formBody,
            successCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const failureCallback = (message) => {
        message && showToast(message, "e");
    };

    const successCallback = (data, message) => {
        getShareApprovalPosts();
        message && showToast(message, "s");
    };

    const removeApprovalPosts = () => {
        const postProps = {
            url: userModuleURL.removeApprovalPosts,
            successCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url);
        showToast("URL copied to clipboard!", "s");
    };

    return (
        <Popover
            open={Boolean(popoverElement)}
            anchorEl={popoverElement}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            PaperProps={{
                sx: {
                    p: 1,
                    m: 1,
                    '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="span" sx={{ fontWeight: 700 }}>Share your posts with a public link!</Typography>
                <Typography variant="body2">Anyone can view, comment, and approve — no sign-in required.</Typography>
                <Select
                    value={selectShareTime}
                    onChange={(event) => handleActionChange(event.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                    sx={{ mt: 1 }}
                >
                    <MenuItem value={'8 HOUR'}>8 HOUR</MenuItem>
                    <MenuItem value={'1 DAY'}>1 DAY</MenuItem>
                    <MenuItem value={'FOREVER'}>FOREVER</MenuItem>
                </Select>
                <Button variant='contained' onClick={shareApprovalPosts} sx={{ mt: 1, width: '150px' }}>Share Public Link</Button>
                {urlKey &&
                    <>
                        <TextField
                            size="small"
                            value={url}
                            readOnly
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button onClick={copyToClipboard} variant="contained">
                                            Copy URL
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ mt: 1 }}
                        />
                        <Typography>Expired Till : {CommonHelper.formatDateAndTime(shareUntil)}</Typography>
                        <Button variant='contained' onClick={removeApprovalPosts} sx={{
                            mt: 1, width: '150px',
                        }} color="error">Remove Link</Button>
                    </>}
            </Box>
        </Popover>
    );
};

export default SharePublicApprovalPopup;
import {
  Box,
  Card,
  FormControlLabel,
  Typography,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Checkbox,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import NoPostFound from 'src/pages/publishedPosts/noPost.component';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { StyledComponent } from '../styled-components/styledComponent';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import DesktopMacRoundedIcon from '@mui/icons-material/DesktopMacRounded';

const dummyData = () => {
  return {
    notification_mapping: [
      {
        notification_type_name: 'Post Success',
        email_notification_enabled: true,
        mobile_notification_enabled: false,
        app_notification_enabled: true,
      },
      {
        notification_type_name: 'Post Failure',
        email_notification_enabled: true,
        mobile_notification_enabled: true,
        app_notification_enabled: true,
      },
      {
        notification_type_name: 'Approve Post',
        email_notification_enabled: false,
        mobile_notification_enabled: false,
        app_notification_enabled: false,
      },
      {
        notification_type_name: 'Reject Post',
        email_notification_enabled: true,
        mobile_notification_enabled: true,
        app_notification_enabled: true,
      },
      {
        notification_type_name: 'Schedule Post',
        email_notification_enabled: true,
        mobile_notification_enabled: false,
        app_notification_enabled: true,
      },
    ],
  };
};

const NotificationSetting = () => {
  const [loader, setLoader] = useState(true);
  const [notificationSettingData, setNotificationsData] = useState([]);
  const [height, setHeight] = useState(window.innerHeight - 110);
  const { showToast } = useToaster();

  useEffect(() => {
    getAllNotificationSettings();
  }, []);

  const getAllNotificationSettings = () => {
    let getProps = {
      url: userModuleURL.getNotificationSetting,
      successCallback: getNotificationSuccessCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNotificationSuccessCallback = (data, message) => {
    setLoader(false);
    setNotificationsData(data?.notification_mapping);
  };
  const failureCallback = (message) => {
    message && showToast(message);
    setLoader(false);
  };
  const saveNotificationFailureCallback = (message) => {
    message && showToast(message);
    setLoader(false);
    getAllNotificationSettings();
  };
  const saveNotificationSuccessCallback = (data, message) => {
    message && showToast(message);
    getAllNotificationSettings();
  };
  const handleNotificationChange = (notification, type) => {
    let settingData = [...notificationSettingData];
    let data = settingData.find((a) => a.notification_type_name === notification.notification_type_name);
    if (data) {
      data[type] = !data[type];
    }
    setNotificationsData([...settingData]);
    let isEnabled = false;
    switch (type) {
      case 'email_notification_enabled':
        isEnabled = notification.email_notification_enabled;
        break;
      case 'mobile_notification_enabled':
        isEnabled = notification.mobile_notification_enabled;
        break;
      case 'app_notification_enabled':
        isEnabled = notification.app_notification_enabled;
        break;
      default:
        break;
    }
    let notificationProps = {
      notification_type_name: notification.notification_type_name,
      notification_method: type,
      is_enabled: isEnabled,
    };
    let postProps = {
      url: userModuleURL.saveNotificationSetting,
      body: notificationProps,
      successCallback: saveNotificationSuccessCallback,
      failureCallback: saveNotificationFailureCallback,
    };
    HttpServices.Post(postProps);
  };
  return (
    <>
      <Helmet>
        <title> Notifications </title>
      </Helmet>
      {loader ? (
        <Spinner />
      ) : (
        <Card style={{ bgcolor: 'background.paper', padding: 2 }}>
          {notificationSettingData?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: '1.2rem', fontWeight: 500 }}>Notification Type</TableCell>
                    <TableCell align="center">
                      <MailOutlineRoundedIcon />
                      <Typography>Email Notification</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <DesktopMacRoundedIcon />
                      <Typography>In App Notification</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notificationSettingData.map((notification) => (
                    <TableRow key={notification.notification_type_name}>
                      <TableCell component="th" scope="row" style={{ fontSize: '1rem' }}>
                        {notification.notification_display_name
                          ? notification.notification_display_name
                          : notification.notification_type_name}
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={notification.email_notification_enabled}
                          onChange={() => handleNotificationChange(notification, 'email_notification_enabled')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={notification.app_notification_enabled}
                          onChange={() => handleNotificationChange(notification, 'app_notification_enabled')}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NoPostFound height={height} />
          )}
        </Card>
      )}
    </>
  );
};
export default NotificationSetting;
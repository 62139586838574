import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Box, Button,
    Card,
    Checkbox,
    Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select,
    Table, TableBody, TableCell, TableContainer, TablePagination, TableRow,
    TextField,
    Typography,
    IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { UserListHead } from 'src/sections/@dashboard/user';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import AddIcon from '@mui/icons-material/Add';
import ValidationTool from 'src/utils/validationHelper';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Styled label component
const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
}));

const AddTeamMember = ({ handleClose, handleSuccess }) => {
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [loader, setLoader] = useState(false);
    const [roles, setRoles] = useState([]);
    const [userList, setUserList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedUsers, setSelectedUser] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [formSchema, setFormSchema] = useState({});
    const [roleError, setRoleError] = useState('');
    const [additionalFields, setAdditionalFields] = useState([]);
    const { showToast } = useToaster();
    const [formValidationErrors, setFormValidationErrors] = useState({});
    const [height, setHeight] = useState(window.innerHeight);
    const [selectAllUsers, setSelectAllUsers] = useState(false);


    useEffect(() => {
        newUserInit();
        generateFormModelSchema();
    }, []);
    const newUserInit = () => {
        setLoader(true);
        let postProps = {
            url: userModuleURL.newUserInit,
            successCallback: successCallback,
            failureCallback: failureCallback
        };
        HttpServices.Get(postProps);
    }
    const successCallback = (data, message) => {
        showToast(message, 's')
        setRoles(data?.role_list);
        setUserList(data?.user_list);
    }
    const failureCallback = (message) => {
        showToast(message, 's')
    }
    const handleOnBlur = (event) => {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;
        let validationErrors = { ...formValidationErrors };
        let message = ValidationTool.ValidateInput(formSchema, fieldValue, fieldName);
        if (message) validationErrors[fieldName] = message;
        else validationErrors[fieldName] = '';
        setFormValidationErrors(validationErrors);
    };
    const generateFormModelSchema = () => {
        let fields = [
            ValidationTool.GenerateSchema('name', 'Name', 'TEXT', true),
            ValidationTool.GenerateSchema('email', 'Email', 'EMAIL', true),
        ];
        const schema = ValidationTool.GenerateSchemaModel(fields);
        setFormSchema(schema);
    };
    const handleSubmit = () => {
        if (selectedUsers?.some(a => !a.role)) {
            showToast("Please select the role for all selected users", 'e');
            setSelectedUser(selectedUsers.map(user => ({
                ...user,
                showError: !user.role
            })));
            return;
        }
        else if (selectedUsers?.some(a => a.role)) {
            handleAddMember();
        }
        else if (additionalFields.length > 0) {
            handleAddMember();
        }
        else {
            let isValid = true;
            if (!role) {
                setRoleError('Please select a role');
                isValid = false;
            } else {
                setRoleError('');
            }
            let registerForm = {
                name: name,
                email: email,
            };
            let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, registerForm);
            if (isValidForm && isValid) handleAddMember();
            else setFormValidationErrors(formValidationErrors);
        }
    };
    const isEmailUnique = (email) => {
        if (additionalFields.some(field => field.email === email)) {
            return false;
        }
        if (selectedUsers.some(user => user.email === email)) {
            return false;
        }
        return true;
    };

    const checkInviteUserField = () => {
        if (additionalFields.length > 0) {
            let firstValue = email !== "" && name !== "" && role !== "";
            let data = additionalFields.map(user => ({
                email: user.email,
                name: user.name,
                role_id: user.role_id,
            }));
            if (firstValue) {
                data.push({ email: email, name: name, role_id: role })
            }
            return data;
        } else if (email !== "" && name !== "" && role !== "") {
            return [{
                email: email,
                name: name,
                role_id: role,
            }];
        } else {
            return [];
        }
    };
    const handleAddMember = () => {
        setLoading(true);
        let inviteUserProps = checkInviteUserField();
        if (inviteUserProps.filter((field, index, self) => 
            self.findIndex(f => f.email === field.email) !== index).length > 0) {
            showToast("Email must be unique", 'e');
            setLoading(false);
            return;
        }
        let existingUserList = selectedUsers.length > 0 ? selectedUsers.map(user => {
            return {
                user_id: user.user_id,
                role_id: user.role
            }
        }) : [];
        let roleProps = {
            invite_users: inviteUserProps,
            user_id_list: existingUserList
        }
        let postProps = {
            url: userModuleURL.createNewUser,
            body: roleProps,
            successCallback: formSuccesCallback,
            failureCallback: formFailureCallback,
        };
        HttpServices.Post(postProps);
    }
    const formSuccesCallback = (data, message) => {
        setLoading(false);
        showToast(message, 's');
        setRole('');
        setEmail('');
        setName('');
        setUserList([]);
        setSelectedUser([]);
        handleSuccess();
    }
    const formFailureCallback = (message) => {
        setLoading(false);
        showToast(message, 'e');
    }
    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "role":
                setRole(value);
                setRoleError('');
                break;
            default:
                break;
        }
    };
    const handleExistingUserSelect = (user, value) => {
        let users = [...selectedUsers];
        let foundedUser = users.find(a => a.user_id === user.user_id);
        if (foundedUser) {
            foundedUser.role = value;
            foundedUser.showError = !value;
        } else {
            users.push({ ...user, role: value, showError: !value });
        }
        setSelectedUser(users);
    };

    const handleUserSelect = (user) => {
        const users = [...selectedUsers];
        const index = users.findIndex(a => a.user_id === user.user_id);
        if (index === -1) {
            users.push(user);
            setSelectedUser(users);
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const getRoleValue = (user) => {
        let foundedUser = selectedUsers?.find(a => a.user_id === user.user_id);
        return foundedUser ? foundedUser.role || '' : ''
    }

    const handleRemoveLast = () => {
        if (additionalFields.length > 0) {
            setAdditionalFields(additionalFields.slice(0, -1));
        }
    };
    const handleAdditionalFieldChange = (event, index, fieldKey) => {
        const { value } = event.target;
        setAdditionalFields(prevFields => {
            const updatedFields = [...prevFields];
            updatedFields[index] = {
                ...updatedFields[index],
                [fieldKey]: value
            };
            return updatedFields;
        });
    };

    const handleAddMore = () => {
        let isValid = true;
        if (!role) {
            setRoleError('Please select a role');
            isValid = false;
        } else {
            setRoleError('');
        }
        let registerForm = {
            name: name,
            email: email,
        };
        let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, registerForm);
        if (isValidForm && isValid) {
            if (!isEmailUnique(email)) {
                formValidationErrors.email = "Email is already taken.";
                isValidForm = false;
            }
        }
        if (isValidForm && isValid) {
            const newUser = {
                email: email,
                name: name,
                role_id: role
            };
            setAdditionalFields(prevUserList => [...prevUserList, newUser]);
            setEmail('');
            setName('');
            setRole('');
            setRoleError('');
        } else {
            setFormValidationErrors(formValidationErrors);
        }
    };
    const handleDeleteAdditionalUser = (index) => {
        const updatedFields = [...additionalFields];
        updatedFields.splice(index, 1);
        setAdditionalFields(updatedFields);
    };

    const handleDeleteSelectedUser = (userId) => {
        const updatedUsers = selectedUsers.filter(user => user.user_id !== userId);
        setSelectedUser(updatedUsers);
    };

    const isSelected = (user_id) => selectedUsers.some(a => a.user_id === user_id);
    return (
        <Dialog open maxWidth="md">
            <DialogTitle> <Typography variant='h4' gutterBottom component="div">
                Invite Team Members
            </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        maxHeight: 'calc(100vh - 330px)',
                        minHeight: 'calc(100vh -330px)',
                    }}>
                    <Grid container spacing={2}>
                        <Grid item xs={3.5} >
                            <LabelStyle>Name</LabelStyle>
                        </Grid>
                        <Grid item xs={3.5} >
                            <LabelStyle>Email Address</LabelStyle>
                        </Grid>
                        <Grid item xs={3.5} >
                            <LabelStyle>Roles</LabelStyle>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {additionalFields.map((field, index) => (
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={3.5}>
                                    <TextField
                                        error={formValidationErrors[`additionalName_${index}`]}
                                        type="text"
                                        fullWidth
                                        name={`additionalName_${index}`}
                                        value={field.name} // Bind value to field.name
                                        placeholder='Name'
                                        onBlur={handleOnBlur}
                                        helperText={formValidationErrors[`additionalName_${index}`]}
                                        sx={{ mb: 1, mt: 1 }}
                                        onChange={(e) => handleAdditionalFieldChange(e, index, 'name')}
                                    />
                                </Grid>
                                <Grid item xs={3.5}>
                                    <TextField
                                        error={formValidationErrors[`additionalEmail_${index}`]}
                                        name={`additionalEmail_${index}`}
                                        placeholder='Email address'
                                        fullWidth
                                        value={field.email}
                                        onChange={(e) => handleAdditionalFieldChange(e, index, 'email')}
                                        helperText={formValidationErrors[`additionalEmail_${index}`]}
                                        onBlur={handleOnBlur}
                                        sx={{ mb: 1, mt: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={3.5}>
                                    <Select
                                        name={`additionalRole_${index}`}
                                        select
                                        fullWidth
                                        value={field.role_id}
                                        onChange={(e) => handleAdditionalFieldChange(e, index, 'role_id')}
                                        placeholder='Role'
                                        margin="normal"
                                        sx={{ mb: 1, mt: 1 }}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="">
                                            <Typography variant="body3" color="text.secondary">Select the role</Typography>
                                        </MenuItem>
                                        {roles.map((role) => (
                                            <MenuItem key={role.id} value={role.id}>
                                                {role.role_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={1.5} container alignItems="center">
                                    <IconButton onClick={() => handleDeleteAdditionalUser(index)} >
                                        <DeleteIcon sx={{ mr: 1, color: 'error.main' }} />
                                    </IconButton>

                                </Grid>
                            </Grid>
                        ))}
                        {selectedUsers.map((user, index) => (
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={3.5}>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        value={user?.first_name + " " + user?.last_name}
                                        placeholder='Name'
                                        sx={{ mb: 1, mt: 1 }}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={3.5}>
                                    <TextField
                                        placeholder='Email address'
                                        fullWidth
                                        value={user.email}
                                        sx={{ mb: 1, mt: 1 }}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={3.5}>
                                    <FormControl fullWidth>
                                        <Select
                                            select
                                            fullWidth
                                            value={getRoleValue(user)}
                                            onChange={(e) => handleExistingUserSelect(user, e.target.value)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            sx={{
                                                mb: 1,
                                                mt: 1,
                                            }}
                                        >
                                            <MenuItem value="">
                                                <Typography variant="body3" color="text.secondary">Select the role</Typography>
                                            </MenuItem>
                                            {roles.map((role) => (
                                                <MenuItem key={role.id} value={role.id}>
                                                    {role.role_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText sx={{ color: "red" }}>{selectedUsers[index].showError ? 'Please select a role' : null}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1.5} container alignItems="center">
                                    <IconButton onClick={() => handleDeleteSelectedUser(user.user_id)} >
                                        <DeleteIcon sx={{ mr: 1, color: 'error.main' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={3.5}>
                            <TextField
                                error={formValidationErrors?.name}
                                color={name === '' ? 'error' : 'success'}
                                type="text"
                                fullWidth
                                name="name"
                                value={name}
                                placeholder='Name'
                                onBlur={handleOnBlur}
                                helperText={formValidationErrors?.name}
                                onChange={handleChange}
                                sx={{ mb: 1, mt: 1 }}
                            />
                        </Grid>
                        <Grid item xs={3.5}>
                            <TextField
                                error={formValidationErrors?.email}
                                color={email === '' ? 'error' : 'success'}
                                name="email"
                                placeholder='Email address'
                                fullWidth
                                onChange={handleChange}
                                value={email}
                                helperText={formValidationErrors?.email}
                                onBlur={handleOnBlur}
                                sx={{ mb: 1, mt: 1 }}
                            />
                        </Grid>
                        <Grid item xs={3.5} >
                            <FormControl fullWidth>
                                <Select
                                    id="role"
                                    name="role"
                                    select
                                    fullWidth
                                    value={role}
                                    error={!!roleError}
                                    helperText={roleError}
                                    onChange={handleChange}
                                    sx={{ mb: 1, mt: 1 }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="">
                                        <Typography variant="body3" color="text.secondary">Select the role</Typography>
                                    </MenuItem>
                                    {roles.map((role) => (
                                        <MenuItem key={role.id} value={role.id}>
                                            {role.role_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText sx={{ color: "red" }}>{roleError}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddMore} sx={{ mr: 1 }}>Add More</Button>
                        </Grid>
                        {userList?.length > 0 &&
                            <Grid item xs={12}>
                                <Card>
                                <TableContainer
                                    sx={{
                                        maxHeight: 'calc(100vh - 500px)',
                                        minHeight: 'calc(100vh -500px)',
                                    }}
                                >
                                    <Table stickyHeader>
                                        <UserListHead
                                            headLabel={[
                                                { id: 'name', width: '35%', label: 'Team Member', alignRight: false },
                                                { id: 'email', width: '40%', label: 'Email', alignRight: false },
                                                { id: 'action', width: '25%', label: 'Action', alignRight: true },
                                            ]}
                                            isShowCheckbox={false}
                                        />
                                        <TableBody>
                                            {userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                                <TableRow hover key={user.id} tabIndex={-1} sx={{ opacity: isSelected(user.user_id) ? 0.5 : 1 }}>
                                                    <TableCell align="left" sx={{ width: '35%' }}>
                                                        <Typography >{user?.first_name + " " + user?.last_name}</Typography>
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ width: '40%' }}>
                                                        <Typography>{user.email}</Typography></TableCell>
                                                    <TableCell align="right" sx={{ width: '25%' }}>
                                                        <IconButton onClick={() => handleUserSelect(user)} >
                                                            <AddCircleIcon color='primary' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[5, 10, 25]}
                                    count={userList?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                    </Card>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color='error' onClick={handleClose}>
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    Invite User
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AddTeamMember;
import { Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CommonHelper } from 'src/utils/commonHelper';

export default function Logo({ sx }) {
  let appLogo = CommonHelper.GetAppLogo();
  const logo = (
    <Box sx={{ width: 35, height: 35, ...sx, display: 'flex', alignItems: 'center' }}>
      <img width={35} height={35} src={appLogo} style={{ objectFit: 'cover' }} />
    </Box>
  );

  return <RouterLink to="/">{logo}</RouterLink>;
}

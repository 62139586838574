import { Autocomplete, Avatar, Badge, Chip, TextField, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { CommonHelper } from 'src/utils/commonHelper';
import FacebookIcon from '../../assets/facebook.svg';
import GBusinessIcon from '../../assets/google-my-business-logo.svg';
import InstagramIcon from '../../assets/instagram.svg';
import LinkedInIcon from '../../assets/linked_in.svg';
import TikTokIcon from '../../assets/tiktok.svg';
import TwitterIcon from '../../assets/twitter.svg';
import YoutubeIcon from '../../assets/youtube.png';
import PinterestIcon from '../../assets/pinterest-icon.svg'
import { CommonFunction } from 'src/utils/commonFunctions/common-function.component';
import { PostUtil } from '../new-post-new/post-util';

const ConnectedMediaComponent = ({
  selectedSocialMedias,
  configuredSocialMedia,
  handleNetworks,
  handleDisabled = null,
  disabledProfilesIds = [],
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let fbColor = theme.palette.preview.facebook.color;
  let instaColor = theme.palette.preview.instagram.color;
  const getServiceLabelView = (serviceName) => {
    let sourceFile = null;
    switch (serviceName) {
      case PostUtil.ServiceName.TWITTER:
        sourceFile = (
          <GroupHeader>
            <img alt="Twitter_Icon" width={20} height={20} sx={{ fontSize: 22 }} src={TwitterIcon} />
            <Typography sx={{ fontSize: 18, fontWeight: 800, ml: 1, color: 'black' }}>Twitter</Typography>
          </GroupHeader>
        );
        break;
      case PostUtil.ServiceName.FACEBOOK:
        sourceFile = (
          <GroupHeader>
            <img alt="Facebook_Icon" width={20} height={20} sx={{ fontSize: 22, color: fbColor }} src={FacebookIcon} />
            <Typography sx={{ fontSize: 18, fontWeight: 800, ml: 1, color: fbColor }}>Facebook</Typography>
          </GroupHeader>
        );
        break;
      case PostUtil.ServiceName.YOUTUBE:
        sourceFile = (
          <GroupHeader>
            <img
              alt="youtube_icon"
              src={YoutubeIcon}
              width={30}
              height={30}
              sx={{ fontSize: 22 }}
              style={{ marginLeft: '-5px' }}
            />
            <Typography sx={{ fontSize: 18, fontWeight: 800, color: 'black' }} style={{ marginLeft: '5px' }}>
              YouTube
            </Typography>
          </GroupHeader>
        );
        break;
      case PostUtil.ServiceName.INSTAGRAM:
        sourceFile = (
          <GroupHeader>
            <img
              alt="Instagram_Icon"
              width={20}
              height={20}
              sx={{ fontSize: 22, color: instaColor }}
              src={InstagramIcon}
            />
            <Typography
              color={instaColor}
              style={{
                background: 'linear-gradient(to right, #f58529, #dd2a7b, #c13584, #833ab4, #5851db, #405de6)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
              sx={{ fontSize: 18, fontWeight: 800, ml: 1 }}
            >
              Instagram
            </Typography>
          </GroupHeader>
        );
        break;
      case PostUtil.ServiceName.LINKEDIN:
        sourceFile = (
          <GroupHeader>
            <img
              alt="linkedin_icon"
              src={LinkedInIcon}
              width={30}
              height={30}
              sx={{ fontSize: 22 }}
              style={{ marginLeft: '-5px' }}
            />
            <Typography sx={{ fontSize: 18, fontWeight: 800, color: 'black' }} style={{ marginLeft: '5px' }}>
              LinkedIn
            </Typography>
          </GroupHeader>
        );
        break;
      case PostUtil.ServiceName.TIKTOK:
        sourceFile = (
          <GroupHeader>
            <img
              alt="tiktok_icon"
              src={TikTokIcon}
              width={30}
              height={30}
              sx={{ fontSize: 22 }}
              style={{ marginLeft: '-5px' }}
            />
            <Typography sx={{ fontSize: 18, fontWeight: 800, color: 'black' }} style={{ marginLeft: '5px' }}>
              TikTok
            </Typography>
          </GroupHeader>
        );
        break;
      case PostUtil.ServiceName.GBUSINESS:
        sourceFile = (
          <GroupHeader>
            <img
              alt="gbusiness"
              src={GBusinessIcon}
              width={30}
              height={30}
              sx={{ fontSize: 22 }}
              style={{ marginLeft: '-5px' }}
            />
            <Typography sx={{ fontSize: 18, fontWeight: 800, color: 'black' }} style={{ marginLeft: '5px' }}>
              GoogleMyBusiness
            </Typography>
          </GroupHeader>
        );
        break;
      case PostUtil.ServiceName.PINTEREST:
        sourceFile = (
          <GroupHeader>
            <img
              alt="pinterest"
              src={PinterestIcon}
              width={30}
              height={30}
              sx={{ fontSize: 22 }}
              style={{ marginLeft: '-5px' }}
            />
            <Typography sx={{ fontSize: 18, fontWeight: 800, color: 'black' }} style={{ marginLeft: '5px' }}>
              Pinterest
            </Typography>
          </GroupHeader>
        );
        break;
      default:
        break;
    }
    return sourceFile;
  };
  const GroupHeader = styled('div')(({ theme }) => ({
    top: '-8px',
    padding: '20px 10px',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  }));
  const GroupItems = styled('ul')({
    padding: 0,
  });
  return (
    <Autocomplete
    multiple
    disabled={handleDisabled ? handleDisabled() : false}
    disableCloseOnSelect
    id="grouped-demo"
    options={configuredSocialMedia}
    groupBy={(option) => option.service_name.toUpperCase()}
    getOptionLabel={(option) => (option.profile_name ? option.profile_name : option.id)}
    renderOption={(props, option) => {
        const isDisabled = disabledProfilesIds.includes(option.id);
        return (
            <li {...props} style={{
                pointerEvents: isDisabled ? 'none' : 'auto',
                opacity: isDisabled ? 0.5 : 1,
            }}>
                {CommonFunction.InitialAccountBadgeWithName(option)}
            </li>
        );
    }}
    renderGroup={(params) => (
        <li key={params.key}>
            {getServiceLabelView(params.group)}
            <GroupItems>{params.children}</GroupItems>
        </li>
    )}
    renderInput={(params) => (
        <TextField
            {...params}
            placeholder={selectedSocialMedias && selectedSocialMedias.length > 0 ? '' : 'Select Your Profile'}
            inputProps={{
                ...params.inputProps,
                readOnly: isMobile ? true : false,
            }}
        />
    )}
    onChange={(e, values) => {
        handleNetworks(values);
    }}
    value={selectedSocialMedias}
    renderTags={(value, getTagProps) =>
        value.map((option, index) => (
            <Chip
                key={option.id}
                avatar={
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={CommonHelper.GetSocialMediaAvatar(option.service_name)}
                        sx={{
                          '& .MuiBadge-badge': {
                            height: 20,
                            width: 20,
                            minWidth: 20,
                            borderRadius: '50%',
                            border: '2px solid white', // adds a border for better separation
                            margin: '-4px', // adds slight spacing between badge and avatar
                          },
                        }}
                    >
                        <Avatar
                            src={option.profile_image ? option.profile_image : option.profile_name || option.id}
                            alt={option.profile_name ? option.profile_name : option.id}
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                height: 30,
                                width: 30,
                                bgcolor: 'primary.main',
                            }}
                        />
                    </Badge>
                }
                label={option.profile_name || option.id}
                sx={{
                  ml: 1, // adds some space between avatar and label
                  '& .MuiChip-label': {
                    marginLeft: '6px', // add more space between avatar and label if needed
                  },
                }}
                {...getTagProps({ index })}
            />
        ))
    }
/>
  );
};
export default ConnectedMediaComponent;

const baseUrl = process.env.REACT_APP_API_URL;
const redirectUri = process.env.REACT_APP_REDIRECT_URL;
const Youtube = {
  authorizeUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
  clientId: '392294599757-7vic9peaib6qai7olqvqj007v3pgjso7.apps.googleusercontent.com',
  redirectUri: `${redirectUri}/youtube-redirect`,
  scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
};
const Threads = {
  authorizeUrl: 'https://www.instagram.com/oauth/authorize',
  clientId: 579189177454671,
  redirectUri: `${redirectUri}/threads-redirect`,
  scope: 'threads_basic,threads_content_publish,threads_manage_replies,threads_read_replies,threads_manage',
};
const GoogleMyBusiness = {
  authorizeUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
  clientId: '392294599757-nqlelkduf631bareqcg1q3ks5t65a0ne.apps.googleusercontent.com',
  redirectUri: `${redirectUri}/gbusiness-redirect`,
  scope: 'https://www.googleapis.com/auth/business.manage',
};
const Instagram = {
  authorizeUrl: 'https://api.instagram.com/oauth/authorize',
  clientId: 2271193803068714,
  redirectUri: `${redirectUri}/facebook-redirect`,
  scope: 'user_profile,user_media',
};
const Facebook = {
  appId: 556082859565903,
  redirectUri: `${redirectUri}/facebook-redirect`,
  scope:
    `pages_show_list,pages_read_engagement,pages_read_user_content,pages_manage_posts,public_profile,instagram_basic , business_management, instagram_content_publish, 
    instagram_manage_comments, pages_manage_engagement, instagram_manage_messages, pages_messaging,read_insights,instagram_manage_insights`,
  state: true,
  xfbml: true,
  cookie: true,
  version: 'v2.7',
  responseType: 'code',
  SDK_URL: 'https://connect.facebook.net/en_EN/sdk.js',
  SCRIPT_ID: 'facebook-jssdk',
};
const LinkedIn = {
  authorizeUrl: 'https://www.linkedin.com/oauth/v2/authorization',
  clientId: '86b3vqsgm6yplu',
  redirectUri: `${redirectUri}/linkedin-redirect`,
  scope: "r_basicprofile,r_organization_social,w_member_social,w_organization_social,rw_organization_admin,profile",
  state: 123456
};
const Stripe = {
  clientId:
    'pk_live_51NsJleSHFbhO2NgA1fUCahPcroz7FN4KeVHIasIJLQx5pU6lKjBdWTfHK4AQRhoEichjelohFDxv3fIpcLTO95If00PPi9GtwK',
  // clientId:
  //   'pk_test_51NsJleSHFbhO2NgAhg0BcTjDHCbRAijfw52vDx9OfA0FsHoRHsIUcyxH8GkPGF0lp6ETgm2Wmy7e6uGqqrXyrvNg00gMnGhTge',
  redirectUri: `${redirectUri}/payment-redirect`,
};
const GoogleSignIn = {
  authorizeUrl: "https://accounts.google.com/o/oauth2/v2/auth",
  clientId: "392294599757-e6sf8cj9nv702bjsmi67opkphe6gipgi.apps.googleusercontent.com",
  redirectUri: `${redirectUri}/google-redirect`,
  scope: 'openid%20profile%20email'
};
const Tiktok = {
  authorizeUrl: "https://www.tiktok.com/v2/auth/authorize",
  clientId: "awlze1s3235okd0z",
  scope: "user.info.basic,user.info.profile,user.info.stats,video.publish,video.upload,video.list",
  responseType: "code",
  redirectUri: `${redirectUri}/tiktok-redirect`
}
const Pinterest = {
  authorizeUrl: 'https://www.pinterest.com/oauth/',
  clientId: '1504526',
  redirectUri: `${redirectUri}/pinterest-redirect`,
  scope : "boards:read,boards:read_secret,boards:write,boards:write_secret,pins:read,pins:read_secret,pins:write,pins:write_secret,user_accounts:read"
};
const Common = {
  notificationInterval: 60000,
  socialAccountsIntegrationInterval: 2000
};
const GoogleTagManager = {
  id: 'AW-11309238073'
}
const Maintainance = {
  StartTime: "25/09/2024 5:00 pm", //DD/MM/YY HH:mm // 12 hours format
  EndTime: "25/09/2024 5:55 pm"
}
export const Config = {
  Youtube,
  Threads,
  GoogleMyBusiness,
  Stripe,
  Instagram,
  Facebook,
  Common,
  LinkedIn,
  Pinterest,
  GoogleSignIn,
  Tiktok,
  GoogleTagManager,
  Maintainance
};

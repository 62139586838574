import { CommonHelper } from "src/utils/commonHelper";
import generatePalette from "./generate-palette";

const generateTheme = () => {
  let appDetails = CommonHelper.GetAppDetails();
  let primaryColor = CommonHelper.DomainColor.Primary;
  let secondaryColor = CommonHelper.DomainColor.Secondary;

  if (appDetails) {
    if (appDetails.primary_color) {
      primaryColor = appDetails.primary_color;
    }
    if (appDetails.secondary_color) {
      secondaryColor = appDetails.secondary_color;
    }
  }

  return generatePalette(primaryColor, secondaryColor);
};

export default generateTheme;
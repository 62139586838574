import {
    Box,
    Card,
    CardMedia,
    Grid,
    IconButton,
    Table, TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
    Typography,
    Stack,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import Comments from '../../../assets/comment.svg';
import Likes from '../../../assets/fblike.svg';
import Share from '../../../assets/fbshare.svg';
import {
    FavoriteBorderRounded as FavoriteBorderRoundedIcon,
    ChatBubbleOutline as ChatBubbleOutlineIcon,
    BookmarkBorder as BookmarkBorderIcon,
} from '@mui/icons-material'; import { CommonHelper } from 'src/utils/commonHelper';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
import TextIconLabel from 'src/components/TextIconLabel';


const TiktokMobileViewPosts = ({ publishedPosts, mobileLoader, getMobileViewTiktokNextPublishedPosts, pagination }) => {

    const theme = useTheme();
    const postcontentL = 220;
    const postcontentS = 110;
    return (
        <Box sx={{
            maxHeight: 'calc(100vh - 330px)',
            minHeight: 'calc(100vh - 330px)',
        }} >{publishedPosts.map((row, index) => {
            const { created_time, id, image_url, media_url, like_count, description, share_count, comment_count } = row;
            return (
                <Card key={index} sx={{ maxHeight: description?.length > 25 ? postcontentL : postcontentS, display: 'flex', flexDirection: 'row', mb: 1, p: 1, gap: 1 }}>
                    {image_url || media_url ? <CardMedia
                        component="img"
                        sx={{ width: 140, maxHeight: 140 }}
                        image={image_url ? image_url : media_url}
                        alt="Post image"
                    /> : null}
                    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 1, maxWidth: 200 }}>
                        <Typography variant="subtitle2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} >{description}</Typography>
                        <Stack
                            direction="row"
                            justifyContent="space-between" sx={{ gap: 1 }}>
                            <TextIconLabel
                                value={like_count}
                                aria-label="chatbubbleoutline"
                                sx={{ typography: 'subtitle2' }}
                                icon={<FavoriteBorderRoundedIcon fontSize="small" />}
                            />
                            <TextIconLabel
                                value={comment_count}
                                aria-label="chatbubbleoutline"
                                sx={{ typography: 'subtitle2' }}
                                icon={<img alt="comment" src={Comments} height={20} width={20} />}
                            />
                            <TextIconLabel
                                value={share_count}
                                aria-label="chatbubbleoutline"
                                sx={{ typography: 'subtitle2' }}
                                icon={<img alt="share" src={Share} height={20} width={20} />}
                            />
                        </Stack>
                        <Typography variant="body2" sx={{ fontSize: '10px' }} color="text.secondary">
                            {CommonHelper.GetDateFormat(created_time)}
                        </Typography>
                    </Box>
                </Card>
            );
        })}
            {pagination && <Box sx={{ textAlign: 'center' }}>
                {mobileLoader ? (
                    <ThreeDotLoader />
                ) : (
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 700,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: theme.palette.grey[500],
                        }}
                        onClick={getMobileViewTiktokNextPublishedPosts}
                    >
                        Load More Posts
                    </Typography>
                )}
            </Box>}</Box>
    )

}
export default TiktokMobileViewPosts;
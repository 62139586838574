import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  Stack,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Scrollbar from 'src/components/scrollbar';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Label from 'src/components/Label';
import { fToNow, fDateTime } from '../../../utils/formatTime';
import { Config } from 'src/config';

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState([]);
  const [totalUnRead, setUnReadNotifications] = useState(0);
  const [notificationInterval, setNotificationInterval ] = useState(Config.Common.notificationInterval);

  useEffect(() => {
    fetchNotification();
    const intervalId = setInterval(() => {
      fetchNotification();
    }, notificationInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const checkForPreviousApiCall = () => {
    let currentTime = new Date();
    let lastApiCallTime = CommonHelper.GetLastApiCallTime();
    if(lastApiCallTime)
    {

    }
  }

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const successCallback = (data, message) => {
    setStateToInitialState();
  };

  const failureCallback = () => {
    setStateToInitialState();
  };

  const handleMarkAllAsRead = () => {
    let postProps = {
      url: userModuleURL.updateNotification,
      body: null,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const setStateToInitialState = () => {
    setNotifications([]);
    setUnReadNotifications(0);
  };
  const notificationSuccessCallback = (data, message) => {
    if (data.notifications && data.notifications.length > 0) {
      setNotifications(data.notifications);
      setUnReadNotifications(data.unread_count);
    } else {
      setStateToInitialState();
    }
  };
  const notificationFailureCallback = (message) => {
    setStateToInitialState();
  };
  const fetchNotification = () => {
    let postProps = {
      url: userModuleURL.fetchNotification,
      successCallback: notificationSuccessCallback,
      failureCallback: notificationFailureCallback,
    };
    HttpServices.Get(postProps);
  };

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <NotificationsIcon sx={{ width: '20px', height: '20px' }} />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <DoneAllIcon sx={{ width: '18px', height: '18px' }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Scrollbar sx={{ height: { xs: 340, sm: 340 } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications.map((notification) => (
              <NotificationItem notification={notification.notification_details} notificationCategory={notification.notification_category} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />
      </Popover>
    </>
  );
}

function NotificationItem({ notification ,notificationCategory}) {
  const { avatar, title } = renderContent(notification, notificationCategory);
  if(notificationCategory === "approval_post" || notificationCategory === "rejected_post" || notificationCategory === "un_approval_post"){
    notification.notification_status = 'SUCCESS';
  }
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.read_receipts && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>{avatar}</ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <>
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled',
              }}
            >
              <AccessTimeOutlinedIcon sx={{ mr: 0.5, width: '16px', height: '16px' }} />
              {fToNow(notification.notification_time || notification.scheduled_time)}
              <Label color={notification.notification_status === 'SUCCESS' ? 'primary' : 'error'} sx={{ ml: 0.5 }}>
                {notification.notification_status}
              </Label>
            </Typography>
          </>
        }
      />
    </ListItemButton>
  );
}

function renderContent(notification, notificationCategory) {
  const {
    notification_category,
    service_name,
    connected_profile_image,
    connected_profile_name,
    created_by_profile_name,
    connected_profile_type,
    created_by_name,
    brand_name,
    notification_text,
    avatar,
    notification_status,
    dis_integrated_by_name,
    failure_reason,
    scheduled_time,
    media_url,
    description,
    approved_by_name,
    rejected_by_name,
    un_approved_by_name,
  } = notification;

  let badgeContent = CommonHelper.GetSocialMediaAvatar(service_name);
  let mediaFiles = CommonHelper.ProcessMediaUrl(media_url)?.media_data;
  let remainingCount = mediaFiles?.length > 1 ? mediaFiles?.length - 1 : 0;
  const connectedProfileType = connected_profile_type && connected_profile_type.charAt(0).toUpperCase() + connected_profile_type.slice(1).toLowerCase();
  const ServiceName = service_name && service_name.charAt(0).toUpperCase() + service_name.slice(1).toLowerCase();

  let profileImage = (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={badgeContent}
      sx={{ zIndex: 0,height: 20, width: 20 }}
    >
      <Avatar
        src={connected_profile_image}
        alt={connected_profile_name}
        sx={{ height: 35, width: 35, bgcolor: 'primary.main' }}
      />
    </Badge>
  );

  let title = '';
  switch (notificationCategory.toLowerCase()) {
    case 'social_connect':
      title = (
        <Typography variant="subtitle2">
          <b>{created_by_profile_name}</b> has successfully connected the {ServiceName} {connectedProfileType} '
          <b>{connected_profile_name}</b>' to the Brand <b>{brand_name}</b>!
        </Typography>
      );
      return { avatar: profileImage, title };

    case 'social_disconnect':
      title = (
        <Typography variant="subtitle2">
          <b>{dis_integrated_by_name}</b> has removed the {ServiceName} {connectedProfileType}'
          <b>{connected_profile_name}</b>' from the Brand <b>{brand_name}</b>.
        </Typography>
      );
      return { avatar: profileImage, title };

    case 'post_success':
      title = (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body2">
            <b>{created_by_profile_name ? created_by_profile_name : created_by_name}</b> successfully posted to <b>{ServiceName}</b>
            <b>{connectedProfileType}</b> at <b>{fDateTime(scheduled_time)}</b>
            <Typography
              noWrap="none"
              sx={{
                color: 'text.secondary',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: description.length > 10 ? 'initial' : 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              {description}
            </Typography>
          </Typography>
          {mediaFiles[0] && mediaFiles[0]?.url && (
            <Badge badgeContent={remainingCount} color="primary">
              <Avatar src={mediaFiles[0]?.url} variant="rounded" />
            </Badge>
          )}
        </Stack>
      );
      return { avatar: profileImage, title, mediaFiles, remainingCount };

    case 'post_failure':
      title = (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body2">
            <b>{created_by_profile_name}</b> failed to post on <b>{ServiceName}</b>
            <b>{connectedProfileType}</b> at <b>{fDateTime(scheduled_time)}</b> due to a
            <b>{failure_reason}</b>
            <Typography
              noWrap="none"
              sx={{
                color: 'text.secondary',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: description.length > 10 ? 'initial' : 1,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
                fontSize: '0.875rem',
              }}
            >
              {description}
            </Typography>
          </Typography>
          {mediaFiles[0] && mediaFiles[0]?.url && (
          <Badge badgeContent={remainingCount} color="primary">
            <Avatar src={mediaFiles[0]?.url} variant="rounded" />
          </Badge>)}
        </Stack>
      );
      return { avatar: profileImage, title };
    case 'approval_post':
      title = (
        <Typography variant="subtitle2" >
          <b>{approved_by_name}</b> successfully approved <b>{created_by_profile_name}</b>'s post in the Brand <b>{brand_name}</b> scheduled at <b>{fDateTime(scheduled_time)}</b>.
        </Typography>
      );
      return { avatar: profileImage, title };
    case 'rejected_post':
      title = (
        <Typography variant="subtitle2" >
          <b>{rejected_by_name}</b> rejected {created_by_profile_name}'s  post in the Brand <b>{brand_name}</b> scheduled at <b>{fDateTime(scheduled_time)}</b>.
        </Typography>
      );
      return { avatar: profileImage, title };
      case 'un_approval_post':
        title = (
          <Typography variant="subtitle2" >
            <b>{un_approved_by_name	}</b> unapproved {created_by_profile_name}'s  post in the Brand <b>{brand_name}</b> scheduled at <b>{fDateTime(scheduled_time)}</b>.
          </Typography>
        );
        return { avatar: profileImage, title };
    default:
      return {
        avatar: avatar ? <img alt={notification_text} src={avatar} /> : null,
        title: 'HE::'+{notification_category}
      };
  }
}

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { Container, IconButton, InputAdornment, Link, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader/loader';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { LocalStorage } from 'src/utils/storage/local-storage';
import { useToaster } from 'src/utils/toaster/toasterContext';
import ValidationTool from 'src/utils/validationHelper';
import useResponsive from '../hooks/useResponsive';
import GoogleSignInComponent from './google-signin/googleSignIn.component';
import landingImage from "../assets/app-image/landing.png";

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const Register = () => {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const appLogo = CommonHelper.GetAppLogo(true);
  const [formSchema, setFormSchema] = useState({});
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const { showToast } = useToaster();
  const [loading, setLoading] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);

  useEffect(() => {
    let token = LocalStorage.getItem('token');
    if (token) navigate('/new-post');
    else generateFormModelSchema();
  }, []);

  useEffect(() => { }, [email, password, firstName, lastName, formSchema, formValidationErrors]);

  const generateFormModelSchema = () => {
    let fields = [
      ValidationTool.GenerateSchema('firstName', 'First Name', 'TEXT', true),
      ValidationTool.GenerateSchema('email', 'Email', 'EMAIL', true),
      ValidationTool.GenerateSchema('password', 'Password', 'TEXT', true),
    ];
    const schema = ValidationTool.GenerateSchemaModel(fields);
    setFormSchema(schema);
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      default:
        break;
    }
  };

  const handleOnBlur = (event) => {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    let validationErrors = { ...formValidationErrors };
    let message = ValidationTool.ValidateInput(formSchema, fieldValue, fieldName);
    if (message) validationErrors[fieldName] = message;
    else validationErrors[fieldName] = '';
    setFormValidationErrors(validationErrors);
  };

  const handleRegister = () => {
    let registerForm = {
      firstName: firstName,
      email: email,
      password: password,
      device_type: isMobile ? CommonHelper.DeviceType.Mobile : CommonHelper.DeviceType.Desktop,
    };
    let { formValidationErrors, isValidForm } = ValidationTool.FormValidation(formSchema, registerForm);
    if (isValidForm) handleCreateNewUser();
    else setFormValidationErrors(formValidationErrors);
  };

  const handleLogin = () => {
    let loginForm = {
      email: 'demo@brand2social.com',
      password: 'demo1234',
    };
    let postProps = {
      url: userModuleURL.login,
      body: loginForm,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const handleCreateNewUser = () => {
    setLoading(true);
    let registerForm = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };
    let postProps = {
      url: userModuleURL.register,
      body: registerForm,
      successCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };
  const successCallback = (data, message) => {
    message && showToast(message);
    CommonHelper.AppLoginCall(data);
  };
  const failureCallback = (message) => {
    message && showToast(message, 'e');
    setLoading(false);
  };
  const navigateToLogin = () => {
    navigate('/login');
  };
  const applyDemoCredential = () => {
    setDemoLoading(true);
    handleLogin(true);
  };
  const StyledStack = styled(Stack)(({ }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  }));

  return (
    <>
      {loader && <Loader />}
      <Helmet>
        <title> Register </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <>
            <img
              src={appLogo}
              style={{
                height: '40px',
                position: 'fixed',
                top: '34px',
                left: '35px',
              }}
            />
            <StyledSection>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                Hi, Welcome to B2S
              </Typography>
              <img src={landingImage} alt="login" />
            </StyledSection>
          </>
        )}
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Register in to B2S
            </Typography>

            <Typography variant="body2" sx={{ mb: 3 }}>
              Already have an account? {''}
              <Link variant="subtitle2" onClick={navigateToLogin}>
                Click here
              </Link>
            </Typography>
            <Stack spacing={3} sx={{ mb: 5 }}>
              <TextField
                error={formValidationErrors?.firstName}
                color={firstName === '' ? 'error' : 'success'}
                name="firstName"
                label="First Name"
                onChange={handleInputChange}
                value={firstName}
                helperText={formValidationErrors?.firstName}
                onBlur={handleOnBlur}
              />
              <TextField name="lastName" label="Last Name" onChange={handleInputChange} value={lastName} />
              <TextField
                error={formValidationErrors?.email}
                color={email === '' ? 'error' : 'success'}
                name="email"
                label="Email address"
                onChange={handleInputChange}
                value={email}
                helperText={formValidationErrors?.email}
                onBlur={handleOnBlur}
              />
              <TextField
                error={formValidationErrors?.password}
                color={password === '' ? 'error' : 'success'}
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                onChange={handleInputChange}
                onBlur={handleOnBlur}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={formValidationErrors?.password}
              />
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              loading={loading}
              variant="contained"
              onClick={handleRegister}
            >
              Register
            </LoadingButton>
            <StyledStack>
              <LoadingButton
                fullWidth
                size="large"
                loading={demoLoading}
                variant="contained"
                onClick={applyDemoCredential}
              >
                Use Demo Account
              </LoadingButton>
            </StyledStack>
            <StyledStack>
              <GoogleSignInComponent handleLoginSuccess={successCallback} googleButtonText="Register via Google" />
            </StyledStack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
};
export default Register;

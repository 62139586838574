import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Box,
    Card,
    Stack,
    TextField,
    Grid,
    Chip,
    Typography,
    Tabs,
    Tab,
    tabsClasses,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import NoPostFound from 'src/pages/publishedPosts/noPost.component';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import SummaryComponent from './summary/summary.component';

const FacebookReportHeaderComponent = ({ facebookPageId, startDate, endDate, viewBy }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [summary, setSummary] = useState({}); 
    const [isNetworksLoaded, setNetworkLoaded] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [height, setHeight] = useState(window.innerHeight - 250);

    useEffect(() => {
        getReportsDetails();
    }, []);
    useEffect(() => {}, [
        summary,
        isNetworksLoaded,
      ]);

    const reportSuccessCallback = (data) => {
        const fbSummary = data.find((a) => a.report_name.toUpperCase() === "SUMMARY");
        setSummary(fbSummary);
        setNetworkLoaded(true);
        setLoading(false);
    };
    
    const reportFailureCallback = (error) => {
        setSummary([])
        setLoading(false); 
    };

    const ACCOUNT_TABS = [
        {
            id:0,
            value: 'summary',
            component: <SummaryComponent facebookPageId={facebookPageId} startDate={startDate} endDate={endDate} viewBy={viewBy} summary={summary} />,
        },
        // {
        //     id:1,
        //     value: 'audience',
        //     component: <>Coming soon</>,
        // },
    ];

    const getReportsDetails = () => {
        const getProps = {
            url: `${integrateModuleURL.getReportsDetails}${"facebook"}`,
            successCallback: reportSuccessCallback,
            failureCallback: reportFailureCallback,
        };
        HttpServices.Get(getProps);
    };
    const handleTab =(value)=>{ 
        setCurrentTab(value);
    }

    return (
        <Box>
            {isLoading ? (
                <Spinner height={height} />
            ) : (
                <>
                    <Tabs
                        value={currentTab}
                        variant="scrollable"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { display: 'none' },
                            },
                            ...(!isMobile && { gap: '0.5rem', minHeight: '37px' }),
                        }}
                        allowScrollButtonsMobile
                        onChange={(e, value) => handleTab(value)}
                    >
                        {ACCOUNT_TABS.map((tab) => (
                            <Tab key={tab.value} label={tab.value} />
                        ))}
                    </Tabs>

                    {isNetworksLoaded ? (
                        ACCOUNT_TABS.map((tab) => {
                            const isMatched = tab.id === currentTab;
                            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                        })
                    ) : (
                        <NoPostFound height={height}/> 
                    )}
                </>
            )}
        </Box>
    );
};

export default FacebookReportHeaderComponent;

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Avatar, Typography, DialogContent, Dialog } from '@mui/material';
// import { DialogAnimate } from '../../../components/animate';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import CallIcon from '@mui/icons-material/Call';
import MarkunreadIcon from '@mui/icons-material/Markunread';
const RowStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(1.5),
}));

ChatRoomPopup.propTypes = {
  participant: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function ChatRoomPopup({ participant, isOpen, onClose }) {
  const { name, avatar, position, address, phone, email } = participant;

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogContent sx={{ pb: 5, textAlign: 'center' }}>
        <Avatar
          alt={name}
          src={avatar}
          sx={{
            mt: 5,
            mb: 2,
            mx: 'auto',
            width: 96,
            height: 96,
          }}
        />
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body2" paragraph sx={{ color: 'text.secondary' }}>
          {position}
        </Typography>

        <RowStyle>
        <PlaceRoundedIcon sx={{ mr: 1, width: 16, height: 16, color: 'text.disabled' }} />
          <Typography variant="body2">{address}</Typography>
        </RowStyle>
        <RowStyle>
        <CallIcon sx={{ mr: 1, width: 16, height: 16, color: 'text.disabled' }} />
          <Typography variant="body2">{phone}</Typography>
        </RowStyle>
        <RowStyle>
        <MarkunreadIcon  sx={{ mr: 1, width: 16, height: 16, color: 'text.disabled' }} />
          <Typography variant="body2">{email}</Typography>
        </RowStyle>
      </DialogContent>
    </Dialog>
  );
}

import { Group as GroupIcon, Business as BusinessIcon, BrandingWatermark as BrandingWatermarkIcon, Build as BuildIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { CommonHelper } from 'src/utils/commonHelper';
import OrgManageBrand from './organizationBrand';
import OrgInfoComponent from './organizationInfo';
import OrgManageUsers from './organizationUsers';

export default function ManageOrganization() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userPermissions = CommonHelper.GetBrandInfoFromLocalStorage("permissions");

  const [currentTab, setCurrentTab] = useState('Organization Info');

  const ACCOUNT_TABS = [
    {
      value: 'Organization Info',
      icon: <BusinessIcon sx={{ width: "20px", height: "20px" }} />,
      component: <OrgInfoComponent />,
      permissions: [CommonHelper.PermissionList.READ_POST]
    },
    {
      value: 'Manage Brand',
      icon: <BuildIcon sx={{ width: "20px", height: "20px" }} />,
      component: <OrgManageBrand />,
      permissions: [CommonHelper.PermissionList.ORG_ADMIN]
    },
    {
      value: 'Manage Users',
      icon: <GroupIcon sx={{ width: "20px", height: "20px" }} />,
      component: <OrgManageUsers />,
      permissions: [CommonHelper.PermissionList.ORG_ADMIN]
    },
  ];

  return (
    <>
      <Helmet>
        <title>Organization Management</title>
      </Helmet>
      <Box>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          sx={{ gap: '0.5rem', minHeight: '37px' }}
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {ACCOUNT_TABS.map((tab) => {
            let isShow = tab.permissions.some(b => userPermissions[b]);
            return isShow && <Tab
              disableRipple
              key={tab.value}
              label={isMobile ? "" : capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
              sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }}
            />
          })}
        </Tabs>

      </Box>
      <Box sx={{ mb: 2 }} />
      {ACCOUNT_TABS.map((tab) => {
        let isShow = tab.permissions.some(b => userPermissions[b]);
        const isMatched = tab.value === currentTab;
        return isShow && isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </>
  );
}


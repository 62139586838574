import { Box, Tab, Tabs, Typography,TextField,Stack, tabsClasses } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import Facebook_Icon from '../../assets/facebook.svg';
import Gbusiness_Icon from '../../assets/google-my-business-logo.svg';
import Instagram_Icon from '../../assets/instagram.svg';
import LinkedIn_Icon from '../../assets/linked_in.svg';
import Youtube_Icon from '../../assets/youtube.png';
import { PostUtil } from 'src/pages/new-post-new/post-util';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FacebookReportComponent from './facebook-report/facebook-report.component';
import InstagramReportComponent from './instagram-report/instagram-report.component';

const Report = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  //State Variable
  const [currentTab, setCurrentTab] = useState('Facebook Page');
  const [facebookNetworks, setFacebookNetworks] = useState([]);
  const [facebookGroupNetworks, setfacebookGroupNetworks] = useState([]);
  const [instagramNetworks, setInstagramNetworks] = useState([]);
  const [youtubeNetworks, setYoutubeNetworks] = useState([]);
  const [twitterNetworks, setTwitterNetworks] = useState([]);
  const [linkedInNetworks, setLinkedInNetworks] = useState([]);
  const [tiktokNetworks, setTiktokNetworks] = useState([]);
  const [gbusinessNetworks, setGBusinessNetworks] = useState([]);
  const [isNetworksLoaded, setNetworkLoaded] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const currentDate = new Date();
    const pastDate = new Date();
    pastDate.setDate(currentDate.getDate() - 30); 
    return pastDate;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [viewBy, setViewBy] = useState('day');

  const ACCOUNT_TABS = [
    {
      value: 'Facebook Page',
      icon: <img src={Facebook_Icon} width={28} height={20} />,
      component: <FacebookReportComponent connectedNetworks={facebookNetworks} startDate={startDate} endDate={endDate} viewBy={viewBy}/>,
    },
    {
      value: 'Instagram',
      icon: <img src={Instagram_Icon} width={28} height={20} />,
      component: <InstagramReportComponent connectedNetworks={instagramNetworks} startDate={startDate} endDate={endDate} viewBy={viewBy}/>,
    },
    // {
    //   value: 'Youtube',
    //   icon: <img src={Youtube_Icon} width={35} height={35} />,
    //   component: <>Coming soon</>,
    // },
    // {
    //   value: 'LinkedIn Page',
    //   icon: <img src={LinkedIn_Icon} width={28} height={20} />,
    //   component: <>Coming soon</>,
    // },
    // {
    //   value: 'GoogleMyBusiness',
    //   icon: <img src={Gbusiness_Icon} width={28} height={20} />,
    //   component: <>Coming soon</>,
    // },
    // {
    //   value: 'TikTok',
    //   icon: <img src={Tiktok_Icon} width={28} height={20} />,
    //   component: <TikTokPagePostGrid connectedNetworks={tiktokNetworks} />,
    // }
  ];

  useEffect(() => {
    getConnectedNetworks();
  }, []);

  useEffect(() => {}, [
    facebookNetworks,
    instagramNetworks,
    youtubeNetworks,
    twitterNetworks,
    linkedInNetworks,
    tiktokNetworks,
    gbusinessNetworks,
    isNetworksLoaded,
  ]);
  const connectedNetworksSuccessCallback = (data, message) => {
    let fNetworks = data.filter(
      (a) =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK &&
        a.profile_type == PostUtil.ProfileType.PAGE &&
        a.is_enabled
    );
    let fgNetworks = data.filter(
      (a) =>
        a.service_name.toUpperCase() === PostUtil.ServiceName.FACEBOOK &&
        a.profile_type == PostUtil.ProfileType.GROUP &&
        a.is_enabled
    );
    let iNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.INSTAGRAM && a.is_enabled);
    let yNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.YOUTUBE && a.is_enabled);
    let tNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.TWITTER && a.is_enabled);
    let gNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.GBUSINESS && a.is_enabled);
    let lNetworks = data.filter(
      (a) => a.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN && a.profile_type === 'PAGE' && a.is_enabled
    );
    // let lNetworks = data.filter(a => a.service_name.toUpperCase() === PostUtil.ServiceName.LINKEDIN);
    let tiNetworks = data.filter((a) => a.service_name.toUpperCase() === PostUtil.ServiceName.TIKTOK);
    setFacebookNetworks(fNetworks);
    setfacebookGroupNetworks(fgNetworks);
    setInstagramNetworks(iNetworks);
    setYoutubeNetworks(yNetworks);
    setTwitterNetworks(tNetworks);
    setLinkedInNetworks(lNetworks);
    setTiktokNetworks(tiNetworks);
    setGBusinessNetworks(gNetworks);
    setNetworkLoaded(true);
  };
  const connectedNetworkFailureCallback = (message) => {
    setFacebookNetworks([]);
    setInstagramNetworks([]);
    setYoutubeNetworks([]);
    setTwitterNetworks([]);
    setLinkedInNetworks([]);
    setTiktokNetworks([]);
    setGBusinessNetworks([]);
  };
  const getConnectedNetworks = () => {
    let getProps = {
      url: integrateModuleURL.getConnectedNetworks,
      successCallback: connectedNetworksSuccessCallback,
      failureCallback: connectedNetworkFailureCallback,
    };
    HttpServices.Get(getProps);
  };

  return (
    <>
      <Helmet>
        <title> Published Post </title>
      </Helmet>
      <Box display='flex' justifyContent='space-between'>
        <Tabs
          value={currentTab}
          variant="scrollable"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { display:'none' },
            },
            ...(!isMobile && { gap: '0.5rem', minHeight: '37px' }),
          }}
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {ACCOUNT_TABS.map((tab) => {
            return (
              <Tab
                disableRipple
                key={tab.value}
                label={isMobile ? '' : capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
                sx={{
                  ...(!isMobile && {
                    flexDirection: 'row',
                    gap: '0.25rem',
                    padding: 0,
                    minHeight: '10px',
                    mr: '0.75rem',
                    fontWeight: 600,
                  }),
                }}
              />
            );
          })}
        </Tabs>
        <Stack direction='row' spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            </Stack>
      </Box>
      <Box sx={{ mb: 2 }} />
      {isNetworksLoaded &&
        ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
    </>
  );
};

export default Report;

import { FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import UploadAvatar from '../upload/UploadAvatar';


export function RHFUploadAvatar({ name, url, handleDeleteProfiles, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;
        return (
          <div>
            <UploadAvatar error={checkError} {...other} file={field.value ? field.value : url} handleDeleteProfiles={handleDeleteProfiles}/>
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

import { Avatar, Badge, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CommonHelper } from "src/utils/commonHelper";
import NoImage from '../../../assets/no-media.svg';

const FacebookCardDetails = ({ service }) => {
    const profile = {
        id: service.id,
        profile_name: service.name,
        profile_image: service.url,
        date: service.posts && service.posts.length > 0 ? service.posts[0].created_time : null,
    };
    let cardDetails = null;
    if (service.posts && service.posts.length > 0) {
        const post = service.posts[0];
        cardDetails = {
            mediaFiles: post.image_url,
            description: post.message,
            profile,
        };
    }
    return (
        <>
            <Card sx={{ padding: 2 , height: "510px"}}>
                <CardContent sx={{ height: "380px" }} >
                    <CardHeader
                        avatar={
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={CommonHelper.GetSocialMediaAvatar("facebook")}
                                sx={{ zIndex: 0 }}
                            >
                                <Avatar
                                    src={cardDetails.profile.profile_image}
                                    alt={cardDetails.profile.profile_name}
                                    sx={{ height: 35, width: 35, bgcolor: 'primary.main' }}
                                />
                            </Badge>
                        }

                        title={profile?.profile_name}
                        sx={{
                            fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 }, padding: '0px 0px 10px',
                            '.MuiCardHeader-title': {
                                fontSize: '0.875rem',
                            },
                            '.MuiCardHeader-subheader': {
                                fontSize: '0.75rem', fontWeight: 500,
                            }
                        }}
                        subheader={CommonHelper.DateTimeFromCurrentTime(profile?.date)}
                    />
                    <CardContent sx={{ padding: 0, height: "35px" }}>
                        <Typography
                            style={{
                                display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden',
                                WebkitBoxDecorationBreak: 'clone', whiteSpace: 'pre-line', textOverflow: 'ellipsis', fontSize: '0.875rem',
                            }}
                        >
                            {cardDetails.description}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <img src={cardDetails.mediaFiles ? cardDetails.mediaFiles : NoImage } alt={cardDetails.mediaFiles} width={'100%'} height={200} style={{ objectFit: cardDetails.mediaFiles ? 'cover': 'contain' }} />
                    </CardContent>
                </CardContent>


                {service.posts && service.posts.length > 0 &&
                    <>
                        <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
                            <Typography>Like </Typography> <Typography>{service.posts[0].like_count}</Typography>
                        </Stack>
                        <Stack spacing={2} direction="row" justifyContent="space-between" >
                            <Typography>Comment </Typography> <Typography>{service.posts[0].comment_count}</Typography>
                        </Stack>
                        <Stack spacing={2} direction="row" justifyContent="space-between" >
                            <Typography>Share </Typography> <Typography>{service.posts[0].share_count}</Typography>
                        </Stack>
                    </>}
            </Card >
        </>
    )
}
export default FacebookCardDetails;

import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent, TextField, IconButton, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

const TwitterAppSumoAuthorizationModal = ({
    handlTwitterOauthToken,
    handleClose,
    handleInputChange,
    appKey,
    appSecretKey,
    accessToken,
    accessSecretToken,
    isLoadingOn
}) => {

    const handleConnectTwitter = () => {
        handlTwitterOauthToken();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography gutterBottom variant="h4">
                    Authorize Brand2Social to access your account?
                </Typography>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{
                    backgroundColor: 'whitesmoke',
                    padding: 2
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="subtitle1" color="textSecondary">
                        Please enter your Twitter App credentials.
                    </Typography>
                    <IconButton
                        color="primary"
                        component="a"
                        href="https://www.youtube.com/watch?v=HENxXi5sK1A"
                        target="_blank"
                    >
                        <VideoLibraryIcon />
                    </IconButton>
                </Box>
                <CardContent
                    sx={{
                        p: 0,
                        color: 'grey.800',
                    }}
                >
                    <TextField
                        name="appKey"
                        value={appKey}
                        label="API Key"
                        fullWidth
                        margin="normal"
                        onChange={handleInputChange}
                    />
                    <TextField
                        name="appSecretKey"
                        value={appSecretKey}
                        label="API Key Secret"
                        fullWidth
                        margin="normal"
                        onChange={handleInputChange}
                    />
                    <TextField
                        name="accessToken"
                        value={accessToken}
                        label="Access Token"
                        fullWidth
                        margin="normal"
                        onChange={handleInputChange}
                    />
                    <TextField
                        name="accessSecretToken"
                        value={accessSecretToken}
                        label="Access Token Secret"
                        fullWidth
                        margin="normal"
                        onChange={handleInputChange}
                    />
                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={handleClose}>Close</Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoadingOn}
                    autoFocus
                    onClick={handleConnectTwitter}
                >
                    Connect
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default TwitterAppSumoAuthorizationModal;

import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL, postsModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import MobileNewPostComponent from './mobile-new-post.component';
import PostContentComponent from './post-content.component';
import PostFooter from './post-footer.component';
import PostTabComponent from './post-tab.component';
import { PostUtil } from './post-util';
import { CommonHelper } from 'src/utils/commonHelper';

const NewPostComponent = ({ }) => {
  const PostOption = {
    PostNow: { Name: 'postnow', Label: 'Post Now' },
    SchedulePost: { Name: 'schedulepost', Label: 'Scheduled Post' },
    ApprovalPost: { Name: 'approval', Label: 'Send for Approval' },
  };

  const NewPostStruct = {
    media_url: '',
    media_data: [],
    description: '',
    scheduled_time: new Date(),
    is_file_upload: false,
    connected_profile_ids: [],
    scheduled_event_requests_data: {
      facebook_requests_data: [],
      instagram_requests_data: [],
      twitter_requests_data: [],
      youtube_requests_data: [],
      gbusiness_requests_data: [],
      linkedin_requests_data: [],
      tiktok_requests_data: [],
      pinterest_requests_data: [],
    },
    comment_description: '',
    has_comment: false,
  };

  const location = useLocation();
  const { showToast } = useToaster();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const hasApproverPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.APPROVER
  );

  //State Variables
  const [loader, setLoader] = useState(true);
  const [configuredSocialMedia, setConfiguredSocialMedia] = useState([]);
  const [selectedSocialMedias, setSelectedSocialMedias] = useState([]);
  const [scheduleDateTime, setScheduleDateTime] = useState(new Date());
  const [scheduleId, setScheduleId] = useState(null);
  const [isSubmitting, setPostSubmitting] = useState(false);
  const [isSubmittingDraft, setDraftSubmitting] = useState(false);
  const [whenToPost, setWhenToPost] = useState(hasApproverPermission ? PostOption.PostNow.Name : PostOption.ApprovalPost.Name);
  const [newPostForm, setNewPostForm] = useState(NewPostStruct);
  const [tabValue, setTabValue] = useState(1);

  //Ref Variables
  const facebookConditionRef = useRef(null);
  const instagramConditionRef = useRef(null);
  const twitterConditionRef = useRef(null);
  const youtubeConditionRef = useRef(null);
  const linkedInConditionRef = useRef(null);
  const tiktokConditionRef = useRef(null);
  const googleMyBusinessConditionRef = useRef(null);
  const pinterestConditionRef = useRef(null);
  const previewRef = useRef(null);

  const setStateToInitialStage = () => {
    setSelectedSocialMedias([]);
    setScheduleDateTime(new Date());
    setWhenToPost(hasApproverPermission ? PostOption.PostNow.Name : PostOption.ApprovalPost.Name);
    setNewPostForm(NewPostStruct);

    facebookConditionRef?.current?.handleRequestData([]);
    instagramConditionRef?.current?.handleRequestData([]);
    twitterConditionRef?.current?.handleRequestData([]);
    youtubeConditionRef?.current?.handleRequestData([]);
    tiktokConditionRef?.current?.handleRequestData([]);
    linkedInConditionRef?.current?.handleRequestData([]);
    googleMyBusinessConditionRef?.current?.handleRequestData([]);
    pinterestConditionRef?.current?.handleRequestData([]);
    previewRef?.current?.handlePreviewData(JSON.parse(JSON.stringify(NewPostStruct)));
  };

  useEffect(() => {
    getConnectedNetworks();
  }, []);

  const getConnectedNetworks = () => {
    setLoader(true);
    let getProps = {
      url: integrateModuleURL.getConnectedNetworks,
      successCallback: connectedNetworksSuccessCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };
  const connectedNetworksSuccessCallback = (data, message) => {
    setLoader(false);
    message && showToast(message, 'e');
    const filterConnectedNetworks = data?.filter((a) => a.is_enabled && a.is_active)
    setConfiguredSocialMedia(filterConnectedNetworks);
    //TODO: Need to check the plan and need to select
    if (location.state?.data) {
      setNewPostForm({ ...location.state.data });
      const scheduledTime = new Date(location.state.data.scheduled_time);
      const currentTime = new Date();
      setWhenToPost(scheduledTime > currentTime ? PostOption.SchedulePost.Name : PostOption.PostNow.Name);
      setScheduleId(location.state.data?.schedule_id)
      setScheduleDateTime(scheduledTime > currentTime ? scheduledTime : currentTime);
      if (location.state.data.connected_profile_ids && location.state.data.connected_profile_ids.length > 0) {
        let selectedSocialMediaAccounts = data.filter((a) => location.state.data.connected_profile_ids.includes(a.id));
        setSelectedSocialMedias(selectedSocialMediaAccounts);
      }
    }
    else if (location.state?.media_data) {
      setSelectedSocialMedias(filterConnectedNetworks);
      processConnectedNetworksData(filterConnectedNetworks);
      let mediaData = location.state.media_data;
      handleMediaFiles(mediaData.media_data)
    }
    else {
      setSelectedSocialMedias(filterConnectedNetworks);
      processConnectedNetworksData(filterConnectedNetworks);
    }
  };
  const processConnectedNetworksData = (connectedNetworks) => {
    let formData = { ...NewPostStruct };
    connectedNetworks.map((network) => {
      formData.connected_profile_ids = formData.connected_profile_ids.concat(network.id);
      switch (network.service_name.toUpperCase()) {
        case PostUtil.ServiceName.FACEBOOK:
          let facebookData = {
            connected_profile_id: network.id,
            media_url: '',
            description: '',
            comment_description: formData.comment_description,
            has_comment: false,
            post_type: PostUtil.PostType.POST,
            connected_profile_details: {
              connected_profile_id: network.id,
              connected_profile_name: network.profile_name,
              connected_profile_image: network.profile_image,
              social_media: network.service_name
            }

          };
          formData.scheduled_event_requests_data.facebook_requests_data.push(facebookData);
          break;
        case PostUtil.ServiceName.INSTAGRAM:
          let instagramData = {
            connected_profile_id: network.id,
            media_url: '',
            description: '',
            post_type: PostUtil.PostType.POST,
            comment_description: formData.comment_description,
            has_comment: false,
            connected_profile_details: {
              connected_profile_id: network.id,
              connected_profile_name: network.profile_name,
              connected_profile_image: network.profile_image,
              social_media: network.service_name
            }
          };
          formData.scheduled_event_requests_data.instagram_requests_data.push(instagramData);
          break;
        case PostUtil.ServiceName.TWITTER:
          let twitterData = {
            connected_profile_id: network.id,
            media_url: '',
            description: '',
            post_type: PostUtil.PostType.POST,
            connected_profile_details: {
              connected_profile_id: network.id,
              connected_profile_name: network.profile_name,
              connected_profile_image: network.profile_image,
              social_media: network.service_name
            }
          };
          formData.scheduled_event_requests_data.twitter_requests_data.push(twitterData);
          break;
        case PostUtil.ServiceName.YOUTUBE:
          let youtubeData = {
            connected_profile_id: network.id,
            media_url: '',
            description: '',
            thumbnail: '',
            post_type: PostUtil.PostType.VIDEO,
            comment_description: formData.comment_description,
            has_comment: false,
            privacy: PostUtil.YoutubePrivacyLevel.PUBLIC,
            connected_profile_details: {
              connected_profile_id: network.id,
              connected_profile_name: network.profile_name,
              connected_profile_image: network.profile_image,
              social_media: network.service_name
            }
          };
          formData.scheduled_event_requests_data.youtube_requests_data.push(youtubeData);
          break;
        case PostUtil.ServiceName.LINKEDIN:
          let linkedData = {
            connected_profile_id: network.id,
            media_url: '',
            description: '',
            post_type: PostUtil.PostType.POST,
            comment_description: formData.comment_description,
            has_comment: false,
            connected_profile_details: {
              connected_profile_id: network.id,
              connected_profile_name: network.profile_name,
              connected_profile_image: network.profile_image,
              social_media: network.service_name
            }
          };
          formData.scheduled_event_requests_data.linkedin_requests_data.push(linkedData);
          break;
        case PostUtil.ServiceName.TIKTOK:
          let tiktokData = {
            connected_profile_id: network.id,
            media_url: '',
            description: '',
            title: '',
            post_type: PostUtil.PostType.DIRECT_POST,
            privacy: PostUtil.TikTokPrivacyLevel.PUBLIC_TO_EVERYONE,
            allow_user_to_comment: true,
            allow_user_to_duet: true,
            allow_user_to_switch: true,
            disclosure_content: false,
            your_brand: false,
            branded_content: false,
            connected_profile_details: {
              connected_profile_id: network.id,
              connected_profile_name: network.profile_name,
              connected_profile_image: network.profile_image,
              social_media: network.service_name
            }
          };
          formData.scheduled_event_requests_data.tiktok_requests_data.push(tiktokData);
          break;
        case PostUtil.ServiceName.GBUSINESS:
          let gBusinessData = {
            connected_profile_id: network.id,
            media_url: '',
            description: '',
            post_type: PostUtil.PostType.STANDARD,
            button_type: 'None',
            connected_profile_details: {
              connected_profile_id: network.id,
              connected_profile_name: network.profile_name,
              connected_profile_image: network.profile_image,
              social_media: network.service_name
            },
            start_date: new Date(),
            end_time: new Date(),
            start_time: new Date(),
            end_date: (() => {
              const date = new Date();
              date.setDate(date.getDate() + 7);
              return date;
            })(),
          };
          formData.scheduled_event_requests_data.gbusiness_requests_data.push(gBusinessData);
          break;
        case PostUtil.ServiceName.PINTEREST:
          let pinterestData = {
            connected_profile_id: network.id,
            media_url: '',
            description: '',
            title: '',
            board_id: '',
            cover_image_url: '',
            post_type: PostUtil.PostType.POST,
            connected_profile_details: {
              connected_profile_id: network.id,
              connected_profile_name: network.profile_name,
              connected_profile_image: network.profile_image,
              social_media: network.service_name
            }
          };
          formData.scheduled_event_requests_data.pinterest_requests_data.push(pinterestData);
          break;
        default:
          break;
      }
    });
    setNewPostForm({ ...formData });
  };
  const failureCallback = (message) => {
    setLoader(false);
    setDraftSubmitting(false);
    setPostSubmitting(false);
    message && showToast(message, 'e');
  };
  const handleNetworks = (socialMedias) => {
    setSelectedSocialMedias([...socialMedias]);
    if (socialMedias.length == 0) {
      handleDeleteAllNetworks();
      return;
    }
    let selectedSocialNetworks = [...selectedSocialMedias];
    let deletedNetwork = null;
    let addedNetwork = null;
    socialMedias.map((media) => {
      let index = selectedSocialNetworks.findIndex((a) => a.id === media.id);
      if (index >= 0) {
        selectedSocialNetworks.splice(index, 1);
      } else {
        addedNetwork = media;
      }
    });
    deletedNetwork = selectedSocialNetworks?.length > 0 ? selectedSocialNetworks[0] : null;

    if (addedNetwork) {
      handleAddNewNetwork(addedNetwork);
    } else {
      handleDeleteNetwork(deletedNetwork);
    }
  };
  const handleAddNewNetwork = (addedNetwork) => {
    let newFormData = { ...newPostForm };
    newFormData.connected_profile_ids.push(addedNetwork.id);

    const commonData = {
      connected_profile_id: addedNetwork.id,
      media_url: newFormData.media_url,
      media_data: newFormData.media_data,
      description: newFormData.description,
      comment_description: newFormData.comment_description,
      post_type: whenToPost,
    };

    switch (addedNetwork.service_name.toUpperCase()) {
      case PostUtil.ServiceName.FACEBOOK:
        newFormData.scheduled_event_requests_data.facebook_requests_data.push({
          ...commonData,
          post_type: PostUtil.PostType.POST,
          comment_description: commonData.comment_description,
          has_comment: false,
          connected_profile_details: {
            connected_profile_id: addedNetwork.id,
            connected_profile_name: addedNetwork.profile_name,
            connected_profile_image: addedNetwork.profile_image,
            social_media: addedNetwork.service_name
          },

        });
        if (facebookConditionRef && facebookConditionRef.current) {
          facebookConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.facebook_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.INSTAGRAM:
        newFormData.scheduled_event_requests_data.instagram_requests_data.push({
          ...commonData,
          post_type: PostUtil.PostType.POST,
          comment_description: commonData.comment_description,
          has_comment: false,
          connected_profile_details: {
            connected_profile_id: addedNetwork.id,
            connected_profile_name: addedNetwork.profile_name,
            connected_profile_image: addedNetwork.profile_image,
            social_media: addedNetwork.service_name
          },
        });
        if (instagramConditionRef && instagramConditionRef.current) {
          instagramConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.instagram_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.TWITTER:
        newFormData.scheduled_event_requests_data.twitter_requests_data.push({
          ...commonData,
          post_type: PostUtil.PostType.POST,
          connected_profile_details: {
            connected_profile_id: addedNetwork.id,
            connected_profile_name: addedNetwork.profile_name,
            connected_profile_image: addedNetwork.profile_image,
            social_media: addedNetwork.service_name
          },
        });
        if (twitterConditionRef && twitterConditionRef.current) {
          twitterConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.twitter_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.YOUTUBE:
        newFormData.scheduled_event_requests_data.youtube_requests_data.push({
          ...commonData,
          post_type: PostUtil.PostType.VIDEO,
          comment_description: commonData.comment_description,
          has_comment: false,
          privacy: PostUtil.YoutubePrivacyLevel.PUBLIC,
          thumbnail: '',
          connected_profile_details: {
            connected_profile_id: addedNetwork.id,
            connected_profile_name: addedNetwork.profile_name,
            connected_profile_image: addedNetwork.profile_image,
            social_media: addedNetwork.service_name
          },
        });
        if (youtubeConditionRef && youtubeConditionRef.current) {
          youtubeConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.youtube_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.LINKEDIN:
        newFormData.scheduled_event_requests_data.linkedin_requests_data.push({
          ...commonData,
          post_type: PostUtil.PostType.POST,
          comment_description: commonData.comment_description,
          has_comment: false,
          connected_profile_details: {
            connected_profile_id: addedNetwork.id,
            connected_profile_name: addedNetwork.profile_name,
            connected_profile_image: addedNetwork.profile_image,
            social_media: addedNetwork.service_name
          },
        });
        if (linkedInConditionRef && linkedInConditionRef.current) {
          linkedInConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.linkedin_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.TIKTOK:
        newFormData.scheduled_event_requests_data.tiktok_requests_data.push({
          ...commonData,
          title: '',
          post_type: PostUtil.PostType.DIRECT_POST,
          privacy: PostUtil.TikTokPrivacyLevel.PUBLIC_TO_EVERYONE,
          allow_user_to_comment: true,
          allow_user_to_duet: true,
          allow_user_to_switch: true,
          disclosure_content: false,
          your_brand: false,
          branded_content: false,
          connected_profile_details: {
            connected_profile_id: addedNetwork.id,
            connected_profile_name: addedNetwork.profile_name,
            connected_profile_image: addedNetwork.profile_image,
            social_media: addedNetwork.service_name
          },
        });
        if (tiktokConditionRef && tiktokConditionRef.current) {
          tiktokConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.tiktok_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.GBUSINESS:
        newFormData.scheduled_event_requests_data.gbusiness_requests_data.push({
          ...commonData,
          button_type: 'None',
          post_type: PostUtil.PostType.STANDARD,
          start_date: new Date(),
          end_date: (() => {
            const date = new Date();
            date.setDate(date.getDate() + 7);
            return date;
          })(),
          connected_profile_details: {
            connected_profile_id: addedNetwork.id,
            connected_profile_name: addedNetwork.profile_name,
            connected_profile_image: addedNetwork.profile_image,
            social_media: addedNetwork.service_name
          },
        });
        if (googleMyBusinessConditionRef && googleMyBusinessConditionRef.current) {
          googleMyBusinessConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.gbusiness_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.PINTEREST:
        newFormData.scheduled_event_requests_data.pinterest_requests_data.push({
          ...commonData,
          title: '',
          board_id: '',
          link: '',
          cover_image_url: '',
          post_type: PostUtil.PostType.POST,
          connected_profile_details: {
            connected_profile_id: addedNetwork.id,
            connected_profile_name: addedNetwork.profile_name,
            connected_profile_image: addedNetwork.profile_image,
            social_media: addedNetwork.service_name
          },
        });
        if (pinterestConditionRef && pinterestConditionRef.current) {
          pinterestConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.pinterest_requests_data
          );
        }
        break;
      default:
        break;
    }
    //Preview Ref
    if (previewRef && previewRef.current) {
      let fData = JSON.parse(JSON.stringify(newFormData));
      previewRef.current.handlePreviewData(fData);
    }
    setNewPostForm({ ...newFormData });
  };

  const handleDeleteAllNetworks = () => {
    let newFormData = { ...newPostForm };
    newFormData.connected_profile_ids = [];
    const platforms = [
      'facebook_requests_data',
      'instagram_requests_data',
      'twitter_requests_data',
      'youtube_requests_data',
      'linkedin_requests_data',
      'tiktok_requests_data',
      'gbusiness_requests_data',
      'pinterest_requests_data',
    ];
    platforms.forEach(platform => {
      newFormData.scheduled_event_requests_data[platform] = [];
    });
    facebookConditionRef?.current?.handleRequestData([]);
    instagramConditionRef?.current?.handleRequestData([]);
    twitterConditionRef?.current?.handleRequestData([]);
    youtubeConditionRef?.current?.handleRequestData([]);
    linkedInConditionRef?.current?.handleRequestData([]);
    tiktokConditionRef?.current?.handleRequestData([]);
    googleMyBusinessConditionRef?.current?.handleRequestData([]);
    pinterestConditionRef?.current?.handleRequestData([]);
    previewRef?.current?.handlePreviewData(JSON.parse(JSON.stringify(newFormData)));

    setNewPostForm({ ...newFormData });
  };
  const handleDeleteNetwork = (deletedNetwork) => {
    let newFormData = { ...newPostForm };
    newFormData.connected_profile_ids = newFormData.connected_profile_ids.filter((id) => id !== deletedNetwork.id);
    switch (deletedNetwork.service_name.toUpperCase()) {
      case PostUtil.ServiceName.FACEBOOK:
        newFormData.scheduled_event_requests_data.facebook_requests_data =
          newFormData.scheduled_event_requests_data.facebook_requests_data.filter(
            (data) => data.connected_profile_id !== deletedNetwork.id
          );
        if (facebookConditionRef && facebookConditionRef.current) {
          facebookConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.facebook_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.INSTAGRAM:
        newFormData.scheduled_event_requests_data.instagram_requests_data =
          newFormData.scheduled_event_requests_data.instagram_requests_data.filter(
            (data) => data.connected_profile_id !== deletedNetwork.id
          );
        if (instagramConditionRef && instagramConditionRef.current) {
          instagramConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.instagram_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.TWITTER:
        newFormData.scheduled_event_requests_data.twitter_requests_data =
          newFormData.scheduled_event_requests_data.twitter_requests_data.filter(
            (data) => data.connected_profile_id !== deletedNetwork.id
          );
        if (twitterConditionRef && twitterConditionRef.current) {
          twitterConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.twitter_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.YOUTUBE:
        newFormData.scheduled_event_requests_data.youtube_requests_data =
          newFormData.scheduled_event_requests_data.youtube_requests_data.filter(
            (data) => data.connected_profile_id !== deletedNetwork.id
          );
        if (youtubeConditionRef && youtubeConditionRef.current) {
          youtubeConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.youtube_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.LINKEDIN:
        newFormData.scheduled_event_requests_data.linkedin_requests_data =
          newFormData.scheduled_event_requests_data.linkedin_requests_data.filter(
            (data) => data.connected_profile_id !== deletedNetwork.id
          );
        if (linkedInConditionRef && linkedInConditionRef.current) {
          linkedInConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.linkedin_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.TIKTOK:
        newFormData.scheduled_event_requests_data.tiktok_requests_data =
          newFormData.scheduled_event_requests_data.tiktok_requests_data.filter(
            (data) => data.connected_profile_id !== deletedNetwork.id
          );
        if (tiktokConditionRef && tiktokConditionRef.current) {
          tiktokConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.tiktok_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.GBUSINESS:
        newFormData.scheduled_event_requests_data.gbusiness_requests_data =
          newFormData.scheduled_event_requests_data.gbusiness_requests_data.filter(
            (data) => data.connected_profile_id !== deletedNetwork.id
          );
        if (googleMyBusinessConditionRef && googleMyBusinessConditionRef.current) {
          googleMyBusinessConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.gbusiness_requests_data
          );
        }
        break;
      case PostUtil.ServiceName.PINTEREST:
        newFormData.scheduled_event_requests_data.pinterest_requests_data =
          newFormData.scheduled_event_requests_data.pinterest_requests_data.filter(
            (data) => data.connected_profile_id !== deletedNetwork.id
          );
        if (pinterestConditionRef && pinterestConditionRef.current) {
          pinterestConditionRef.current.handleRequestData(
            newFormData.scheduled_event_requests_data.pinterest_requests_data
          );
        }
        break;
      default:
        break;
    }
    //Preview Ref
    if (previewRef && previewRef.current) {
      let fData = JSON.parse(JSON.stringify(newFormData));
      previewRef.current.handlePreviewData(fData);
    }
    setNewPostForm({ ...newFormData });
  };

  const handleAddAllDescription = (description) => {
    let newFormData = { ...newPostForm };
    newFormData.description = description;
    let facebookRequestData = newFormData.scheduled_event_requests_data.facebook_requests_data;
    let instagramRequestData = newFormData.scheduled_event_requests_data.instagram_requests_data;
    let youtubeRequestData = newFormData.scheduled_event_requests_data.youtube_requests_data;
    let twitterRequestData = newFormData.scheduled_event_requests_data.twitter_requests_data;
    let linkedinRequestData = newFormData.scheduled_event_requests_data.linkedin_requests_data;
    let tiktokRequestsData = newFormData.scheduled_event_requests_data.tiktok_requests_data;
    let gbusinessRequestData = newFormData.scheduled_event_requests_data.gbusiness_requests_data;
    let pinterestRequestData = newFormData.scheduled_event_requests_data.pinterest_requests_data;
    if (facebookRequestData?.length > 0) {
      facebookRequestData.map((request) => {
        request.description = newFormData.description;
      });
      if (facebookConditionRef && facebookConditionRef.current) {
        facebookConditionRef.current.handleRequestData(facebookRequestData);
      }
    }
    if (instagramRequestData?.length > 0) {
      instagramRequestData.map((request) => {
        request.description = newFormData.description;
      });
      if (instagramConditionRef && instagramConditionRef.current) {
        instagramConditionRef.current.handleRequestData(instagramRequestData);
      }
    }
    if (youtubeRequestData?.length > 0) {
      youtubeRequestData.map((request) => {
        request.description = newFormData.description;
      });
      if (youtubeConditionRef && youtubeConditionRef.current) {
        youtubeConditionRef.current.handleRequestData(youtubeRequestData);
      }
    }
    if (twitterRequestData?.length > 0) {
      twitterRequestData.map((request) => {
        request.description = newFormData.description;
      });
      if (twitterConditionRef && twitterConditionRef.current) {
        twitterConditionRef.current.handleRequestData(twitterRequestData);
      }
    }
    if (linkedinRequestData?.length > 0) {
      linkedinRequestData.map((request) => {
        request.description = newFormData.description;
      });
      if (linkedInConditionRef && linkedInConditionRef.current) {
        linkedInConditionRef.current.handleRequestData(linkedinRequestData);
      }
    }
    if (pinterestRequestData?.length > 0) {
      pinterestRequestData.map((request) => {
        request.description = newFormData.description;
      });
      if (pinterestConditionRef && pinterestConditionRef.current) {
        pinterestConditionRef.current.handleRequestData(pinterestRequestData);
      }
    }
    if (tiktokRequestsData?.length > 0) {
      tiktokRequestsData.map((request) => {
        request.description = newFormData.description;
      });
      if (tiktokConditionRef && tiktokConditionRef.current) {
        tiktokConditionRef.current.handleRequestData(tiktokRequestsData);
      }
    }
    if (gbusinessRequestData?.length > 0) {
      gbusinessRequestData.map((request) => {
        request.description = newFormData.description;
      });
      if (googleMyBusinessConditionRef && googleMyBusinessConditionRef.current) {
        googleMyBusinessConditionRef.current.handleRequestData(gbusinessRequestData);
      }
    }
    //Preview Ref
    if (previewRef && previewRef.current) {
      let fData = JSON.parse(JSON.stringify(newFormData));
      previewRef.current.handlePreviewData(fData);
    }
    setNewPostForm(newFormData);
  };

  const handleAddAllCommentDescription = (commentDescription) => {
    let newFormData = { ...newPostForm };
    newFormData.comment_description = commentDescription;
    let facebookRequestData = newFormData.scheduled_event_requests_data.facebook_requests_data;
    let instagramRequestData = newFormData.scheduled_event_requests_data.instagram_requests_data;
    let youtubeRequestData = newFormData.scheduled_event_requests_data.youtube_requests_data;
    let twitterRequestData = newFormData.scheduled_event_requests_data.twitter_requests_data;
    let linkedinRequestData = newFormData.scheduled_event_requests_data.linkedin_requests_data;
    let gbusinessRequestData = newFormData.scheduled_event_requests_data.gbusiness_requests_data;
    if (facebookRequestData?.length > 0) {
      facebookRequestData.map((request) => {
        request.comment_description = newFormData.comment_description;
        request.has_comment = request.comment_description != "" ? true : false;
      });
      if (facebookConditionRef && facebookConditionRef.current) {
        facebookConditionRef.current.handleRequestData(facebookRequestData);
      }
    }
    if (instagramRequestData?.length > 0) {
      instagramRequestData.map((request) => {
        request.comment_description = newFormData.comment_description;
        request.has_comment = request.comment_description != "" ? true : false;
      });
      if (instagramConditionRef && instagramConditionRef.current) {
        instagramConditionRef.current.handleRequestData(instagramRequestData);
      }
    }
    if (youtubeRequestData?.length > 0) {
      youtubeRequestData.map((request) => {
        request.comment_description = newFormData.comment_description;
        request.has_comment = request.comment_description != "" ? true : false;
      });
      if (youtubeConditionRef && youtubeConditionRef.current) {
        youtubeConditionRef.current.handleRequestData(youtubeRequestData);
      }
    }
    if (twitterRequestData?.length > 0) {
      twitterRequestData.map((request) => {
        request.comment_description = newFormData.comment_description;
      });
      if (twitterConditionRef && twitterConditionRef.current) {
        twitterConditionRef.current.handleRequestData(twitterRequestData);
      }
    }
    if (linkedinRequestData?.length > 0) {
      linkedinRequestData.map((request) => {
        request.comment_description = newFormData.comment_description;
        request.has_comment = request.comment_description != "" ? true : false;
      });
      if (linkedInConditionRef && linkedInConditionRef.current) {
        linkedInConditionRef.current.handleRequestData(linkedinRequestData);
      }
    }
    if (gbusinessRequestData?.length > 0) {
      gbusinessRequestData.map((request) => {
        request.comment_description = newFormData.comment_description;
      });
      if (googleMyBusinessConditionRef && googleMyBusinessConditionRef.current) {
        googleMyBusinessConditionRef.current.handleRequestData(gbusinessRequestData);
      }
    }
    //Preview Ref
    if (previewRef && previewRef.current) {
      let fData = JSON.parse(JSON.stringify(newFormData));
      previewRef.current.handlePreviewData(fData);
    }
    setNewPostForm(newFormData);
  };

  const handleMediaFiles = (mediaFiles) => {
    let newFormData = { ...newPostForm };
    newFormData.media_url = mediaFiles.map((a) => a.media_url).join();
    newFormData.media_data = mediaFiles;
    let facebookRequestData = newFormData.scheduled_event_requests_data.facebook_requests_data;
    let instagramRequestData = newFormData.scheduled_event_requests_data.instagram_requests_data;
    let youtubeRequestData = newFormData.scheduled_event_requests_data.youtube_requests_data;
    let twitterRequestData = newFormData.scheduled_event_requests_data.twitter_requests_data;
    let linkedinRequestData = newFormData.scheduled_event_requests_data.linkedin_requests_data;
    let tiktokRequestsData = newFormData.scheduled_event_requests_data.tiktok_requests_data;
    let gbusinessRequestData = newFormData.scheduled_event_requests_data.gbusiness_requests_data;
    let pinterestRequestData = newFormData.scheduled_event_requests_data.pinterest_requests_data;
    if (facebookRequestData?.length > 0) {
      facebookRequestData.map((c) => {
        c.media_url = newFormData.media_url;
        c.media_data = mediaFiles;
      });
      if (facebookConditionRef && facebookConditionRef.current) {
        facebookConditionRef.current.handleRequestData(facebookRequestData);
      }
    }
    if (instagramRequestData?.length > 0) {
      instagramRequestData.map((c) => {
        c.media_url = newFormData.media_url;
        c.media_data = mediaFiles;
      });
      if (instagramConditionRef && instagramConditionRef.current) {
        instagramConditionRef.current.handleRequestData(instagramRequestData);
      }
    }
    if (youtubeRequestData?.length > 0) {
      youtubeRequestData.map((c) => {
        c.media_url = newFormData.media_url;
        c.media_data = mediaFiles;
      });
      if (youtubeConditionRef && youtubeConditionRef.current) {
        youtubeConditionRef.current.handleRequestData(youtubeRequestData);
      }
    }
    if (twitterRequestData?.length > 0) {
      twitterRequestData.map((c) => {
        c.media_url = newFormData.media_url;
        c.media_data = mediaFiles;
      });
      if (twitterConditionRef && twitterConditionRef.current) {
        twitterConditionRef.current.handleRequestData(twitterRequestData);
      }
    }
    if (pinterestRequestData?.length > 0) {
      pinterestRequestData.map((c) => {
        c.media_url = newFormData.media_url;
        c.media_data = mediaFiles;
      });
      if (pinterestConditionRef && pinterestConditionRef.current) {
        pinterestConditionRef.current.handleRequestData(pinterestRequestData);
      }
    }
    if (linkedinRequestData?.length > 0) {
      linkedinRequestData.map((c) => {
        c.media_url = newFormData.media_url;
        c.media_data = mediaFiles;
      });
      if (linkedInConditionRef && linkedInConditionRef.current) {
        linkedInConditionRef.current.handleRequestData(linkedinRequestData);
      }
    }
    if (tiktokRequestsData?.length > 0) {
      tiktokRequestsData.map((c) => {
        c.media_url = newFormData.media_url;
        c.media_data = mediaFiles;
      });
      if (tiktokConditionRef && tiktokConditionRef.current) {
        tiktokConditionRef.current.handleRequestData(tiktokRequestsData);
      }
    }
    if (gbusinessRequestData?.length > 0) {
      gbusinessRequestData.map((c) => {
        c.media_url = newFormData.media_url;
        c.media_data = mediaFiles;
      });
      if (googleMyBusinessConditionRef && googleMyBusinessConditionRef.current) {
        googleMyBusinessConditionRef.current.handleRequestData(gbusinessRequestData);
      }
    }

    //Preview Ref
    if (previewRef && previewRef.current) {
      let fData = JSON.parse(JSON.stringify(newFormData));
      previewRef.current.handlePreviewData(fData);
    }
    setNewPostForm({ ...newFormData });
  };

  const updateRequestData = (data, serviceName) => {
    let newFormData = { ...newPostForm };
    switch (serviceName) {
      case PostUtil.ServiceName.FACEBOOK:
        newFormData.scheduled_event_requests_data.facebook_requests_data = [...data];
        break;
      case PostUtil.ServiceName.INSTAGRAM:
        newFormData.scheduled_event_requests_data.instagram_requests_data = [...data];
        break;
      case PostUtil.ServiceName.TWITTER:
        newFormData.scheduled_event_requests_data.twitter_requests_data = [...data];
        break;
      case PostUtil.ServiceName.LINKEDIN:
        newFormData.scheduled_event_requests_data.linkedin_requests_data = [...data];
        break;
      case PostUtil.ServiceName.YOUTUBE:
        newFormData.scheduled_event_requests_data.youtube_requests_data = [...data];
        break;
      case PostUtil.ServiceName.GBUSINESS:
        newFormData.scheduled_event_requests_data.gbusiness_requests_data = [...data];
        break;
      case PostUtil.ServiceName.PINTEREST:
        newFormData.scheduled_event_requests_data.pinterest_requests_data = [...data];
        break;
      case PostUtil.ServiceName.TIKTOK:
        newFormData.scheduled_event_requests_data.tiktok_requests_data = [...data];
        break;
      default:
        break;
    }
    setNewPostForm({ ...newFormData });
  };

  const handleConditionErrors = (postForm, serviceName) => {
    switch (serviceName) {
      case PostUtil.ServiceName.FACEBOOK:
        facebookConditionRef.current.checkForErrors();
        break;
      case PostUtil.ServiceName.INSTAGRAM:
        instagramConditionRef.current.checkForErrors();
        break;
      case PostUtil.ServiceName.TWITTER:
        twitterConditionRef.current.checkForErrors();
        break;
      case PostUtil.ServiceName.YOUTUBE:
        youtubeConditionRef.current.checkForErrors();
        break;
      case PostUtil.ServiceName.GBUSINESS:
        googleMyBusinessConditionRef.current.checkForErrors();
        break;
      default:
        break;
    }
    setNewPostForm({ ...postForm });
  };
  const saveDraftSuccessCallback = (data, message) => {
    message && showToast(message, 's');
    // setStateToInitialStage();
    setDraftSubmitting(false);
  };
  const handleSaveDraft = () => {
    setDraftSubmitting(true);
    let bodyprops = getFormDataProps();
    let postProps = {
      url: postsModuleURL.saveDraft,
      body: bodyprops,
      successCallback: saveDraftSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const getFormDataProps = () => {
    let postDateTime = new Date();

    if ((whenToPost === PostOption.SchedulePost.Name) || (whenToPost === PostOption.ApprovalPost.Name)) {
      postDateTime = new Date(scheduleDateTime); // Convert to Date object
    }

    const mediaUrls = newPostForm.media_data.map((file) => file.media_url);
    const now = new Date();

    // Normalize to only date and minutes for comparison
    const normalizeDateTime = (date) => new Date(date.setSeconds(0, 0));

    const normalizedPostDateTime = normalizeDateTime(new Date(postDateTime));
    const normalizedNow = normalizeDateTime(new Date(now));

    // Check if the normalized date and minutes are the same
    const isPostDateTimeNow = normalizedPostDateTime.getTime() === normalizedNow.getTime();

    const adjustedPostDateTime = !hasApproverPermission && isPostDateTimeNow
      ? new Date(normalizedPostDateTime.getTime() + 5 * 60000) // Add 5 minutes
      : postDateTime;
    const formbody = {
      scheduled_event_requests_data: newPostForm.scheduled_event_requests_data,
      scheduled_time: adjustedPostDateTime,
      description: newPostForm.description,
      comment_description: newPostForm.comment_description,
      media_url: mediaUrls,
      is_file_upload: true,
      is_scheduled_post: scheduleId ? true : false,
      schedule_id: scheduleId ? scheduleId.toString() : undefined,
      is_approval : whenToPost == PostOption.ApprovalPost.Name
    }
    return scheduleId
      ? {
        schedule_id: scheduleId,
        new_post_datas: formbody
      }
      : formbody;
  };

  const handleScheduleDate = (date) => {
    setScheduleDateTime(date);
  };
  const handlePostTypeChange = (event) => {
    let isChecked = event.target.checked;
    let postOption = isChecked ? PostOption.SchedulePost.Name : PostOption.PostNow.Name;
    setWhenToPost(postOption);
  };
  const handleSelectPostTypeChange = (value) => {
    setWhenToPost(value);
  };
  const newPostSuccessCallback = (data, message) => {
    showToast(message, 's');
    setLoader(false);
    setPostSubmitting(false);
    handleDeleteAllNetworks();
    setStateToInitialStage();
  };
  const handleFormsubmit = async () => {
    if (selectedSocialMedias && selectedSocialMedias.length > 0) {
      // Temporarily switch to the conditions tab to validate
      setTabValue(1);
      await new Promise((resolve) => setTimeout(resolve, 100));
      let isValidForm = checkFormIsValid();
      if (isValidForm) {
        setPostSubmitting(true);
        let formBody = getFormDataProps();
        let postProps = {
          url: scheduleId ? postsModuleURL.updateScheduledPost : postsModuleURL.uploadPosts,
          body: formBody,
          successCallback: newPostSuccessCallback,
          failureCallback,
        };
        HttpServices.Post(postProps);
      } else {
        showToast('Please fix all the validations to post', 'e');
      }
    } else {
      showToast('Please fix all the validations to post', 'e');
    }
  };

  const checkFormIsValid = () => {
    let errorCount = 0;
    const { scheduled_event_requests_data } = newPostForm;
    const validateServiceData = (serviceData) => {
      serviceData.forEach((data) => {
        if (data.errors && data.errors.length > 0) {
          errorCount += data.errors.length;
        }
      });
    };
    if (scheduled_event_requests_data.facebook_requests_data.length > 0 && facebookConditionRef && facebookConditionRef.current) {
      facebookConditionRef.current.checkForErrors();
      validateServiceData(scheduled_event_requests_data.facebook_requests_data);
    }
    if (scheduled_event_requests_data.instagram_requests_data.length > 0 && instagramConditionRef && instagramConditionRef.current) {
      instagramConditionRef.current.checkForErrors();
      validateServiceData(scheduled_event_requests_data.instagram_requests_data);
    }
    if (scheduled_event_requests_data.twitter_requests_data.length > 0 && twitterConditionRef && twitterConditionRef.current) {
      twitterConditionRef.current.checkForErrors();
      validateServiceData(scheduled_event_requests_data.twitter_requests_data);
    }
    if (scheduled_event_requests_data.youtube_requests_data.length > 0 && youtubeConditionRef && youtubeConditionRef.current) {
      youtubeConditionRef.current.checkForErrors();
      validateServiceData(scheduled_event_requests_data.youtube_requests_data);
    }
    if (scheduled_event_requests_data.linkedin_requests_data.length > 0 && linkedInConditionRef && linkedInConditionRef.current) {
      linkedInConditionRef.current.checkForErrors();
      validateServiceData(scheduled_event_requests_data.linkedin_requests_data);
    }
    if (scheduled_event_requests_data.gbusiness_requests_data.length > 0 && googleMyBusinessConditionRef && googleMyBusinessConditionRef.current) {
      googleMyBusinessConditionRef.current.checkForErrors();
      validateServiceData(scheduled_event_requests_data.gbusiness_requests_data);
    }
    if (scheduled_event_requests_data.pinterest_requests_data.length > 0 && pinterestConditionRef && pinterestConditionRef.current) {
      pinterestConditionRef.current.checkForErrors();
      validateServiceData(scheduled_event_requests_data.pinterest_requests_data);
    }
    if (scheduled_event_requests_data.tiktok_requests_data.length > 0 && tiktokConditionRef && tiktokConditionRef.current) {
      tiktokConditionRef.current.checkForErrors();
      validateServiceData(scheduled_event_requests_data.tiktok_requests_data);
    }
    // if(scheduled_event_requests_data.tiktok_requests_data.length>0){
    //   facebookConditionRef.current.checkForErrors();
    //   validateServiceData(scheduled_event_requests_data.tiktok_requests_data);
    // }
    return errorCount === 0;
  };

  return (
    <>
      <Helmet>
        <title> Create New Post </title>
      </Helmet>
      {loader ? (
        <Spinner />
      ) :
        isMobile ?
          <MobileNewPostComponent configuredSocialMedia={configuredSocialMedia}
            selectedSocialMedias={selectedSocialMedias}
            handleNetworks={handleNetworks}
            newPostForm={newPostForm}
            handleAddAllDescription={handleAddAllDescription}
            handleAddAllCommentDescription={handleAddAllCommentDescription}
            handleMediaFiles={handleMediaFiles}
            tabValue={tabValue}
            setTabValue={setTabValue}
            updateRequestData={updateRequestData}
            googleMyBusinessConditionRef={googleMyBusinessConditionRef}
            tiktokConditionRef={tiktokConditionRef}
            linkedInConditionRef={linkedInConditionRef}
            youtubeConditionRef={youtubeConditionRef}
            twitterConditionRef={twitterConditionRef}
            instagramConditionRef={instagramConditionRef}
            facebookConditionRef={facebookConditionRef}
            pinterestConditionRef={pinterestConditionRef}
            previewRef={previewRef}
            handleSaveDraft={handleSaveDraft}
            isSubmittingDraft={isSubmittingDraft}
            handleScheduleDate={handleScheduleDate}
            scheduleDateTime={scheduleDateTime}
            whenToPost={whenToPost}
            PostOption={PostOption}
            handlePostTypeChange={handlePostTypeChange}
            isSubmitting={isSubmitting}
            handleFormsubmit={handleFormsubmit}
            hasApproverPermission={hasApproverPermission}
            handleSelectPostTypeChange={handleSelectPostTypeChange}
          />
          :
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sm={12} style={{ paddingBottom: '12px' }}>
              <PostContentComponent
                configuredSocialMedia={configuredSocialMedia}
                selectedSocialMedias={selectedSocialMedias}
                handleNetworks={handleNetworks}
                newPostForm={newPostForm}
                handleAddAllDescription={handleAddAllDescription}
                handleAddAllCommentDescription={handleAddAllCommentDescription}
                handleMediaFiles={handleMediaFiles}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingBottom: '12px' }}>
              <PostTabComponent
                tabValue={tabValue}
                setTabValue={setTabValue}
                updateRequestData={updateRequestData}
                googleMyBusinessConditionRef={googleMyBusinessConditionRef}
                tiktokConditionRef={tiktokConditionRef}
                linkedInConditionRef={linkedInConditionRef}
                youtubeConditionRef={youtubeConditionRef}
                twitterConditionRef={twitterConditionRef}
                instagramConditionRef={instagramConditionRef}
                facebookConditionRef={facebookConditionRef}
                pinterestConditionRef={pinterestConditionRef}
                previewRef={previewRef}
                newPostForm={newPostForm}
              />
            </Grid>
            <PostFooter
              handleSaveDraft={handleSaveDraft}
              isSubmittingDraft={isSubmittingDraft}
              handleScheduleDate={handleScheduleDate}
              scheduleDateTime={scheduleDateTime}
              whenToPost={whenToPost}
              PostOption={PostOption}
              hasApproverPermission={hasApproverPermission}
              handlePostTypeChange={handlePostTypeChange}
              handleSelectPostTypeChange={handleSelectPostTypeChange}
              isSubmitting={isSubmitting}
              handleFormsubmit={handleFormsubmit}
            />
          </Grid>
      }
    </>
  );
};
export default NewPostComponent;

import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, CardContent, ListItemIcon,Paper  } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import VerifiedUserIcon from  '@mui/icons-material/VerifiedUser';

const FacebookPageAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);

    const handleConnectFacebook = () => {
        setLoadingOn(true);
        props.handleConnectFacebook();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Typography gutterBottom variant="h4">
                        Have you converted your Facebook account to a Business Profile?
                    </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CardContent
                    sx={{
                        p: { xs: 0, md: 0 },
                        color: 'grey.800',
                    }}
                >
                    <Typography variant="body1" sx={{ margin: 'auto 0', fontWeight: 'bold' }}>
                        Make Sure you've completed these steps before continuing:
                    </Typography>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, mt: 2 }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                        You’ve converted your profile to a Business Profile in the Facebook application.
                        </Typography>
                    </Paper>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, }}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                        You’re an Admin on your Facebook Page.
                        </Typography>
                    </Paper>
                    <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1,}}>
                        <ListItemIcon>
                            <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                        </ListItemIcon>
                        <Typography variant="body1">
                        Please create a Facebook page to connect.
                        </Typography>
                    </Paper>
                    
                </CardContent>
            </DialogContent>
            <DialogActions>
                <Button
                color='error'
                    variant="outlined"
                    onClick={handleClose}
                >
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectFacebook();
                    }}
                >
                    Connect
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default FacebookPageAuthorizationModal;

import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';

const TableDonutChart = ({ title, labels, value, engagementPostType }) => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'donut',
    },
    labels: labels, // Labels for the slices
    plotOptions: {
      pie: {
        customScale: 1.1, // Increased scaling of the pie for a bigger chart
        donut: {
          size: '75%', // Increased the size of the inner donut
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              fontWeight: '600',
              color: '#373d3f',
            },
            value: {
              show: true,
              fontSize: '10px',
              fontWeight: '200',
              color: '#373d3f',
              formatter: (val) => `${val}%`, // Custom formatter for values
            },
          },
        },
        expandOnClick: false, // Disable slice expansion on click
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + '%'; // Display percentages
      },
    },
    legend: {
      show: true,
      position: 'bottom',
    },
  });

  const [chartSeries, setChartSeries] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

  useEffect(() => {
    if (value.every((val) => val === 0)) {
      setChartSeries([0, 100]);
    } else {
      setChartSeries(value);
    }
  }, [value]);

  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Typography variant="h5" color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between' alignItems='center'>
          {/* Updated Chart Width */}
          <Chart options={chartOptions} series={chartSeries} type="donut" width="300" /> {/* Increased chart width to 300 */}

          {/* Table for Engagement Data */}
          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell align="right">Engagement</TableCell>
                  <TableCell align="right">Percentage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {engagementPostType && engagementPostType.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{data.type}</TableCell>
                    <TableCell align="right">{data.engagement}</TableCell>
                    <TableCell align="right">{data.percentage}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TableDonutChart;

import {
    Box,
    Card,
    CardMedia,
    Grid,
    IconButton,
    Table, TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import Comments from '../../../assets/comment.svg';
import Likes from '../../../assets/fblike.svg';
import Share from '../../../assets/fbshare.svg';
import { CommonHelper } from 'src/utils/commonHelper';
import TextIconLabel from 'src/components/TextIconLabel';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const YoutubeMobileViewPosts = ({ publishedPosts, mobileLoader, getMobileViewYoutubeNextPublishedPosts, pagination }) => {
    const theme = useTheme();

    const postcontentL = 220;
    const postcontentS = 110;
    return (
        <Box sx={{
            maxHeight: 'calc(100vh - 330px)',
            minHeight: 'calc(100vh - 330px)',
        }} >{publishedPosts.map((row, index) => {
            const { createdTime, id, image_url, likeCount, description, viewCount, commentCount } = row;
            return (
                <Card key={index} sx={{ maxHeight: description.length > 25 ? postcontentL : postcontentS, display: 'flex', flexDirection: 'row', mb: 1, p: 1, gap: 1 }}>
                    {image_url && <CardMedia
                        component="img"
                        sx={{ width: 140, maxHeight: 140 }}
                        image={image_url}
                        alt="Post image"
                    />}
                    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 1, maxWidth: 200 }}>
                        <Typography variant="subtitle2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }} >{description}</Typography>
                        <Stack
                            direction="row"
                            justifyContent="space-between"  sx={{gap:1}}>
                            <TextIconLabel
                                value={likeCount}
                                aria-label="chatbubbleoutline"
                                sx={{ typography: 'subtitle2' }}
                                icon={<img src={Likes} style={{ width: 20, height: 20 }} />}
                            />
                            <TextIconLabel
                                value={commentCount}
                                aria-label="chatbubbleoutline"
                                sx={{ typography: 'subtitle2' }}
                                icon={<img src={Comments} style={{ width: 20, height: 20 }} />}
                            />
                            <TextIconLabel
                                value={viewCount}
                                aria-label="chatbubbleoutline"
                                sx={{ typography: 'subtitle2' }}
                                icon={<RemoveRedEyeIcon  sx={{ color: 'text.secondary'}} />}
                            />
                        </Stack>
                        <Typography variant="body2" sx={{ fontSize: '10px' }} color="text.secondary">
                            {CommonHelper.GetDateFormat(createdTime)}
                        </Typography>
                    </Box>
                </Card>
            );
        })}
            <Box sx={{ textAlign: 'center' }}>
                {mobileLoader ? (
                    <ThreeDotLoader />
                ) : (<>
                    {pagination && <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 700,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: theme.palette.grey[500],
                        }}
                        onClick={getMobileViewYoutubeNextPublishedPosts}
                    >
                        Load More Posts
                    </Typography>}</>
                )}
            </Box></Box>
    )

}
export default YoutubeMobileViewPosts;
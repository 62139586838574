import { Cancel, Replay } from '@mui/icons-material';
import { MenuItem, Popover } from '@mui/material';

const FailedPostNestedGridActionPopup = ({
  popoverElement,
  handleCloseMenu,
  retryNow,
}) => {
  return (
    <Popover
      open={Boolean(popoverElement)}
      anchorEl={popoverElement}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: { p: 1, width: 170, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } },
      }}
    >
      {' '}
      <MenuItem sx={{ color: 'error.main' }} onClick={retryNow}>
        <Replay sx={{ mr: 1 }} /> Retry Now{' '}
      </MenuItem>
    </Popover>
  );
};
export default FailedPostNestedGridActionPopup;

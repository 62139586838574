import ReactDOM from 'react-dom/client';
import App from './App';import TagManager from 'react-gtm-module';


const tagManagerArgs = {
  gtmId: 'AW-11309238073', // Replace with your actual GTM ID
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

// If you want to enable client cache, register instead.
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { LoadingButton } from '@mui/lab';
import { CommonHelper } from 'src/utils/commonHelper';
import _ from 'lodash';
import ConnectedMediaComponent from '../new-post-new/connected-media.component';

// Styled label component
const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

const UpdateRole = ({ handleClose, handleSuccess,updateRole }) => {
    const [role, setRole] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [connectedProfiles, setConnectedProfiles] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedSocialMedias, setSelectedSocialMedia] = useState([]);
    const [roleError, setRoleError] = useState(false);
    const [permissionsError, setPermissionsError] = useState(false);
    const [roleHelperText, setRoleHelperText] = useState('');
    const [permissionsHelperText, setPermissionsHelperText] = useState('');
    const [isLoading, setLoading] = useState(false);
    const { showToast } = useToaster();

    useEffect(() => {
        initNewRole();
    }, [])

    const initNewRole = () => {
        let getProps = {
            url: userModuleURL.newRoleInit,
            successCallback: permissionSuccessCallback,
            failureCallback,
        };
        HttpServices.Get(getProps);
    }
    const permissionSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        setPermissions(data.permission_list);
        setConnectedProfiles(data.connected_profiles);
        if (updateRole && updateRole.role_name) {
            setRole(updateRole.role_name);
        }
        if (updateRole && updateRole.role_permissions) {
            const matchedPermissions = data.permission_list.filter(permission =>
                updateRole.role_permissions.some(selectedPermission => selectedPermission.permission_id === permission.permission_id && selectedPermission.has_permission)
            );
            setSelectedPermissions(matchedPermissions);
        }
        if (updateRole && updateRole.connected_profiles) {
            const matchedProfiles = data.connected_profiles.filter(profile =>
                updateRole.connected_profiles.some(selectedProfile => selectedProfile.id === profile.id)
            );
            setSelectedSocialMedia(matchedProfiles);
        }
    };
    

    const validateForm = () => {
        let isValid = true;
        if (role.trim() === '') {
            setRoleError(true);
            setRoleHelperText('Please enter a role name.');
            isValid = false;
        } else {
            setRoleError(false);
            setRoleHelperText('');
        }

        if (selectedPermissions?.length === 0) {
            setPermissionsError(true);
            setPermissionsHelperText('At least one permission must be selected.');
            isValid = false;
        } else {
            setPermissionsError(false);
            setPermissionsHelperText('');
        }

        return isValid;
    };
    const handleCreateNewRole = () => {
        if (validateForm()) {
            setLoading(true);
            let roleProps = {
                role_id: updateRole.id,
                role_name: role,
                permission_list: selectedPermissions.map(a => a.permission_id),
                connected_profiles: selectedSocialMedias?.map(a => a.id)
            }
            let postProps = {
                url: userModuleURL.updateRole,
                body: roleProps,
                successCallback,
                failureCallback,
            };
            HttpServices.Post(postProps);
        }
    }
    const successCallback = (data, message) => {
        setLoading(false);
        handleSuccess(message);
    }
    const failureCallback = (message) => {
        showToast(message, 'e')
    }
    const handleChangeRole = (event) => {
        setRole(event.target.value);
        if (roleError) {
            validateForm();
        }
    };
    const handleTogglePermission = (permission) => {
        let sPermissions = [...selectedPermissions];
        let sSelectedSocialMedia = [...selectedSocialMedias]
        let index = sPermissions.findIndex(a => a.permission_id === permission.permission_id);
        if (index >= 0) {
            sPermissions.splice(index, 1);
            if (permission.permission_name === CommonHelper.PermissionList.PUBLISH_PERMISSION
                || permission.permission_name === CommonHelper.PermissionList.ALL_CHANNEL_ACCESS) {
                let aIndex = sPermissions.findIndex(a => a.permission_name === CommonHelper.PermissionList.APPROVER);
                if (permission.permission_name === CommonHelper.PermissionList.ALL_CHANNEL_ACCESS) {
                    sSelectedSocialMedia = [];
                }
                if (aIndex >= 0)
                    sPermissions.splice(aIndex, 1);
            }
            if (permission.permission_name === CommonHelper.PermissionList.ALL_CHANNEL_ACCESS) {
                let aIndex = sPermissions.findIndex(a => a.permission_name === CommonHelper.PermissionList.INTEGRATE_PERMISSION);
                if (aIndex >= 0)
                    sPermissions.splice(aIndex, 1);
            }
        }
        else {
            if (permission.permission_name === CommonHelper.PermissionList.ALL_CHANNEL_ACCESS) {
                sPermissions.push(permission);
                sSelectedSocialMedia = [...connectedProfiles]
            }
            else
                sPermissions.push(permission);
        }
        setSelectedPermissions(sPermissions);
        setSelectedSocialMedia(sSelectedSocialMedia);
        if (permissionsError)
            validateForm();
    }

    const isSwitchChecked = (permission) => {
        return selectedPermissions?.some(a => a.permission_id === permission.permission_id)
    }
    const checkSwitchIsDisabled = (permission) => {
        if (!permission.has_permission)
            return true;
        if (permission.permission_name === CommonHelper.PermissionList.READ_POST) {
            return true
        }
        const hasAllChannelAccessPermission = selectedPermissions.some(permission =>
            permission.permission_name === CommonHelper.PermissionList.ALL_CHANNEL_ACCESS
        );
        if (permission.permission_name === CommonHelper.PermissionList.APPROVER) {
            const hasPublishPermission = selectedPermissions.some(permission =>
                permission.permission_name === CommonHelper.PermissionList.PUBLISH_PERMISSION
            );
            if (hasPublishPermission && hasAllChannelAccessPermission) {
                return false
            } else {
                return true
            }
        }
        if (permission.permission_name === CommonHelper.PermissionList.INTEGRATE_PERMISSION) {
            if (hasAllChannelAccessPermission) {
                return false
            } else {
                return true
            }
        }
        return false
    }
    const handleNetworks = (selectedSocialMedia) => {
        setSelectedSocialMedia(selectedSocialMedia);
    };
    const checkConnectedMediaIsEnable = () => {
        return selectedPermissions.some(a => (a.permission_name === CommonHelper.PermissionList.ORG_ADMIN)
            || (a.permission_name === CommonHelper.PermissionList.BRAND_ADMIN)
            || (a.permission_name === CommonHelper.PermissionList.ALL_CHANNEL_ACCESS));
    }
    const connectedMediaProps = { configuredSocialMedia: connectedProfiles, selectedSocialMedias, handleNetworks, handleDisabled: checkConnectedMediaIsEnable }
    return (
        <Dialog open maxWidth="md">
            <DialogContent dividers>
                <Typography variant='h4' gutterBottom component="div">
                    Update Role
                </Typography>
                <Box component="form" noValidate autoComplete="off" sx={{ p: 2, minWidth: 300 }}>
                    <FormControl fullWidth margin="normal" error={roleError}>
                        <LabelStyle>Role</LabelStyle>
                        <TextField
                            placeholder='Role Name'
                            value={role}
                            onChange={handleChangeRole}
                            helperText={roleHelperText}
                            error={roleError}
                        />
                    </FormControl>
                    {connectedProfiles?.length > 0 && <LabelStyle>Connected Profiles</LabelStyle>}
                    {connectedProfiles?.length > 0 && <ConnectedMediaComponent {...connectedMediaProps} />}
                    <FormControl component="fieldset" fullWidth margin="normal" error={permissionsError}>
                        <LabelStyle>Permissions</LabelStyle>
                        <FormGroup>
                            <Grid container spacing={2}>
                                {permissions.map((permission) => {

                                    return <Grid item xs={12} md={6} key={permission.permission_id}>
                                        <FormControlLabel
                                            control={
                                                <StyledComponent.SwitchIcon sx={{ m: 1 }}
                                                    checked={isSwitchChecked(permission)}
                                                    onChange={() => handleTogglePermission(permission)}
                                                    name={permission.permission_display_name}
                                                    disabled={checkSwitchIsDisabled(permission)}
                                                />
                                            }
                                            label={permission.permission_display_name}
                                        />
                                    </Grid>
                                })}
                            </Grid>
                        </FormGroup>
                        {permissionsError && <Typography color="error">{permissionsHelperText}</Typography>}
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color='error' onClick={handleClose}>
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    onClick={handleCreateNewRole}
                >
                    Update Role
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default UpdateRole;

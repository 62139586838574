import CalendarGridMonthView from "./calendar-grid-month-view.component";
import CalendarGridWeekView from "./calendar-grid-week-view.component";
import CalendarGridDayView from "./calendar-grid-day-view.component";
import CalendarEventsView from "./calendar-grid-events-view.component";
import { CalendarUtil } from "../calendarUtil";
import { useEffect, useRef } from "react";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { useTheme } from '@mui/material/styles';

const CalendarGridView = ({ view, event, eventHeight }) => {
    const theme = useTheme();
    const eventRef = useRef(null);

    useEffect(() => {
        if (eventRef.current) {
            eventRef.current.style.height = `${eventHeight}px`;
        }
    }, [eventHeight]);
    return (
        <Scrollbar>
            <div ref={eventRef} className="fc-timegrid-event" style={{
                width: '100%', 
            }}>
                {(() => {
                    switch (view) {
                        case CalendarUtil.CalenderType.DayMonth:
                            return <CalendarGridMonthView event={event} sx={{ width: '100%' }} />;
                        // case CalendarUtil.CalenderType.TimeGridWeek:
                        //     return <CalendarGridWeekView event={event} />;
                        // case CalendarUtil.CalenderType.TimeGridDay:
                        //     return <CalendarGridDayView event={event} />;
                        case CalendarUtil.CalenderType.ListWeek:
                            return <CalendarEventsView event={event} sx={{ width: '100%' }} />;
                        default:
                            return null;
                    }
                })()}
            </div>
        </Scrollbar>
    );
};


export default CalendarGridView;
import { useEffect } from "react";
import { useState } from "react";
import LinkedinPostVideoPreview from "./linkedin-post-video-preview.component";
import LinkedinPostImagesPreview from "./linkedin-post-images-preview.component";
import { PostUtil } from "src/pages/new-post-new/post-util";

const LinkedinPostPreview = ({ data }) => {
    const [requestData, setRequestData] = useState({});
    const [isVideo, setIsVideo] = useState(false);
    useEffect(() => {
        if (!data) return;
        if (data?.media_data?.count == 1 && data?.media_data.media_data[0].type === PostUtil.MediaType.VIDEO) {
            setIsVideo(true)
        }

    }, [data]);
    return (
        <>
            {isVideo ?
                <LinkedinPostVideoPreview data={data}> </LinkedinPostVideoPreview>
                :
                <LinkedinPostImagesPreview data={data}></LinkedinPostImagesPreview>}

        </>
    );
};

export default LinkedinPostPreview;

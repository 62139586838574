import { useEffect, useRef } from 'react';

const useThrottle = (callback, delay) => {
  const lastCall = useRef(0);
  const lastArgs = useRef(null);

  const throttled = (...args) => {
    const now = new Date().getTime();
    if (now - lastCall.current >= delay) {
      lastCall.current = now;
      callback(...args);
    } else {
      lastArgs.current = args;
    }
  };

  useEffect(() => {
    if (lastArgs.current) {
      const handler = setTimeout(() => {
        callback(...lastArgs.current);
        lastArgs.current = null;
      }, delay - (new Date().getTime() - lastCall.current));

      return () => clearTimeout(handler);
    }
  }, [callback, delay]);

  return throttled;
};

export default useThrottle;

import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; // Icon for percentage increase
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // Icon for percentage decrease

const SummaryCommonCard = ({ title, data, percentage, percentageState, averageData }) => {
  const isPositive = percentageState !== 'down'; // Assuming 'up' or 'down' for percentage state

  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Grid container spacing={2}>
          {/* Card Title */}
          <Grid item xs={12}>
            <Typography variant="h5" color="textSecondary" gutterBottom>
              {title}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="h2" color="primary" sx={{ marginRight: 1 }}>
                {data || 0}
              </Typography>
              {percentage && (
                <Box display="flex" alignItems="center">
                  {isPositive ? (
                    <ArrowUpwardIcon style={{ color: 'green', fontSize: 24 }} />
                  ) : (
                    <ArrowDownwardIcon style={{ color: 'red', fontSize: 24 }} />
                  )}
                  <Typography variant="h5" sx={{ color: isPositive ? 'green' : 'red', marginLeft: 0.5 }}>
                    {percentage}%
                  </Typography>
                </Box>
              )}
            </Box>
            {averageData && (
              <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1 }}>
                Avg. per day: {averageData || '<1'}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SummaryCommonCard;

import { LoadingButton } from '@mui/lab';
import { Button, DialogTitle, ListItemIcon, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

const LinkedInPageAuthorizationModal = (props) => {
    const [isLoadingOn, setLoadingOn] = useState(false);

    const handleConnectLinkedInPage = () => {
        setLoadingOn(true);
        props.handleConnectLinkedInPage();
    };

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Have you converted your LinkedIn account to a Business Page?
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" sx={{ mx: 'auto', fontWeight: 'bold' }}>
                    Make sure you’ve completed these steps before continuing:
                </Typography>
                <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, mt: 2 }}>
                    <ListItemIcon>
                        <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                    </ListItemIcon>
                    <Typography variant="body1">
                        You’ve converted your Page to a Business Profile in the LinkedIn application.
                    </Typography>
                </Paper>
                <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, }}>
                    <ListItemIcon>
                        <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                    </ListItemIcon>
                    <Typography variant="body1">
                        You’re an Admin on your LinkedIn Page.
                    </Typography>
                </Paper>
                <Paper sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, }}>
                    <ListItemIcon>
                        <VerifiedUserIcon sx={{ color: 'success.dark', width: 18, height: 18, ml: 2, mt: 0.5 }} />
                    </ListItemIcon>
                    <Typography variant="body1">
                        Please create a LinkedIn Page to connect.
                    </Typography>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color='error' onClick={handleClose}>Close</Button>
                <LoadingButton
                    variant='contained'
                    loading={isLoadingOn}
                    autoFocus
                    onClick={() => {
                        handleConnectLinkedInPage();
                    }}
                >
                    Connect
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default LinkedInPageAuthorizationModal;

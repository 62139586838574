import { Avatar, Card, Chip, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import HttpServices from 'src/services/httpService';
import { chatModuleURL, postsModuleURL } from 'src/services/urlService';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import InstagramIcon from '../../assets/instagram.svg';
import ChatSidebar from './ChatSidebar';
import ChatWindow from './ChatWindow';
import NoMessage from '../../assets/app-image/no-message.svg';


export default function InstagramChat({ connectedNetworks }) {
  const isDesktop = useResponsive('up', 'sm');
  const [chatConversation, setChatConversation] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [pagination, setPagination] = useState('');
  const [convPagination, setConvPagination] = useState('');
  const { showToast } = useToaster();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileIcon = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMessageLoad, setMessageLoad] = useState(false);
  const [connectedMedias, setConnectedMedias] = useState([]);
  const [instagramId, setInstagramId] = useState('');
  const [userId, setUserId] = useState('');
  const [userPic, setProfilePic] = useState();
  const [showLoader, setLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isSendLoading, setSendLoading] = useState(false);
  const [height, setHeight] = useState(window.innerHeight - 300);

  const [isNextConversationLoading, setsNextConversationLoading] = useState(false);
  const [isNextChatMessageLoading, setsNextChatMessageLoading] = useState(false);
  const [scrollHeightBeforeLoad, setScrollHeightBeforeLoad] = useState(0);

  useEffect(() => {}, [selectedConversation, chatConversation]);

  useEffect(() => {
    let currId = connectedNetworks && connectedNetworks.length > 0 && connectedNetworks[0].id;
    currId && setInstagramId(currId);
    setConnectedMedias(connectedNetworks);
    connectedNetworks && connectedNetworks.length === 0 && setMessageLoad(false);
    instagramId && getAllConversation();
  }, []);

  useEffect(() => {
    instagramId && getAllConversation();
  }, [instagramId]);

  const getAllConversation = () => {
    setLoading(true);
    setLoader(true);
    let getProps = {
      url: `${chatModuleURL.getAllInstagramMessageConversations}${instagramId}&pagination=`,
      successCallback: chatConversationCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
    setMessageLoad(true);
  };
  const getAllNextConversation = () => {
    let getProps = {
      url: `${chatModuleURL.getAllInstagramMessageConversations}${instagramId}&pagination=${pagination}`,
      successCallback: chatConversationCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
    setMessageLoad(true);
  };
  const chatConversationCallback = (data, message) => {
    setLoading(false);
    setLoader(false);
    setsNextConversationLoading(false);
    if (data.conversion.length === 0 && userId === data.user_id) {
      return;
    }
    if (pagination) {
      let cConversation = { ...chatConversation };
      cConversation.pagination = data.pagination;
      cConversation.conversion = cConversation.conversion.concat(data.conversion);
      setChatConversation({ ...cConversation });
    } else {
      setChatConversation(data);
      setSelectedConversation(data.conversion[0]);
      setUserId(data.user_id);
    }
    setPagination(data.pagination);
    setMessageLoad(true);
  };
  const failureCallback = (message) => {
    setLoading(false);
    setLoader(false);
    setsNextConversationLoading(false);
    showToast(message, 'e');
    setMessageLoad(false);
    setsNextChatMessageLoading(false);
  };
  const addfailureCallback = (message) => {
    showToast(message, 'e');
    setSendLoading(false);
  };
  const handleConversation = (conversation) => {
    setSelectedConversation(conversation);
    setScrollHeightBeforeLoad(0)
  };
  const handleScrollHeightBeforeLoad =(value) =>{
    setScrollHeightBeforeLoad(value)
  }

  const instagramMessageCallback = (data, message) => {
    getConversationsendMessages();
  };
  const getConversationsendMessages = () => {
    let getProps = {
      url: `${chatModuleURL.getInstagramMessageConversation}${instagramId}&conversation_id=${selectedConversation.id}&pagination=`,
      successCallback: chatSendMessageCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };
  const chatSendMessageCallback = (data, message) => {
    let sConversation = { ...selectedConversation };
    sConversation.messages = data.messages;
    sConversation.pagination = data.pagination;
    setSelectedConversation({ ...sConversation });

    let updatedConversations = chatConversation.conversion.map((conversation) =>
      conversation.id === sConversation.id ? sConversation : conversation
    );

    setChatConversation({
      ...chatConversation,
      conversion: updatedConversations,
    });
    setSendLoading(false);
  };

  const handleSendMessage = (messageProps) => {
    setSendLoading(true);
    let type = 'TEXT';
    if (messageProps.imageUrl !== '') {
      type = messageProps.type;
    }
    let chatBody = {
      page_id: instagramId,
      recipient_id: messageProps.id,
      text: messageProps.text,
      image_url: messageProps.imageUrl,
      media_type: type,
    };
    let postProps = {
      url: chatModuleURL.sendInstagramMessage,
      body: chatBody,
      successCallback: instagramMessageCallback,
      failureCallback: addfailureCallback,
    };
    HttpServices.Post(postProps);
  };
  const handleNextConversation = () => {
    setsNextConversationLoading(true);
    if(pagination){
      getAllNextConversation();
    }
    else{
      setsNextConversationLoading(false);
    }
  };
  const getConversationMessages = (pagination) => {
    let getProps = {
      url: `${chatModuleURL.getInstagramMessageConversation}${instagramId}&conversation_id=${selectedConversation.id}&pagination=${pagination}`,
      successCallback: chatMessageCallback,
      failureCallback: nextCoversationfailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const nextCoversationfailureCallback = (message) => {
    setsNextChatMessageLoading(false);
    let sConversation = { ...selectedConversation };
    sConversation.pagination = '';
    setSelectedConversation({ ...sConversation });
    showToast(message, 'e');
  };
  const chatMessageCallback = (data, message) => {
    setsNextChatMessageLoading(false);
    let sConversation = { ...selectedConversation };
    sConversation.messages = sConversation.messages.concat(data.messages);
    sConversation.pagination = data.pagination;
    setSelectedConversation({ ...sConversation });
  };
  const handleNextMessage = (pagination) => {
    if (pagination) {
      setsNextChatMessageLoading(true);
      getConversationMessages(pagination);
      setConvPagination(pagination);
    }
  };

  const handleDeleteMessage = (messageId) => {
    let deleteBody = {
      id: messageId,
      page_id: instagramId,
    };
    let postProps = {
      url: postsModuleURL.deleteInstagramCommentAndMessage,
      body: deleteBody,
      successCallback: instagramDeleteSuccessCallback,
      failureCallback,
    };
    HttpServices.Post(postProps);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  const navigateToIntegration = () => {
    navigate('/integration');
  };

  const handleInstagramPage = (id) => {
    setInstagramId(id);
    setPagination('');
    setChatConversation([]);
    setSelectedConversation([]);
    setScrollHeightBeforeLoad(0);
  };
  const instagramDeleteSuccessCallback = (data, message) => {
    let sConversation = { ...selectedConversation };
    let index = sConversation.messages.findIndex((a) => a.id === data);
    if (index >= 0) sConversation.messages.splice(index, 1);
    setSelectedConversation({ ...sConversation });
  };
  const getNetworkChip = () => {
    return (
      connectedMedias &&
      connectedMedias.length > 0 &&
      connectedMedias.map((c) => {
        let isActive = c.id === instagramId;
        return (
          <Chip
            style={{
              borderRadius: 8,
              opacity: isActive ? 1 : 0.9,
              ...(isActive && {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                fontWeight: 800,
              }),
            }}
            sx={{ mr: 1, mb: 1 }}
            onClick={() => {
              handleInstagramPage(c.id);
            }}
            avatar={
              <Avatar
                src={c.profile_image ? c.profile_image : c.profile_name || c.id}
                alt={c.profile_name ? c.profile_name : c.id}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: isActive ? 'white' : theme.palette.primary.main,
                }}
                style={{ color: isActive ? theme.palette.primary.main : 'white' }}
              />
            }
            label={c.profile_name ? c.profile_name : c.id}
          />
        );
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>Chat</title>
      </Helmet>
      <>
        {connectedMedias && connectedMedias.length > 0 ? (
          <>
            <Card sx={{ mb: 2, p: 2 }}>{getNetworkChip()}</Card>
            {isLoading ? (
              <Spinner height={height} />
            ) : (
              <>
                {isMessageLoad && chatConversation.conversion && chatConversation.conversion.length > 0 ? (
                  <Card
                    sx={{
                      maxHeight: 'calc(100vh - 250px)',
                      minHeight: 'calc(100vh - 250px)',
                      display: 'flex',
                    }}
                  >
                    <ChatSidebar
                      chatConversation={chatConversation}
                      handleConversation={handleConversation}
                      selectedConversation={selectedConversation}
                      handleNextConversation={handleNextConversation}
                      userId={userId}
                      isNextConversationLoading={isNextConversationLoading}
                      icon={InstagramIcon}
                    />
                    <ChatWindow
                      selectedConversation={selectedConversation}
                      handleSendMessage={handleSendMessage}
                      handleNextMessage={handleNextMessage}
                      handleDeleteMessage={handleDeleteMessage}
                      userId={userId}
                      isNextChatMessageLoading={isNextChatMessageLoading}
                      isSendLoading={isSendLoading}
                      scrollHeightBeforeLoad={scrollHeightBeforeLoad}
                      handleScrollHeightBeforeLoad={handleScrollHeightBeforeLoad}
                    />
                  </Card>
                ) : (
                  <Card
                    sx={{
                      mb: 2,
                      p: 2,
                      maxHeight: 'calc(100vh - 250px)',
                      minHeight: 'calc(100vh - 250px)',
                      display: 'flex',
                      flexDirection:'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={NoMessage}
                      style={{ width: '45%', height: '45%', maxWidth: '45%', maxHeight: '45%' }}
                      alt="No Message Available"
                    />
                    <Typography variant="h4" sx={{ fontWeight: 800 }}>
                      No Message Available
                    </Typography>
                  </Card>
                )}
              </>
            )}
          </>
        ) : (
          <Card
            sx={{
              mb: 2,
              p: 2,
              maxHeight: 'calc(100vh - 150px)',
              minHeight: 'calc(100vh - 150px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingButton variant="contained" autoFocus onClick={navigateToIntegration}>
              Connect Instagram Accounts
            </LoadingButton>
          </Card>
        )}
      </>
    </>
  );
}

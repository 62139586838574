import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import { styled } from '@mui/material/styles';
import { Box, Avatar, ListItemText, ListItemAvatar, ListItemButton, Badge, useTheme } from '@mui/material';
import BadgeStatus from 'src/components/BadgeStatus';

const AVATAR_SIZE = 48;
const AVATAR_SIZE_GROUP = 32;

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  transition: theme.transitions.create('all'),
}));

const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-root': { width: '100%', height: '100%' },
});
const SmallAvatar_f = styled(Avatar)(({ }) => ({
  width: 18,
  height: 18,
  overflow: 'hidden',
  backgroundColor: '#ffffff',
}));

// const getDetails = (conversation, currentUserId) => {
//   const otherParticipants = conversation.participants.filter((participant) => participant.id !== currentUserId);
//   const displayNames = otherParticipants.reduce((names, participant) => [...names, participant.name], []).join(', ');
//   let displayText = '';
//   const lastMessage = conversation.messages[conversation.messages.length - 1];
//   if (lastMessage) {
//     const sender = lastMessage.senderId === currentUserId ? 'You: ' : '';
//     const message = lastMessage.contentType === 'image' ? 'Sent a photo' : lastMessage.body;
//     displayText = `${sender}${message}`;
//   }
//   return { otherParticipants, displayNames, displayText };
// };

export default function ChatConversationItem({ isSelected, conversation, isOpenSidebar, onSelectConversation, isUnread = false, userId, userPic, icon }) {
  const displayName = conversation.from.id === userId ? conversation.to.username : conversation.from.username
  const theme =useTheme();
  return (
    <RootStyle
      onClick={onSelectConversation}
      sx={{
        ...(isSelected && { bgcolor: 'action.selected' }),
      }}
    >
      <ListItemAvatar>
        <Box
        // sx={{
        //   ...(isGroup && {
        //     position: 'relative',
        //     width: AVATAR_SIZE,
        //     height: AVATAR_SIZE,
        //     '& .avatarWrapper': {
        //       position: 'absolute',
        //       width: AVATAR_SIZE_GROUP,
        //       height: AVATAR_SIZE_GROUP,
        //       '&:nth-of-type(1)': {
        //         left: 0,
        //         zIndex: 9,
        //         bottom: 2,
        //         '& .MuiAvatar-root': {
        //           border: (theme) => `solid 2px ${theme.palette.background.paper}`,
        //         },
        //       },
        //       '&:nth-of-type(2)': { top: 2, right: 0 },
        //     },
        //   }),
        // }}
        >
         
            <Badge overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<SmallAvatar_f src={icon} />} >
              <Avatar
                src={conversation.from.profile_pic ? conversation.from.profile_pic : conversation.from.name|| displayName }
                alt={conversation.from.name || displayName}
                sx={{
                  alignItems: 'center', justifyContent: 'center', textAlign: 'center',
                  height: 40, width: 40, bgcolor: theme.palette.primary.main,
                }}
              />
            </Badge>
          {/* {isOnlineGroup && <BadgeStatus status="online" sx={{ right: 2, bottom: 2, position: 'absolute' }} />} */}
        </Box>
      </ListItemAvatar>

      {isOpenSidebar && (
        <>
          <ListItemText
            primary={conversation.from.name || displayName}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondary={conversation?.messages?.[0]?.text }
            secondaryTypographyProps={{
              noWrap: true,
              variant: isUnread ? 'subtitle2' : 'body2',
              color: isUnread ? 'textPrimary' : 'textSecondary',
            }}
          />

          <Box
            sx={{
              ml: 2,
              height: 44,
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                mb: 1.25,
                fontSize: 12,
                lineHeight: '22px',
                whiteSpace: 'nowrap',
                color: 'text.disabled',
              }}
            >
              {formatDistanceToNowStrict(new Date(conversation.updated_time), {
                addSuffix: false,
              })}
            </Box>
            {isUnread && <BadgeStatus status="unread" size="small" />}
          </Box>
        </>
      )}
    </RootStyle>
  );
}

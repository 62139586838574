import AddIcon from '@mui/icons-material/Add';
import { AppBar, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import ProfileCard from 'src/components/profile-card/profile-card.component';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import WorkPlaceBox from '../WorkPlaceBox';

export default function ProfileSection({ handleChangeBrand, handleInitCall }) {

  const theme = useTheme();

  const initialLoad = CommonHelper.GetBrandInfoFromLocalStorage();
  const activeBrandId = CommonHelper.GetCurrentBrandId();

  const [openWorkPlaceModal, setWorkPlaceModal] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [currentBrandId, setCurrentBrand] = useState(activeBrandId);

  const { showToast } = useToaster();

  useEffect(() => {
    getAllBrandsFromLocalStorage();
  }, []);

  const getAllBrandsFromLocalStorage = () => {
    let brand_details = initialLoad?.brand_details;
    if (brand_details?.length > 0) {
      setResponseData(brand_details);
    }
  }

  const successCallback = (data, message) => {
    message && showToast(message, "s");
    setResponseData(data);
  }

  const getAllBrands = () => {
    let postProps = {
      url: userModuleURL.fetchUsersBrand,
      successCallback,
      failureCallback
    };
    HttpServices.Get(postProps);
  }

  const failureCallback = (message) => {
    message && showToast(message, "e");
  }

  const handleListItemClick = (brandId) => {
    setCurrentBrand(brandId);
    handleChangeBrand(brandId);
  }

  const handleWorkPlaceClick = () => {
    setWorkPlaceModal(true);
  }

  const handleClose = () => {
    setWorkPlaceModal(false);
  }

  const handleSuccess = () => {
    handleClose();
    handleInitCall();
  }

  const workPlaceProps = { handleClose, handleSuccess };

  return (
    <>
      <AppBar
        sx={{
          position: 'relative',
          left: 0,
          height: '100vh',
          width: theme.spacing(9),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: 'white',
          padding: '10px !important',
          borderRight: `2px dashed ${theme.palette.secondary.main}`,
        }}
      >
        <div style={{
          height: '97vh',
          display: 'grid',
          alignContent: 'space-between'
        }}>
          <div style={{
            display: 'flex', flexDirection: 'column', gap: '1rem', overflow: 'hidden scroll',
            maxHeight: '100%',
            scrollbarWidth: 'none'
          }} >
            {responseData.map((brand, index) => (
              <CommonHelper.HtmlTooltip
                title={
                  <ProfileCard
                    primaryText={brand.brand_name}
                    secondaryText={brand.description}
                    imageUrl={brand.brand_image}
                  />
                }
                placement="bottom"
              >
                <div key={index}
                  onClick={() => handleListItemClick(brand.brand_id)}
                >
                  <Avatar
                    src={brand.brand_image ? brand.brand_image : brand.brand_name}
                    alt={brand.brand_name}
                    sx={{
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      color: `${theme.palette.primary.main}`,
                      backgroundColor: `${theme.palette.secondary.main}`,
                      boxSizing: 'content-box',
                      border: currentBrandId === brand.brand_id ? `2px solid ${theme.palette.primary.main}` : `2px solid rgb(255, 255, 255)`,
                      ...(responseData.length - 1 === index && {
                        marginBottom: '1rem',
                      }),
                    }}
                    variant="rounded"
                  />
                </div>
              </CommonHelper.HtmlTooltip>
            ))}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '10px' }} >
            {CommonHelper.CheckForPermissionAvailableForUser() &&
              <Avatar onClick={handleWorkPlaceClick} sx={{ bgcolor: theme.palette.primary.main, cursor: 'pointer', boxSizing: 'content-box', border: '2px solid rgb(255, 255, 255)' }} variant="rounded">
                <AddIcon />
              </Avatar>
            }
          </div>
        </div>
      </AppBar >
      {openWorkPlaceModal && <WorkPlaceBox {...workPlaceProps} />}
    </>
  );
}

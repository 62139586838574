import {
  Grid,
  Typography,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Paper,
  Stack,
  useMediaQuery,
  useTheme,
  Divider,
  createTheme,
  Box
} from '@mui/material';
import { CommonHelper } from 'src/utils/commonHelper';
import FBcomment from '../../../../assets/comment.svg';
import FBlike from '../../../../assets/fblike.svg';
import FBshare from '../../../../assets/fbshare.svg';
import PublicIcon from '@mui/icons-material/Public';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const FacebookPostImagesPreview = ({ data }) => {
  let mediaFiles = CommonHelper.ProcessMediaUrl(data?.media_url)?.media_data;
  const isMultipleMedia = mediaFiles.length > 1;
  const mediaHeight = isMultipleMedia ? 150 : 200;
  const mediaStyle = { objectFit: 'cover', width: '100%', height: mediaHeight };
  const imageView = () => {
    let view = [];
    let xWidth = 6;
    const remainingImagesCount = mediaFiles.length > 5 ? mediaFiles.length - 4 : 0;
    switch (mediaFiles.length) {
      case 1:
        xWidth = 12;
        break;
      case 2:
        xWidth = 12;
        break;
      case 3:
      case 4:
        xWidth = 6;
        break;
      default:
        xWidth = 6;
        break;
    }
    view = mediaFiles.slice(0, 4).map((image, i) => (
      <Grid
        item
        xs={mediaFiles.length === 3 && i === 2 ? 12 : xWidth}
        key={i}
        sx={{
          maxWidth: 500,
          maxHeight: 250,
          overflow: 'hidden',
          position: 'relative',
          display: 'inline-flex',
          opacity: 1,
          transform:'scale(0.99)'
        }}
      >
        {image && image.url ? (
          image.type === 'image' ? (
            <img src={image.url} alt={image.url} style={mediaStyle} />
          ) : (
            <video src={image.url} alt={image.url} style={mediaStyle} />
          )
        ) : (
          <img src={image} alt={image} style={{ ...mediaStyle, height: 200 }} />
        )}
      </Grid>
    ));
    return (
      <Grid container sx={{ position: 'relative' }}>
        {view}
        {remainingImagesCount > 0 && (
          <Typography
            variant="h2"
            color="white"
            style={{
              position: 'absolute',
              top: '75%',
              left: '75%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              width: '208px',
              height: '150px',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            +{remainingImagesCount}
          </Typography>
        )}
      </Grid>
    );
  };

  const theme = useTheme();
  const customTheme = createTheme({
    breakpoints: {
      values: {
        mdCustom: 1100,
      },
    },
  });

  const isDesktop = useMediaQuery(customTheme.breakpoints.up('mdCustom'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
  const ConnectedProfiles = data.connected_profile_details;
  const ProfileTime = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="span">Just Now</Typography>
          <PublicIcon sx={{ fontSize: 15, mt: '0.3rem', ml: '0.2rem' }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Stack container justifyContent="center" alignItems="center" >
        <Card
          sx={{
            maxWidth: 500,
            minWidth: respwidth ? 400 : null,
            boxShadow: 'none',
            border: 'solid 2px',
            borderColor: theme.palette.preview.postcard.color,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={ConnectedProfiles.connected_profile_image}
                alt={
                  ConnectedProfiles.connected_profile_name
                    ? ConnectedProfiles.connected_profile_name
                    : ConnectedProfiles.connected_profile_id
                }
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: 40,
                  width: 40,
                  bgcolor: theme.palette.primary.main,
                }}
                style={{ color: 'white' }}
              />
            }
            title={ConnectedProfiles?.connected_profile_name}
            sx={{ fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 } }}
            subheader={<ProfileTime />}
            action={<MoreVertIcon sx={{ mt: 1 }} />}
          />
          <CardContent sx={{ pt: 1, pb: 1 }}>
            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                WebkitBoxDecorationBreak: 'clone',
                whiteSpace: 'pre-line',
                textOverflow: 'ellipsis',
              }}
            >
              {data.description}
            </Typography>
          </CardContent>
          <CardMedia sx={{ pl: 2, pr: 2 }}>{imageView()}</CardMedia>
          <CardActions disableSpacing sx={{ display: 'block' }}>
            {data.has_comment && data.comment_description &&
              <Stack sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'flex-end', mb: 1 }}>
                <Typography color={'text.secondary'}>1 comment</Typography>
              </Stack>}
            <Divider />
            <Stack direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt:0.5 }}>
                <img
                  src={FBlike}
                  style={{ height: 'auto', width: '100%', maxWidth: '18px', maxHeight: '18px', marginLeft: '1rem' }}
                />
                <Typography
                  color={'text.secondary'}
                  sx={{ fontWeight: 700, fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 }, marginLeft: '0.5rem' }}
                >
                  Like{' '}
                </Typography>
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={FBcomment}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginLeft: '0.5rem' }}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: 'text.secondary',
                    fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 },
                    marginLeft: '0.5rem',
                  }}
                >
                  Comment{' '}
                </Typography>
              </Paper>
              <Paper sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                <img
                  src={FBshare}
                  style={{ height: 'auto', width: '100%', maxWidth: '20px', maxHeight: '20px', marginRight: '0.5rem' }}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: 'text.secondary',
                    fontSize: { xl: 15, lg: 13, md: 11, sm: 9, xs: 7 },
                    marginRight: '0.5rem',
                  }}
                >
                  Share{' '}
                </Typography>
              </Paper>
            </Stack>
            {data.has_comment && data.comment_description && <>
              <Divider sx={{ mt: 0.5 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '0.5rem',
                  marginTop: '8px',
                }}
              >
                <Avatar
                  src={
                    ConnectedProfiles.connected_profile_image
                      ? ConnectedProfiles.connected_profile_image
                      : ConnectedProfiles.connected_profile_name || ConnectedProfiles.connected_profile_id
                  }
                  alt={
                    ConnectedProfiles.connected_profile_name
                      ? ConnectedProfiles.connected_profile_name
                      : ConnectedProfiles.connected_profile_id
                  }
                  sx={{
                    textAlign: 'flex-start',
                    height: 30,
                    width: 30,
                    bgcolor: theme.palette.primary.main,
                  }}
                />
                <Box sx={{ width: '100%' }}>
                  <Box sx={{
                    padding: 1,
                    borderRadius: '8px',
                    border: `1px solid ${theme.palette.text.border}`,
                    background: theme.palette.background.default,
                  }}>
                    <Typography variant="body2" sx={{ fontWeight: 800 }}>{ConnectedProfiles?.connected_profile_name}</Typography>
                    <Typography
                      variant="body2"
                      style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        WebkitBoxDecorationBreak: 'clone',
                        whiteSpace: 'pre-line',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.comment_description}
                    </Typography>
                  </Box>
                  <Stack spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Typography variant="body2" sx={{ fontWeight: 800, color: 'text.secondary' }}>Like</Typography>
                    <Typography variant="body2" sx={{ fontWeight: 800, color: 'text.secondary' }}>Reply</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Just Now</Typography>
                  </Stack>
                </Box>
              </Box>
            </>}
          </CardActions>
        </Card>
      </Stack>
    </>
  );
};

export default FacebookPostImagesPreview;

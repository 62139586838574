import { ExpandMore } from "@mui/icons-material";
import CollectionsSharpIcon from '@mui/icons-material/CollectionsSharp';
import MovieFilterSharpIcon from '@mui/icons-material/MovieFilterSharp';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { capitalCase } from "change-case";
import { useEffect, useState } from "react";
import { NewPostStyledComponent } from "src/pages/new-post-new/newPostStyled.component";
import { CommonHelper } from "src/utils/commonHelper";
import { PostDetailsHelper } from "src/utils/postDetailsHelper";
import YoutubePreviewOrganize from "./youtube-preview-organize.component";

const YoutubePreview = ({ postDetails }) => {
    const [previewData, setPreviewData] = useState(null);
    const [expanded, setExpanded] = useState(() => {
        const initialExpandedState = {};
        if (previewData) {
            Object.keys(previewData).forEach(profileId => {
                initialExpandedState[profileId] = true; // Set each profileId to true to expand all accordions
            });
        }
        return initialExpandedState;
    });
    const theme = useTheme();
    useEffect(() => {
        processPreview();
    }, [postDetails]);

    const [currentTab, setCurrentTab] = useState({});

    const processPreview = () => {
        let details = CommonHelper.PreviewGroupBy(postDetails, "connected_profile_id")
        setPreviewData(details)
    }

    const handleExpand = (profileId) => (event, isExpanded) => {
        setExpanded(prevExpanded => ({
            ...prevExpanded,
            [profileId]: isExpanded
        }));
    };

    const handleChangeTab = (profileId, newValue) => {
        setCurrentTab(prevTabs => ({
            ...prevTabs,
            [profileId]: newValue
        }));
    };

    const getCurrentTab = (profileId) => {
        if (currentTab[profileId]) {
            return currentTab[profileId]
        }
        return previewData[profileId]?.data?.length > 0 &&
            previewData[profileId].data[0].post_type
    };

    const POST_TYPES = {
        video: {
            value: 'video',
            icon: <CollectionsSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
        },
        shorts: {
            value: 'shorts',
            icon: <MovieFilterSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
        }
    };
    return (
        <>
            {previewData && Object.keys(previewData).map(profileId => {
                return (
                    <>
                        <Accordion
                            key={profileId}
                            expanded={expanded[profileId] !== undefined ? expanded[profileId] : true}
                            onChange={handleExpand(profileId)}
                            style={{ ...NewPostStyledComponent.AccordionStyle, borderColor: theme.palette.text.border }}
                        >

                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                sx={NewPostStyledComponent.AccordionSummary}
                            >
                                <Grid alignItems="center" display="flex">
                                    {PostDetailsHelper.getSocialMediaViewWithBadge(previewData[profileId]?.connected_profile_details, "youtube")}
                                    <Typography
                                        sx={{ fontSize: '0.875rem', fontWeight: 700, lineHeight: 1.5, textTransform: 'uppercase', marginLeft: 2 }}>
                                        {previewData[profileId]?.connected_profile_details?.connected_profile_name}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails style={{ padding: '.5rem', marginLeft: '1rem' }}>
                                <Box>
                                    <Tabs
                                        value={currentTab[profileId] ? currentTab[profileId] : getCurrentTab(profileId)}
                                        onChange={(e, newValue) => handleChangeTab(profileId, newValue)}
                                        scrollButtons="auto"
                                        variant="scrollable"
                                        sx={{ gap: '0.5rem', minHeight: '37px', margin: '1rem' }}
                                    >
                                        {previewData[profileId]?.data?.length > 0 &&
                                            previewData[profileId].data.map(post => {
                                                return (
                                                    <Tab sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }} disableRipple key={POST_TYPES[post.post_type].value} label={capitalCase(POST_TYPES[post.post_type].value)} icon={POST_TYPES[post.post_type].icon} value={POST_TYPES[post.post_type].value} />
                                                )
                                            })
                                        }
                                    </Tabs>
                                    <Box sx={{ mb: 2 }} />
                                    <YoutubePreviewOrganize postType={getCurrentTab(profileId)} previewData={previewData} profileId={profileId}/>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </>

                )
            })}
        </>

    )
}

export default YoutubePreview;
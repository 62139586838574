import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import NoReport from '../../../assets/reports-empty.svg';

const NoReportCard = () => {
  return (
    <Card sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
          <img src={NoReport} alt="No Data Available" height={100} width={100} />
        </Box>
        <Typography variant="body2" sx={{ color: '#777' }}>
          There is no data available currently for this report.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NoReportCard;

import { TextField, Typography, IconButton, Tooltip, InputAdornment, Box, Divider } from '@mui/material';
import { PhotoCameraOutlined as PhotoCameraOutlinedIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useThrottle from 'src/utils/throttle/throttle.component';

const PostFirstComment = ({ handleChangeDescription, valueDescription, handleUploadMediaPopup, showInputProps }) => {
  const theme = useTheme();

  // State Variables
  const [commentDescription, setCommentDescription] = useState(valueDescription);

  useEffect(() => {
    setCommentDescription(valueDescription);
  }, [valueDescription]);

  const throttledChangeCommentDescription = useThrottle((value) => {
    handleChangeDescription(value);
  }, 500);

  const handleDescription = (value) => {
    setCommentDescription(value);
    throttledChangeCommentDescription(value);
  };

  return (
    <>
      <Box position="relative" style={{ paddingTop: showInputProps ? '1rem' : '0.5rem' }}>
        <Typography style={{ color: theme.palette.text.secondary, ...theme.typography.subtitle2  }}>
          First Comment
        </Typography>
        <TextField
          name="description"
          placeholder="Add your first comment"
          fullWidth
          multiline
          rows={showInputProps ? 4 : 2}
          onChange={(event) => {
            handleDescription(event.target.value);
          }}
          value={commentDescription}
          sx={{
            bgcolor: 'background.neutral',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
            borderRadius:'8px',
            border: `1px solid ${theme.palette.text.border}`,
          }}
        />
      </Box>
      {/* <Tooltip title="Upload Media">
        <IconButton
        style={{bottom:0, left:0, position: 'absolute'}}
          // onClick={() => {
          //   handleUploadMediaPopup(true);
          // }}
        >
          <PhotoCamera />
        </IconButton>
      </Tooltip> */}
    </>
  );
};
export default PostFirstComment;

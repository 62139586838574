import { Card, Table, TableContainer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import DraftPostGridBody from './PostGridBody.component';
import DraftPostGridHead from './PostGridHead.component';
import DraftPostGridPagination from './PostGridPagination.component';
import NoDraft from '../../../assets/app-image/no-draft.svg';

const DraftsPostGrid = ({ draftList, order, orderBy, handleReCallDraft }) => {
    const theme = useTheme()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [height, setHeight] = useState(window.innerHeight - 150);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        let orderStr = isAsc ? 'desc' : 'asc'
        handleReCallDraft(property, orderStr)
    };
    const handleRecallDraftWithSort = () => {
        handleReCallDraft(orderBy, order)
    };


    return (
        <>
            {draftList && draftList.length > 0 ? (
                <>
                    <DynamicHeightComponent >
                        <Scrollbar>
                            <TableContainer
                                sx={{
                                    maxHeight: 'calc(94vh - 111px)',
                                    minHeight: 'calc(94vh - 111px)',
                                    height: height - 60,
                                }}
                            >
                                <Table stickyHeader size='small'>
                                    <DraftPostGridHead
                                        draftList={draftList}
                                        order={order}
                                        orderBy={orderBy}
                                        handleRequestSort={handleRequestSort}
                                    >
                                    </DraftPostGridHead>
                                    <DraftPostGridBody
                                        draftList={draftList}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleReCallDraft={handleRecallDraftWithSort}
                                    >
                                    </DraftPostGridBody>
                                </Table>
                            </TableContainer>
                            <DraftPostGridPagination
                                draftList={draftList}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                            ></DraftPostGridPagination>

                        </Scrollbar>
                    </DynamicHeightComponent>
                </>
            ) : (
                <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: height,minHeight: 'calc(100vh - 100px)', maxHeight: 'calc(100vh - 100px)' }}>
                    <img src={NoDraft} style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }} alt="No Draft Posts" />
                </Card>
            )}
        </>
    );
};
export default DraftsPostGrid;

import {
    Card,
    Table,
    TableContainer,
    TablePagination
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import NoPostFound from 'src/pages/publishedPosts/noPost.component';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import { useToaster } from 'src/utils/toaster/toasterContext';
import RejectedApprovalGridHead from './rejected-post-grid-head.component';
import RejectedApprovalGridBody from './rejected-post-grid-body.component';
import NoApproval from '../../../assets/app-image/no-approval-posts.svg'
// import RejectedApprovalGridBody from './pending-approval-grid-body.component';

const RejectedApprovalGrid = ({ rejectedApprovalList, handleReCallRejectedApprovalPost , order,orderBy}) => {
    const theme = useTheme();
    //Table Variables
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPopOverPost, setPopOverPost] = useState(null);
    const [popoverElement, setPopoverElement] = useState(null);
    const [height, setHeight] = useState(window.innerHeight - 110);
    const { showToast } = useToaster();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        let orderStr = isAsc ? 'desc' : 'asc'
        handleReCallRejectedApprovalPost(property, orderStr)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleCloseMenu = () => {
        setPopOverPost(null);
        setPopoverElement(null);
    };

    const handleEdit = (imageData = []) => {
    };
    const failureCallback = (message) => {
        message && showToast(message, 'e');
        handleCloseMenu();
    };
    const handleOpenScheduledPost = (id, open = false) => {
        // setScheduledPostView(open);
        // setViewedPost(id);
    };
    const handleOpenMenu = (event, row) => {
        setPopOverPost(row);
        setPopoverElement(event.currentTarget);
    };
    const handleApprove = (postInfo) => {
        let approveReq = {
            schedule_id: postInfo.id
        }
        let postProps = {
            url: userModuleURL.approvePost,
            body: approveReq,
            successCallback: approvalSuccessCallback,
            failureCallback
        }
        HttpServices.Post(postProps);
    }
    const approvalSuccessCallback = (data, message) => {
        message && showToast(message, "s");
    }
    const rejectedSuccessCallback = (message) => {
        message && showToast(message, "s");
    }
    const handleReject = (postInfo) => {
        let rejectReq = {
            schedule_id: postInfo.id
        }
        let postProps = {
            url: userModuleURL.rejectPost,
            body: rejectReq,
            successCallback: rejectedSuccessCallback,
            failureCallback
        }
        HttpServices.Post(postProps);
    }
    const postActionProps = {
        popoverElement: popoverElement,
        selectedPopOverPost: selectedPopOverPost,
        handleCloseMenu,
        handleApprove,
        handleReject
    };
    return (
        <>
            {rejectedApprovalList && rejectedApprovalList.length > 0 ? (
                <>
                    <DynamicHeightComponent heightRatio={110}>
                        <Scrollbar>
                            <TableContainer
                                sx={{
                                    maxHeight: 'calc(94vh - 166px)',
                                    minHeight: 'calc(94vh - 166px)',
                                    height: height - 60,
                                  }}
                            >
                                <Table stickyHeader size='small'>
                                    <RejectedApprovalGridHead rejectedApprovalList={rejectedApprovalList}
                                        orderBy={orderBy}
                                        order={order}
                                        handleRequestSort={handleRequestSort}
                                    >

                                    </RejectedApprovalGridHead>
                                    <RejectedApprovalGridBody
                                        rejectedApprovalList={rejectedApprovalList}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleReCallRejectedApprovalPost={handleReCallRejectedApprovalPost}>
                                    </RejectedApprovalGridBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[10, 20, 30, 50]}
                                count={rejectedApprovalList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Scrollbar>
                    </DynamicHeightComponent>
                </>
            ) : (
                <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                    <img src={NoApproval} style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }} alt="No Rejected Approval" />
                </Card>
            )}
        </>
    );
};
export default RejectedApprovalGrid;

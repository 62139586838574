import {
    Card,
    Table,
    TableContainer
} from '@mui/material';
import { useState } from 'react';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import NoPostFound from '../../publishedPosts/noPost.component';
import FailedPostGridBody from './FailedPostGridBody.component';
import FailedPostGridHead from './FailedPostGridHead.component';
import FailedPostGridPagination from './FailedPostGridPagination.component';
import NoUpcomingPost from '../../../assets/app-image/no-upcoming-posts.svg'

const FailedPostGrid = ({ failedPostList, orderBy, order, handleReCallFailedPost }) => {
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [height, setHeight] = useState(window.innerHeight - 200);



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        let orderStr = isAsc ? 'desc' : 'asc'
        handleReCallFailedPost(property, orderStr)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleRecallFailedPostWithSort = () => {
        handleReCallFailedPost(orderBy, order)
    };

    return (
        <>
            {failedPostList && failedPostList.length > 0 ? (
                <>
                    <DynamicHeightComponent >
                        <Scrollbar>
                            <TableContainer
                                sx={{
                                    maxHeight: 'calc(100vh - 305px)',
                                    minHeight: 'calc(100vh - 305px)',
                                }}
                            >
                                <Table aria-label="collapsible table" stickyHeader>
                                    <FailedPostGridHead
                                        failedPostList={failedPostList}
                                        order={order}
                                        orderBy={orderBy}
                                        handleRequestSort={handleRequestSort}>
                                    </FailedPostGridHead>
                                    <FailedPostGridBody
                                        failedPostList={failedPostList}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        handleReCallFailedPost={handleRecallFailedPostWithSort}>
                                    </FailedPostGridBody>
                                </Table>
                            </TableContainer>
                            <FailedPostGridPagination
                                failedPostList={failedPostList}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                            ></FailedPostGridPagination>
                        </Scrollbar>
                    </DynamicHeightComponent>
                </>
            ) : (
                <Card style={{ justifyContent: 'center', height: height, alignItems: 'center', minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                    {/* <img src={NoUpcomingPost} style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }} alt="No Failed Post" /> */}
                    <NoPostFound height={height} />
                </Card>
            )}
        </>
    );
};
export default FailedPostGrid;

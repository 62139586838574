import { useTheme } from '@mui/material';
import { Rings } from 'react-loader-spinner';

const Spinner = ({ height = 250, width = "250", minHeight = '100' }) => {
    const theme = useTheme();
    return <div style={{ display: 'flex', justifyContent: 'center', minHeight: minHeight + 'px', height: 'calc(100vh - 133px)' }}>
        <Rings
            visible={true}
            height={height}
            width={width}
            color={theme.palette.primary.main}
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    </div>
}
export default Spinner;